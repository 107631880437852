export const validateEmail = (value) => {
  if (
    value != null &&
    value.trim() !== "" &&
    value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
  ) {
    return true;
  }
  return false;
};

export const validatePassword = (enteredPassword) => {
  const upperCaseMatch = /[A-Z]/g;
  const lowerCaseMatch = /[a-z]/g;
  const numberMatch = /[0-9]/g;
  if (
    enteredPassword.length > 8 &&
    enteredPassword.match(upperCaseMatch) &&
    enteredPassword.match(lowerCaseMatch) &&
    enteredPassword.match(numberMatch)
  ) {
    return true;
  } else {
    return false;
  }
};

export const validateGenericField = (value) => {
  if (value != null && value.trim() !== "") {
    return true;
  }

  return false;
};

export const validateContactField = (value) => {
  if (value != null && value.trim() !== "" && value.length >=10 && value.length <=13) {
    return true;
  }

  return false;
};
