import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "semantic-ui-react";

export default function TermsAndConditions() {
  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <Helmet>
        <title>Terms and Conditions - Luxo Cabs</title>
        <meta
          name="description"
          content="Luxo Cabs is a company based in Melbourne Australia that offers reliable transportation services for both locals and tourists."
        />
      </Helmet>
      <Container textAlign="left" style={{ padding: "2rem" }}>
        <div className="title-large" style={{ textAlign: "center" }}>
          Terms and Conditions - Luxo Cabs
        </div>
        <br />
        <div className="medium-text">
          Last Updated: September 24, 2023
          <br />
          <br />
          Luxo Cabs is a taxi company based in Melbourne, Australia. We offer a variety of taxi services, including airport transfers, city travel,
          and hourly charter. Our goal is to provide our customers with a safe, reliable, and comfortable taxi experience.
          <br />
          <br />
          The following definitions used in the agreement provides clear and concise definitions of key terms.
        </div>
        <br />
        <span className="title-small">Customer:</span>
        <span className="medium-text">Any person who uses Luxo Cabs' taxi services</span>
        <br />
        <span className="title-small">Booking:</span>{" "}
        <span className="medium-text">A reservation for a taxi ride. A booking can be made online or by phone.</span>
        <br />
        <span className="title-small">Payment:</span>
        <span className="medium-text">The amount of money that a customer owes Luxo Cabs for a taxi ride.</span>
        <br />
        <span className="title-small">Cancellations: </span>
        <span className="medium-text">
          is the act of a customer terminating their booking for a taxi ride. Cancellations can be made online or by phone.
        </span>
        <br />
        <br />
        <div className="title-medium">By using our taxi services, you agree to the following terms and conditions:</div>
        <br />
        <br />
        <div className="title-medium">Customer Eligibility:</div>
        <br />
        <div className="medium-text">You must be at least 18 years old and have a valid credit card to use our taxi services.</div>
        <br />
        <div className="title-medium">Bookings:</div>
        <br />
        <div className="medium-text">
          To make a booking, you can visit our website or call our customer service line.
          <br />
          You will need to provide your name, contact information, pickup and drop-off locations, and the number of passengers.
          <br />
          We will confirm your booking and provide you with an estimated fare.
        </div>
        <br />
        <div className="title-medium">Payments:</div>
        <br />
        <div className="medium-text">
          We accept payment by cash, credit card and debit card.
          <br />
          A 5% service fee will be applied to all credit card payments.
          <br />
          The fare is due at the end of your taxi ride.
        </div>
        <br />
        <div className="title-medium">Cancellations:</div>
        <div className="medium-text">
          <br />
          If you need to cancel your taxi ride, please do so as soon as possible.
          <br />
          <ol>
            <li>For all types of transportation except Special events.</li>
          </ol>
          Cancellations made before 1 hour of your scheduled ride time will not be subject to a cancellation fee but the surcharge will not be
          refunded.
          <br />
          <br />
          Cancellations made within 12 hours of your scheduled ride time will be subject to a cancellation fee.
          <br />
          <br />
          The cancellation fee is equal to 50% of the estimated fare.
        </div>
        <br />
        <div className="title-medium">No-Shows:</div>
        <br />
        <div className="medium-text">
          If you do not show up for your scheduled taxi ride, you will be charged a no-show fee.
          <br />
          <br />
          The no-show fee is equal to 100% of the estimated fare.
        </div>
        <br />
        <div className="title-medium">Refunds:</div>
        <ul>
          <li>
            <div className="title-small">For all types of transportation except Special events.</div>
            <br />
            <div className="medium-text">
              <ol>
                <li>Refunds will be issued for cancellations made more than 1 hour in advance of your scheduled ride time.</li>
              </ol>
              <br />
            </div>
          </li>
          <li>
            <div className="title-small">For Special events</div>
            <br />
            <div className="medium-text">
              <ol>
                <li>Refunds will be issued for cancellations made more than 12 hours in advance of your scheduled ride time.</li>
                <li>Refunds will also be issued if your taxi ride is cancelled by Luxo Cabs due to unforeseen circumstances.</li>
                <li>Refunds will be issued to the original form of payment.</li>
              </ol>
            </div>
          </li>
        </ul>
        <div className="title-medium">Waiting Period:</div>
        <br />
        <div className="medium-text">
          Our drivers will wait up to 15 minutes for you at the pickup location.
          <br />
          <br />
          First 5 minutes of waiting will be free i.e. there will be no additional waiting fee.
          <br />
          <br />
          For the next 10 minutes, there will be a waiting fee equal to $1 for every 1 minute that the driver waits. The driver will be released from
          the booking after a total of 15 mins wait unless the customer calls and agrees to pay for additional waiting fee for which the waiting fee
          is $1 for every 1 minute the driver waits.
        </div>
        <br />
        <div className="title-medium">Damages:</div>
        <br />
        <div className="medium-text">
          If you damage one of our taxis, you will be responsible for the cost of repairs.
          <br />
          <br />
          We will assess the damage and provide you with an estimate of the repair costs.
          <br />
          <br />
          You can pay the repair costs by credit card or debit card.
        </div>
        <br />
        <div className="title-medium">General Conduct:</div>
        <br />
        <div className="medium-text">
          All passengers are expected to behave in a respectful manner to our drivers and other passengers.
          <br />
          <br />
          No disruptive or abusive behaviour will be tolerated.
          <br />
          <br />
          Passengers are not allowed to eat, drink, or smoke in our taxis.
          <br />
          <br />
          Passengers are not allowed to bring pets or flammable materials in our taxis.
        </div>
        <br />
        <div className="title-medium">Safety:</div>
        <br />
        <div className="medium-text">
          Safety is our top priority.
          <br />
          <br />
          Our drivers are all licensed and insured.
          <br />
          <br />
          Our taxis are regularly inspected and maintained.
          <br />
          <br />
          We have a strict safety policy in place.
        </div>
        <br />
        <div className="title-medium">Liability:</div>
        <br />
        <div className="medium-text">
          Luxo Cabs is not liable for any loss or damage to your personal belongings while in one of our taxis.
          <br />
          <br />
          Luxo Cabs is not liable for any delays or cancellations caused by unforeseen circumstances.
          <br />
          <br />
        </div>
        <div className="title-medium">Additional Charges:</div>
        <br />
        <div className="medium-text">
          The following additional charges may apply to your taxi ride:
          <ol>
            <li>Airport tolls</li>
            <li>Parking fees</li>
            <li>Night surcharge</li>
            <li>Waiting fee</li>
            <li>Extra luggage fee</li>
          </ol>
          <br />
          We reserve the right to refuse service to any customer who violates these terms and conditions.
          <br />
          If you have any questions or concerns about our terms and conditions, please do not hesitate to contact us.
        </div>
        <br />
        <div className="title-medium">Changes to these terms and conditions</div>
        <br />
        <div className="medium-text">
          Luxo Cabs reserves the right to change these terms and conditions at any time. Any changes will be posted on our website and will take
          effect immediately.
          <br />
          Thank you for choosing Luxo Cabs!
        </div>
      </Container>
    </div>
  );
}
