import React, { Component, Fragment, createRef } from "react";
import {
  Menu,
  Image,
  Dropdown,
  Segment,
  Button,
  Icon,
  Popup,
  Label,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { signOut } from "../../Redux/Auth/authActions";
import { connect } from "react-redux";
import "./NavbarStyle.css";
import { openModal } from "../../Redux/Modals/ModalActions";
import {
  PRIMARY_COLOR,
  SERVICES,
  SUPPORT_CONTACT,
  SUPPORT_CONTACT_TEXT,
} from "../../Utils/Constants";
import { AccentButton } from "../Custom/CustomButtons";
import { MODAL_LOGIN_REGISTER } from "../Modals/Auth/LoginRegisterModal";
import { sendPhoneCallEvent } from "../../Utils/analyticsFunctions";
import { ASSETS } from "../../Utils/Assets";
import TACApprovedIndicatorTwo from "../Misc/TACApprovedIndicatorTwo";

const HOME = "home";
const FLEET = "fleet";
const CONTACT = "contact";
const CAREER = "career";
const ABOUT = "about";

function MenuItems(activeItem, changeActiveItem) {
  return (
    <div
      style={{
        padding: "0rem 10rem",
        backgroundColor: PRIMARY_COLOR,
        position: "relative",
        textAlign: "center",
      }}
    >
      <Menu
        style={{
          marginTop: "0px",
          marginBottom: "0px",
          textAlign: "center",
          border: "none",
        }}
        inverted
        compact
      >
        <Menu.Item
          as={Link}
          to={"/fleet"}
          onClick={() => {
            changeActiveItem(FLEET);
          }}
          active={activeItem === FLEET}
        >
          Fleet
        </Menu.Item>
        <Dropdown text="Service" className="link item">
          <Dropdown.Menu>
            {SERVICES.map((service) => (
              <Dropdown.Item as={Link} to={service.to}>
                {service.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item
          as={Link}
          to={"/about"}
          onClick={() => {
            this.changeActiveItem(ABOUT);
          }}
          active={activeItem === ABOUT}
        >
          About Us
        </Menu.Item>
        <Menu.Item
          as={Link}
          to={"/contact"}
          onClick={() => {
            this.changeActiveItem(CONTACT);
          }}
          active={activeItem === CONTACT}
        >
          Contact Us
        </Menu.Item>
        <Menu.Item>
          <a href={`tel:${SUPPORT_CONTACT}`}>
            <AccentButton
              color="transparent"
              style={{ padding: "0px", fontSize: "1.5rem" }}
              text={SUPPORT_CONTACT_TEXT}
              icon={
                <Icon
                  name="call"
                  size="large"
                  color="orange"
                  style={{ paddingRight: "3rem" }}
                />
              }
            />
          </a>
        </Menu.Item>
        <Menu.Item style={{ marginRight: "0px" }}>
              <TACApprovedIndicatorTwo/>
        </Menu.Item>
      </Menu>
    </div>
  );
}

const mapStateToProp = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const actions = {
  signOut,
  openModal,
};

class Navbar extends Component {
  
  constructor(props) {
    super(props);
    this.reference = null;
    this.runIndex = 0;
  }

  state = {
    activeItem: FLEET,
    openSubmit: false,
  };

  componentDidMount() {
    this.reference = setInterval(() => {
      this.setState({
        openSubmit: !this.state.openSubmit,
      });
      ++this.runIndex;
      if (this.runIndex === 2) {
        clearInterval(this.reference);
        this.reference = null;
      }
    }, 20000);
  }

  changeActiveItem = (item) => () => {
    this.setState({
      activeItem: item,
    });
  };
  decideBackground = () => {
    switch (this.props.logoSize) {
      case "tiny":
        return { backgroundColor: "orange" };
      case "medium":
        return { background: "transparent" };
      default:
        return { backgroundColor: "none" };
    }
  };

  decodeLogoPosition = () => {
    switch (this.props.logoSize) {
      case "tiny":
        return "center";
      case "medium":
        return true;
      default:
        return true;
    }
  };

  decideLogo = () => {
    switch (this.props.logoSize) {
      case "tiny":
        return false;
      case "small":
        return true;
      default:
        return true;
    }
  };

  render() {
    const { activeItem } = this.state;
    const { auth, openModal } = this.props;
    const authenticated = !auth.isEmpty && auth.isLoaded;

    return (
      <div
        className="top-navigation-bar"
        style={{
          width: "100vw",
          padding: "0rem 1.5rem",
          backgroundColor: PRIMARY_COLOR,
          position: "relative",
          textAlign: "center",
        }}
      >
        {this.props.showLogo && (
          <Menu
            secondary
            inverted
            style={{
              marginTop: "0px",
              marginBottom: "0px",
              width: "100%",
              border: "none",
            }}
          >
            <Menu.Item as={Link} to="/">
              <Image src="/logo.png" style={{ height: "4rem" }} />
            </Menu.Item>

            <Menu.Menu
              position="right"
              secondary="true"
              pointing="true"
              style={{ padding: "1% 1%" }}
            >
              <Menu.Item
                as={Link}
                to={"/fleet"}
                onClick={this.changeActiveItem(FLEET)}
                active={activeItem === FLEET}
              >
                Fleet
              </Menu.Item>
              <Dropdown text="Service" className="link item">
                <Dropdown.Menu>
                  {SERVICES.map((service) => (
                    <Dropdown.Item key={service.to} as={Link} to={service.to}>
                      {service.title}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Menu.Item
                as={Link}
                to={"/about"}
                onClick={this.changeActiveItem(ABOUT)}
                active={activeItem === ABOUT}
              >
                About Us
              </Menu.Item>
              <Menu.Item
                as={Link}
                to={"/contact"}
                onClick={this.changeActiveItem(CONTACT)}
                active={activeItem === CONTACT}
              >
                Contact Us
              </Menu.Item>
              {authenticated && (
                <Dropdown item text="Account">
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/account/orders">
                      My Account
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => this.props.signOut()}>
                      Log Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {!authenticated && (
                <Fragment>
                  <Menu.Item style={{ marginRight: "0px", marginLeft: "0px" }}>
                    <AccentButton
                      onClick={() => {
                        openModal(MODAL_LOGIN_REGISTER, { forLogin: true });
                      }}
                      text={"Log In"}
                      style={{ border: "1px solid white", marginRight: "0px" }}
                    />
                  </Menu.Item>
                  <Menu.Item style={{ marginRight: "0px", marginLeft: "0px" }}>
                    <AccentButton
                      onClick={() => {
                        openModal(MODAL_LOGIN_REGISTER, {});
                      }}
                      text={"Sign up"}
                      style={{ marginRight: "0px  " }}
                      inverted
                    />
                  </Menu.Item>
                </Fragment>
              )}
              <Menu.Item style={{ marginRight: "0px" }}>
                <a
                  href={`tel:${SUPPORT_CONTACT}`}
                  onClick={() => {
                    sendPhoneCallEvent();
                  }}
                >
                  <AccentButton
                    style={{
                      padding: "0px",
                      fontSize: "1.5rem",
                      marginRight: "0px",
                    }}
                    text={SUPPORT_CONTACT_TEXT}
                    icon={
                      <Icon
                        name="call"
                        size="large"
                        color="orange"
                        style={{ paddingRight: "3rem" }}
                      />
                    }
                  />
                </a>
              </Menu.Item>
              <Menu.Item style={{ marginRight: "0px" }}>
                <TACApprovedIndicatorTwo/>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        )}
        {!this.props.showLogo && (
          <Segment basic>
            {" "}
            {MenuItems(activeItem, this.changeActiveItem)}
          </Segment>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProp, actions)(Navbar);
