import {
  LOGIN_ERROR,
  LOGIN_LOADING_START,
  LOGIN_LOADING_FINISH,
  SIGNUP_ERROR,
} from "./authConstants";

import Axios from "axios";
// import { SAMPLE_APPLICANT_PROFILE } from "../../Components/Utils/Constants";
import { closeModal, openModal } from "../Modals/ModalActions";
import {
  EMAIL_VERIFICATION_URL,
  CREATE_USER_URL,
  PASSWORD_RESET_URL,
  VERIFY_ACCOUNT_EMAIL_URL,
} from "../../Utils/URLConstants";
import axios from "axios";
import { getUniqueNumericID } from "../../Utils/Constants";

export const signOut = () => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    try {
      dispatch({ type: LOGIN_LOADING_START });
      await firebase.auth().signOut();
      dispatch({ type: LOGIN_LOADING_FINISH });
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: { error: error.code, signupError: null, loading: false },
      });
    }
  };
};

export const updateAuth = (payload) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({
      type: SIGNUP_ERROR,
      payload: payload,
    });
  };
};
