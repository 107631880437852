import React from "react";
import { Grid, Image, List, Segment } from "semantic-ui-react";
import { ASSETS } from "../../../Utils/Assets";
import TACApprovedIndicator from "../../Misc/TACApprovedIndicator";

const AboutLuxoCabs = () => {
  return (
    <Segment basic style={{  textAlign: "center",paddingLeft:"2em",paddingRight:"2em" }}>
      
      <br />
      <p className="title-large">About LuxoCabs: Where Every Ride Counts</p>
      <TACApprovedIndicator />
      <Grid padded textAlign="left" stackable>
        <Grid.Row columns={"equal"}>
          <Grid.Column>
            <Image src={ASSETS.ARTISTIC_1} centered size="large" rounded />
          </Grid.Column>
          <Grid.Column textAlign="justified" verticalAlign="middle">
            <p className="large-text">
              Welcome to Luxo Cabs, where luxury transportation transcends mere
              travel. We are Melbourne's premier taxi service, renowned for our
              unwavering commitment to impeccable service, discerning clientele,
              and a fleet of immaculate luxury vehicles.
            </p>
            <p className="large-text" style={{fontWeight:"bold"}}>
              Your Gateway to Unparalleled Comfort and Style
            </p>

            <p className="large-text">
              Whether you're arriving at Tullamarine Airport in style, gracing a
              corporate event with your presence, or seeking an unforgettable
              wedding day experience, Luxo Cabs elevates every journey. Our team
              of professional drivers, hand-picked for their expertise and
              discretion, ensures you arrive relaxed, refreshed, and ready to
              make the most of every moment.
            </p>
            <p className="large-text">
              Let Luxo Cabs be your gateway to experiencing Melbourne in
              unparalleled style and comfort. Contact us today to discuss your
              specific needs and reserve your luxury chauffeured experience. We
              look forward to exceeding your expectations and making your
              journey as unforgettable as your destination.
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={"equal"}>
          <Grid.Column textAlign="justified" verticalAlign="middle">
            <p className="large-text" style={{fontWeight:"bold"}}>
              Indulge in a Bespoke Luxury Travel Experience:
            </p>
            <List bulleted>
              <List.Item className="large-text">
                <b>Unrivalled Fleet:</b> Immerse yourself in the unparalleled
                comfort and refinement of our impeccably maintained fleet of
                luxury sedans, SUVs, and limousines. From sleek Audis and
                spacious Mercedes-Benz, we have the perfect vehicle to
                complement your occasion.
              </List.Item>
              <List.Item className="large-text">
                <b>Impeccable Service:</b> Experience the epitome of
                personalised service with our dedicated chauffeurs.
                Knowledgeable of Melbourne's every corner, they navigate the
                city with ease, ensuring a smooth and stress-free journey.
              </List.Item>
              <List.Item className="large-text">
                <b>Tailored to Your Needs:</b> Every Luxo Cabs experience is
                meticulously crafted to your specific requirements. We cater to
                airport transfers, corporate events, private tours, weddings,
                and any other special occasion, ensuring your every need is
                anticipated and exceeded.
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column>
            <Image src={ASSETS.ARTISTIC_2} centered size="large" rounded />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
    </Segment>
  );
};

export default AboutLuxoCabs;
