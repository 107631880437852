import React from "react";
import withRouter from "../../Utils/router/router_hocs";
import ServiceDetails from "./AirportTransfers/AirportTransfersService";
import { SERVICES_DATA } from "../../Utils/Data/ServicesData";

function CabServices(props) {
  function getMainContent() {
    let pageID = props.params.id;
    if (Object.keys(SERVICES_DATA).includes(pageID)) {
      return <ServiceDetails data={SERVICES_DATA[props.params.id]} />;
    } else {
      return <div />;
    }
  }

  return <div>{getMainContent()}</div>;
}

export default withRouter(CabServices);
