import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import {
  Sidebar,
  Segment,
  Menu,
  Sticky,
  Dropdown,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import ModalManager from "./Components/Modals/ModalManager";
import { signOut } from "./Redux/Auth/authActions";
import { isIE, isEdge } from "react-device-detect";
import { connect } from "react-redux";
import { openModal } from "./Redux/Modals/ModalActions";
import MobileNavbar from "./Components/Navbar/MobileNavbar";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import withRouter from "./Utils/router/router_hocs";
import LandingPage from "./Components/LandingPage/LandingPage";
import { DesktopOnly, MobileOnly } from "./Components/Custom/Renderers";
import { MODAL_LOGIN_REGISTER } from "./Components/Modals/Auth/LoginRegisterModal";
import ContactUs from "./Components/ContactUs/ContactUs";
import AboutUsPage from "./Components/AboutUs/AboutUsPage";
import BookingForm from "./Components/BookingForm/BookingForm";
import UserProfilePage from "./Components/User/UserProfilePage";
import CabServices from "./Components/Services/CabServices";
import FleetPage from "./Components/Fleet/FleetPage";
import { SERVICES } from "./Utils/Constants";
import PrivacyPolicy from "./Components/Documentations/PrivacyPolicy";
import TermsAndConditions from "./Components/Documentations/TermsAndConditions";
// import TopBar from "./Components/Navbar/TobBar";
import ReactGA from 'react-ga4';

import { isProd } from "./Utils/Controller";
import SuburbsWeCoverPage from "./Components/SuburbsWeCover/SuburbsWeCover";
import FAQPage from "./Components/FAQSection/FAQSection";
import LuxoCabsTACApproved from "./Components/Misc/LuxoCabsTACApproved";



const HOME = "home";

const mapStateToProp = (state) => {
  return {
    auth: state.firebase.auth,
  };
};

const action = {
  openModal,
  signOut,
};

class App extends Component {
  state = {
    activeItem: HOME,
    sidebarOpen: false,
    logoSize: "medium",
    showLogo: true,
  };

  setDivContext = (context) => {
    this.setState({
      divContext: context,
    });
  };

  handleSidebarToggle = (open) => () => {
    this.setState({
      sidebarOpen: open,
    });
  };

  handleNavbarStick = () => {
    this.setState({
      logoSize: "tiny",
      showLogo: false,
    });
  };

  handleNavbarUnstick = () => {
    this.setState({
      logoSize: "large",
      showLogo: true,
    });
  };

  handleLogoSize = (size) => () => {
    this.setState({
      logoSize: size,
    });
  };

  checkIfSafari() {
    return isIE || isEdge;
  }

  showSupportAlert() {
    alert(
      "Please use Chrome, Mozilla or Any other High End Browser for Better Experience!"
    );
    window.open("https://www.google.com/chrome/");
  }

  componentDidMount() {
    if(isProd){
      // ReactGA.initialize({

      // })
    }
    // window.oncontextmenu = (e) => {
    //   if (e.button === 2) {
    //     e.preventDefault();
    //   }
    //   return true;
    // };
  }

  render() {
    const { sidebarOpen } = this.state;
    const { auth, openModal } = this.props;
    const authenticated = !auth.isEmpty && auth.isLoaded;

    return (
      <div id="lc-page" ref={this.setDivContext}>
        {!this.checkIfSafari() && (
          <div>
            <ModalManager />
            <div>
              <MobileOnly>
                <Sticky
                  context={this.state.divContext}
                  onStick={this.handleNavbarStick}
                  onUnstick={this.handleNavbarUnstick}
                >
                  <MobileNavbar
                    handleSidebarToggle={this.handleSidebarToggle}
                    value={this.state.sidebarOpen}
                  />
                </Sticky>
              </MobileOnly>
              <DesktopOnly>
                <Sticky
                  context={this.state.divContext}
                  onStick={this.handleNavbarStick}
                  onUnstick={this.handleNavbarUnstick}
                >
                  <Navbar
                    logoSize={this.state.logoSize}
                    showLogo={this.state.showLogo}
                  />
                </Sticky>
              </DesktopOnly>
            </div>

            <Sidebar.Pushable
              as={Segment}
              style={{ margin: "0px", border: "none", borderRadius: "0px" }}
            >
              <Sidebar
                style={{ margin: "0px", border: "none", borderRadius: "0px" }}
                as={Menu}
                animation="overlay"
                icon="labeled"
                vertical
                visible={sidebarOpen}
                width="wide"
              >
                <Menu.Item
                  key={"/"}
                  as={Link}
                  to={"/"}
                  onClick={this.handleSidebarToggle(false)}
                >
                  Home
                </Menu.Item>
                <Menu.Item
                  key={"/fleet"}
                  as={Link}
                  to={"/fleet"}
                  onClick={this.handleSidebarToggle(false)}
                >
                  Fleet
                </Menu.Item>
                <Menu.Item>
                  <Dropdown text="Services">
                    <Dropdown.Menu>
                      {SERVICES.map((service) => (
                        <Dropdown.Item
                          as={Link}
                          to={service.to}
                          onClick={this.handleSidebarToggle(false)}
                        >
                          {service.title}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>
                <Menu.Item
                  key={"/contact"}
                  as={Link}
                  to={"/contact"}
                  onClick={this.handleSidebarToggle(false)}
                >
                  Contact Us
                </Menu.Item>

                <Menu.Item
                  key={"/about"}
                  as={Link}
                  to={"/about"}
                  onClick={this.handleSidebarToggle(false)}
                >
                  Know About Us
                </Menu.Item>

                {authenticated && (
                  <Menu.Item
                    key={"/account/orders"}
                    as={Link}
                    to="/account/orders"
                    onClick={this.handleSidebarToggle(false)}
                  >
                    My Account
                  </Menu.Item>
                )}
                {!authenticated && (
                  <Menu.Item
                    key={"/login"}
                    onClick={() => {
                      openModal(MODAL_LOGIN_REGISTER, { forLogin: true });
                      this.handleSidebarToggle(false)();
                    }}
                  >
                    Login/Signup
                  </Menu.Item>
                )}
                {authenticated && (
                  <Menu.Item
                    key={"/logout"}
                    onClick={() => {
                      this.handleSidebarToggle(false)();
                      this.props.signOut();
                    }}
                  >
                    Log Out
                  </Menu.Item>
                )}
              </Sidebar>
              <Sidebar.Pusher style={{ border: "none", boxShadow: "none" }}>
                <Routes>  
                  <Route  path="/" Component={LandingPage}  />
                  <Route path="/fleet" Component={FleetPage} />
                  <Route path="/contact" Component={ContactUs} />
                  <Route path="/about" Component={AboutUsPage} />
                  <Route path="/booking" Component={BookingForm} />
                  <Route path="/account/:id" Component={UserProfilePage} />
                  <Route path="/services/:id" Component={CabServices} />
                  <Route path="/faq" Component={FAQPage} />
                  <Route path="/suburbs-we-cover/:id" Component={SuburbsWeCoverPage} />
                  <Route
                    path="/terms-and-conditions"
                    Component={TermsAndConditions}
                  />
                  <Route path="/privacy-policy" Component={PrivacyPolicy} />
                  <Route path="/tac-approved" Component={LuxoCabsTACApproved} />
                </Routes>
                <Footer />
              </Sidebar.Pusher>
            </Sidebar.Pushable>
          </div>
        )}

        {this.checkIfSafari() && this.showSupportAlert()}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProp, action)(App));
