import React, { useState } from "react";
import "./FAQSection.css";
import { Grid, Image, Segment } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { ASSETS } from "../../Utils/Assets";

const FAQPage = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Can I use my MPTP Card?",
      answer:
        "It is permissible to utilise your MPTP Card to receive discounted cab fares. However, the cardholder must be present in the taxi cab for the duration of the trip to qualify for this benefit. For further details, please follow the link provided: https://cpv.vic.gov.au/passengers/mptp",
    },
    {
      question: "Do you provide child seats?",
      answer:
        "It is important to note that Victorian cabs and their drivers are not legally permitted to provide child seats, restraints, or capsules. Rest assured that all cabs are equipped with an anchor bolt or restraint to support passengers during the ride. It is highly recommended that passengers adhere to these guidelines to ensure the safety and security of all parties involved.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <Helmet>
        <title>FAQ - Luxo Cabs</title>
        <meta
          name="description"
          content="Luxo Cabs is a company based in Melbourne Australia that offers reliable transportation services for both locals and tourists."
        />
      </Helmet>
      <div style={{ position: "relative" }}>
        <Image src={ASSETS.BANNER_FLEET} fluid />
        <div className="dark-tint"></div>
        <Grid
          columns={1}
          style={{
            position: "absolute",
            left: "0px",
            top: "0px",
            zIndex: "9999",
            width: "100%",
            height: "100%",
          }}
        >
          <Grid.Column verticalAlign="middle" textAlign="center">
            <div className="heading-text" style={{ color: "white" }}>
              Frequently Asked Questions
            </div>
          </Grid.Column>
        </Grid>
      </div>
      <Segment secondary basic className="faqSection" attached="bottom">
        <div className="faqList">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faqItem ${activeIndex === index ? "active" : ""}`}
              onClick={() => toggleFAQ(index)}
            >
              <div className="faqQuestion">{faq.question}</div>
              <div className="faqAnswer">
                {activeIndex === index && <p>{faq.answer}</p>}
              </div>
            </div>
          ))}
        </div>
      </Segment>
    </div>
  );
};

export default FAQPage;
