import React, { Component } from "react";
import { Menu, Icon, Header, Image, Popup, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { connect } from "react-redux";
import { openModal } from "../../Redux/Modals/ModalActions";
import {
  PRIMARY_COLOR,
  SUPPORT_CONTACT,
  SUPPORT_CONTACT_TEXT,
} from "../../Utils/Constants";
import { ASSETS } from "../../Utils/Assets";
import { AccentButton } from "../Custom/CustomButtons";
import { sendPhoneCallEvent } from "../../Utils/analyticsFunctions";
const actions = {
  openModal,
};

class MobileNavbar extends Component {
  constructor(props) {
    super(props);
    this.reference = null;
    this.runIndex = 0;
  }

  state = {
    openSubmit: false,
  };

  componentDidMount() {
    this.reference = setInterval(() => {
      this.setState({
        openSubmit: !this.state.openSubmit,
      });
      ++this.runIndex;
      if (this.runIndex === 2) {
        clearInterval(this.reference);
        this.reference = null;
      }
    }, 20000);
  }

  render() {
    const { handleSidebarToggle } = this.props;
    const { value } = this.props;

    return (
      <div style={{ width: "100vw", backgroundColor: "white" }}>
        <Menu
          className="top-navigation-bar"
          pointing
          secondary
          size="huge"
          style={{
            backgroundColor: PRIMARY_COLOR,
            marginBottom: "0px",
          }}
        >
          <Menu.Item onClick={handleSidebarToggle(!value)}>
            <Icon name="bars" size="large" inverted />
          </Menu.Item>
          <Menu.Item as={Link} to="/" style={{ paddingLeft: "0px" }}>
            <Image src={ASSETS.LOGO} style={{ height: "2rem" }} />
          </Menu.Item>
          <div
            style={{
              width: "100%",
              textAlign: "right",
              marginTop: "auto",
              marginBottom: "auto",
              marginRight: "2rem",
            }}
          >
            <a
              href={`tel:${SUPPORT_CONTACT}`}
              onClick={() => {
                sendPhoneCallEvent();
              }}
            >
              <AccentButton
                style={{ padding: "0px", fontSize: "1.5rem" }}
                text={SUPPORT_CONTACT_TEXT}
                color="transparent"
                icon={
                  <Icon
                    name="call"
                    style={{ paddingRight: "2rem", color: "orange" }}
                  />
                }
              />
            </a>
          </div>
        </Menu>
      </div>
    );
  }
}

export default connect(null, actions)(MobileNavbar);
