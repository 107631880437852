export const BOOKING_TYPE = {
    ONE_WAY:"one_way",
    ROUND_TRIP:"round_trip",
    AIRPORT_RIDE:"airport_ride",
}

export const BOOKING_STATUS = {
    processing:"processing",
    accepted:"accepted",
    rejected:"rejected",
    cancelled:"cancelled",
    paid:"paid"
}

export const RIDE_TYPE = {
    SEDAN:"sedan",
    SUV:"suv",
    MAXO_TAXI:"maxi_taxi"
}