const { PROJECT_MODE, TESTING, STAGING } = require("./Controller");

const _TEST_ASSETS = {
  LP_BANNER:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Flp_banner_new.jpg?alt=media&token=e8c4144c-0280-4c85-95b2-450198817c75",
  BANNER_CONTACT:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_contact.jpg?alt=media&token=3aa83665-ef31-4d6c-a052-22a0e9a6d980&_gl=1*xykrlo*_ga*MTMyMDQ1OTE2LjE2NDQzMTI5Mjg.*_ga_CW55HF8NVT*MTY5NTk4MTM3Ny44MC4xLjE2OTU5ODMxNDAuNjAuMC4w",
  SERVICES_BANNER:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-testing.appspot.com/o/assets%2Fassets%2Fservices-banner.png?alt=media&token=791fcc35-dcd9-4906-9b0f-5d4cdf32497b",
  BANNER_AIRPORT:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_airport.jpg?alt=media&token=16715d27-fdeb-4773-a074-474f056a0bdd",
  BANNER_FLEET:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_fleet.jpg?alt=media&token=31a85fce-cbaa-473f-b959-ee858356f6c4",
  BANNER_ROADSIDE:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_roadside.jpg?alt=media&token=df6b8a0e-b9b4-44c8-a81b-77d90e1a674e",
  STOCK_PROFILE_PIC:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-testing.appspot.com/o/assets%2Fassets%2Fstock_profile_pic.png?alt=media&token=34d52085-f3be-47cc-bbc2-b41cbf5cd222",
};

const _PROD_ASSETS = {
  LP_BANNER:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Flp_banner_new.jpg?alt=media&token=e8c4144c-0280-4c85-95b2-450198817c75",
  BANNER_CONTACT:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_contact.jpg?alt=media&token=3aa83665-ef31-4d6c-a052-22a0e9a6d980&_gl=1*xykrlo*_ga*MTMyMDQ1OTE2LjE2NDQzMTI5Mjg.*_ga_CW55HF8NVT*MTY5NTk4MTM3Ny44MC4xLjE2OTU5ODMxNDAuNjAuMC4w",
  SERVICES_BANNER:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-testing.appspot.com/o/assets%2Fassets%2Fservices-banner.png?alt=media&token=791fcc35-dcd9-4906-9b0f-5d4cdf32497b",
  BANNER_AIRPORT:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_airport.jpg?alt=media&token=16715d27-fdeb-4773-a074-474f056a0bdd",
  BANNER_FLEET:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_fleet.jpg?alt=media&token=31a85fce-cbaa-473f-b959-ee858356f6c4",
  BANNER_FLEET:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_fleet.jpg?alt=media&token=31a85fce-cbaa-473f-b959-ee858356f6c4",
  BANNER_ROADSIDE:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_roadside.jpg?alt=media&token=df6b8a0e-b9b4-44c8-a81b-77d90e1a674e",
  STOCK_PROFILE_PIC:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fstock_profile_pic.png?alt=media&token=8cecaf98-016b-449b-8855-d15534430509",
};

const TEST_MODE = PROJECT_MODE == TESTING || PROJECT_MODE == STAGING;

const iconsPrefix = "/assets/icons";
const imagesPrefix = "/assets/images";
const illustrationsPrefix = "/assets/illustrations";
const fleetsPrefix = "/assets/fleets";

export const ASSETS = {
  LP_BANNER: TEST_MODE ? _TEST_ASSETS.LP_BANNER : _PROD_ASSETS.LP_BANNER,
  SERVICES_BANNER: TEST_MODE
    ? _TEST_ASSETS.SERVICES_BANNER
    : _PROD_ASSETS.SERVICES_BANNER,
  STOCK_PROFILE_PIC: TEST_MODE
    ? _TEST_ASSETS.STOCK_PROFILE_PIC
    : _PROD_ASSETS.STOCK_PROFILE_PIC,
  BANNER_AIRPORT:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_airport.jpg?alt=media&token=16715d27-fdeb-4773-a074-474f056a0bdd",
  BANNER_ROADSIDE:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_roadside.jpg?alt=media&token=df6b8a0e-b9b4-44c8-a81b-77d90e1a674e",
  BANNER_FLEET:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_fleet.jpg?alt=media&token=31a85fce-cbaa-473f-b959-ee858356f6c4",
  BANNER_CONFERENCE:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_conference.jpg?alt=media&token=edc64ba5-6215-4aab-9d85-6feaa085d12f&_gl=1*6rryxk*_ga*MTQxMTU4OTkxOS4xNjgxNzg3ODA5*_ga_CW55HF8NVT*MTY5NTgzMTA2OC42NDcuMS4xNjk1ODMxMDg4LjAuMC4w",
  BANNER_CORPORATE:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_corporate.jpg?alt=media&token=cec9cf66-48c4-4d03-ad61-921085d6da3d&_gl=1*145dix9*_ga*MTQxMTU4OTkxOS4xNjgxNzg3ODA5*_ga_CW55HF8NVT*MTY5NTgzMTA2OC42NDcuMS4xNjk1ODMxMTA3LjAuMC4w",
  BANNER_CRUIZE:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_cruise.jpg?alt=media&token=2f085e45-61fa-4313-9de5-4a4ca6d27f84&_gl=1*udo86s*_ga*MTQxMTU4OTkxOS4xNjgxNzg3ODA5*_ga_CW55HF8NVT*MTY5NTgzMTA2OC42NDcuMS4xNjk1ODMxMTMyLjAuMC4w",
  BANNER_HOURLY:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_hourly.jpg?alt=media&token=0897b1c2-ac2d-40e4-920c-dba1508f3e23&_gl=1*12u78tw*_ga*MTQxMTU4OTkxOS4xNjgxNzg3ODA5*_ga_CW55HF8NVT*MTY5NTgzMTA2OC42NDcuMS4xNjk1ODMxMTYwLjAuMC4w",
  BANNER_INTERNATIONAL:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_international.jpg?alt=media&token=2dd283bd-60a0-4839-ac1f-30d1da2afdce&_gl=1*qrhdjy*_ga*MTQxMTU4OTkxOS4xNjgxNzg3ODA5*_ga_CW55HF8NVT*MTY5NTgzMTA2OC42NDcuMS4xNjk1ODMxMTc5LjAuMC4w",
  BANNER_SPECIAL_EVENT:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_special.jpg?alt=media&token=42bb999c-b53b-4e4c-b091-ad3ea7a70985&_gl=1*16dkgzf*_ga*MTQxMTU4OTkxOS4xNjgxNzg3ODA5*_ga_CW55HF8NVT*MTY5NTgzMTA2OC42NDcuMS4xNjk1ODMxMjA0LjAuMC4w",
  BANNER_CONTACT:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Fbanner_contact.jpg?alt=media&token=3aa83665-ef31-4d6c-a052-22a0e9a6d980&_gl=1*imatiy*_ga*MTMyMDQ1OTE2LjE2NDQzMTI5Mjg.*_ga_CW55HF8NVT*MTY5NTk4MTM3Ny44MC4xLjE2OTU5ODE0NjUuMzguMC4w",
  BANNER_TAC_APPROVED:
    "https://firebasestorage.googleapis.com/v0/b/luxocabs-12b6b.appspot.com/o/assets%2Ftac_approved_banner.jpg?alt=media&token=3918e4cd-7273-4503-993b-81a7c60ff6b7",
  BENZ: "/assets/images/benz.png",
  LOGO: `/logo.png`,
  IMAGE_BMW: `${imagesPrefix}/bmw.png`,
  IMAGE_BMW_SUV: `${imagesPrefix}/bmw_suv.png`,
  IMAGE_AIRPORT: `${imagesPrefix}/side_airport_transfer_2.jpg`,
  IMAGE_CONFERENCE: `${imagesPrefix}/side_conference.jpg`,
  IMAGE_CORPORATE: `${imagesPrefix}/side_corporate.jpg`,
  IMAGE_INTERNATIONAL_EVENT: `${imagesPrefix}/side_international_transfer.jpg`,
  IMAGE_HOURLY: `${imagesPrefix}/side_hourly.jpg`,
  IMAGE_POINT_TO_POINT: `${imagesPrefix}/side_ptp.jpg`,
  IMAGE_SPECIAL_EVENT: `${imagesPrefix}/side_special_events.jpg`,
  IMAGE_CARDS_SUPPORTED: `${imagesPrefix}/cards_supported.png`,
  IMAGE_CABCHARGE: `${imagesPrefix}/cabcharge.png`,
  ICON_BEST_PRICE: `${iconsPrefix}/best_price.png`,
  ICON_EASY_BOOKING: `${iconsPrefix}/easy_booking.png`,
  ICON_HOME_PICKUP: `${iconsPrefix}/home_pickup.png`,
  ICON_SUPPORT: `${iconsPrefix}/support.png`,
  ICON_SEDAN: `${iconsPrefix}/sedan.png`,
  ICON_SEAT: `${iconsPrefix}/seat.png`,
  ICON_SUITCASE: `${iconsPrefix}/suitcase.png`,
  ICON_AIR_CONDITION: `${iconsPrefix}/air_condition.png`,
  FLEET_SEDAN_LEXUS: `${fleetsPrefix}/sedan_lexus.png`,
  FLEET_SEDAN_BMW: `${fleetsPrefix}/sedan_bmw.png`,
  FLEET_SEDAN_CAPRICE: `${fleetsPrefix}/sedan_caprice.png`,
  FLEET_SEDAN_GENESIS: `${fleetsPrefix}/sedan_genesis.png`,
  FLEET_SEDAN_MERCEDES: `${fleetsPrefix}/sedan_mercedes.png`,
  FLEET_SEDAN_SKODA: `${fleetsPrefix}/sedan_skoda.png`,
  FLEET_SUV_KLUGER: `${fleetsPrefix}/suv_kruger.png`,
  FLEET_SUV_LEXUS: `${fleetsPrefix}/suv_lexus.png`,
  FLEET_MAXI_TAXI: `${fleetsPrefix}/maxi_taxi.png`,
  ILLUS_NOTHING_HERE: `${illustrationsPrefix}/nothing_here.png`,
  ARTISTIC_1: `${imagesPrefix}/artistic_1.jpeg`,
  ARTISTIC_2: `${imagesPrefix}/artistic_2.jpeg`,
  SUBURBS_WE_COVER: `${imagesPrefix}/suburbs_we_cover.jpg`,
  TAC_APPROVED:`${imagesPrefix}/tac_approved_white.png`
};
