import React, { Component } from "react";
import { Menu, Form } from "semantic-ui-react";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";
import OtherCitySelector from "./OtherCitySelector";
import SearchField from "./SearchField";
import withRouter from "../../Utils/router/router_hocs";
import { BOOKING_TYPE } from "../../Utils/enums";
import { AccentButton } from "../Custom/CustomButtons";
import { EMPTY_TRIP } from "../../Utils/Constants";
import { connect } from "react-redux";
import { openModal } from "../../Redux/Modals/ModalActions";
import { MODAL_LOGIN_REGISTER } from "../Modals/Auth/LoginRegisterModal";

let warningStatus = false;
let resultMessage = "";
let sendingMessage = false;
let bookingDone = false;

const mapState = (state) => {
  return {
    authenticated: !state.firebase.auth.isEmpty,
  };
};

const actions = {
  openModal,
};

class MiniBookingForm extends Component {
  state = {
    pickupTime: "",
    pickupDate: "",
    trip: {
      start: {
        name: "",
        position: {
          lat: 0.0,
          lon: 0.0,
        },
      },
      end: {
        name: "",
        position: {
          lat: 0.0,
          lon: 0.0,
        },
      },
    },
    bookingType: BOOKING_TYPE.ONE_WAY,
    returnDate: "nill",
    returnTime: "nill",
  };

  componentWillUnmount() {
    warningStatus = false;
    resultMessage = "";
    sendingMessage = false;
    bookingDone = false;
  }

  handleBookingType = (type) => () => {
    let newState = {};
    newState["bookingType"] = type;
    if (type === BOOKING_TYPE.ROUND_TRIP) {
      newState["returnDate"] = "";
      newState["returnTime"] = "";
    } else {
      newState["returnDate"] = "nill";
      newState["returnTime"] = "nill";
    }
    this.setState(newState);
  };

  handleFieldChange = (e) => {
    warningStatus = false;
    resultMessage = "";
    e.preventDefault();
    let fieldName = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => {
      return {
        [fieldName]: value,
      };
    });
  };

  handleFormSubmit = () => {
    const validation = this.checkFormFields();
    if (validation) {
      let details = this.state;
      this.props.updateBookingForm(details);
      if (this.props.authenticated) {
        this.props.navigate("/booking");
      } else {
        this.props.openModal(MODAL_LOGIN_REGISTER, {
          forLogin: true,
          forBooking: true,
        });
      }
    } else {
      warningStatus = true;
      this.setState({});
    }
  };

  handleTimeChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleDateChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  onPickupSuburbChange = (value) => {
    let newTrip = EMPTY_TRIP;
    newTrip.start = value;
    this.setState({
      trip: newTrip,
    });
  };

  onDropOffSuburbChange = (value) => {
    this.setState((prevState) => {
      let prevTrip = prevState.trip;
      prevTrip.end = value;
      return {
        trip: prevTrip,
      };
    });
  };

  showWarning = (name) => {
    if (name !== "trip.start" && name !== "trip.end") {
      let fieldValue = this.state[name];
      if (warningStatus && fieldValue.trim() === "") {
        return true;
      }
    } else {
      let fieldValue = "";
      if (name === "trip.start") {
        fieldValue = this.state.trip.start.name;
      } else if (name == "trip.end") {
        fieldValue = this.state.trip.end.name;
      }
      if (warningStatus && fieldValue.trim() === "") {
        return true;
      }
    }
    return false;
  };

  checkFormFields = () => {
    let valid = true;
    const stateKeys = Object.keys(this.state);
    if (stateKeys.length > 0) {
      stateKeys.forEach((key) => {
        if (key !== "trip") {
          let value = this.state[key];
          if (value.trim() === "") {
            valid = false;
          }
        } else {
          let value = this.state[key];
          if (value != null) {
            if (
              value.start.name.trim() === "" ||
              value.end.name.trim() === ""
            ) {
              valid = false;
            }
          } else {
            valid = false;
          }
        }
      });
    }
    return valid;
  };

  isRoundTrip = () => {
    return this.state.bookingType === BOOKING_TYPE.ROUND_TRIP;
  };

  isReturnEnabled = () => {
    if (this.state.pickupTime === "" || this.state.pickupDate === "") {
      return false;
    }
    return true;
  };

  decidePickupSelector = () => {
    if (true) {
      return (
        // <CitySelector
        //   name="start"
        //   editable={true}
        //   onAddressChanged={this.onPickupSuburbChange}
        // />
        <SearchField
          name="start"
          editable={true}
          hint="Select Pick-Up Location"
          onAddressChanged={this.onPickupSuburbChange}
        />
      );
    } else {
      return (
        <OtherCitySelector onPickupSuburbChange={this.onPickupSuburbChange} />
      );
    }
  };

  decideDropOffSelector = () => {
    if (true) {
      return (
        <SearchField
          name="end"
          hint="Select Drop-Off Location"
          editable={true}
          onAddressChanged={this.onDropOffSuburbChange}
        />
      );
    } else {
      return (
        <OtherCitySelector onPickupSuburbChange={this.onDropOffSuburbChange} />
      );
    }
  };

  render() {
    const { bookingType } = this.state;

    return (
      <div style={{ width: "100%" }}>
        <Menu pointing secondary fluid color="black">
          <Menu.Item
            active={bookingType === BOOKING_TYPE.ONE_WAY}
            onClick={this.handleBookingType(BOOKING_TYPE.ONE_WAY)}
          >
            One Way
          </Menu.Item>
          <Menu.Item
            active={bookingType === BOOKING_TYPE.ROUND_TRIP}
            onClick={this.handleBookingType(BOOKING_TYPE.ROUND_TRIP)}
          >
            Return Trip
          </Menu.Item>
          <Menu.Item
            active={bookingType === BOOKING_TYPE.AIRPORT_RIDE}
            onClick={this.handleBookingType(BOOKING_TYPE.AIRPORT_RIDE)}
          >
            Airport Transfer
          </Menu.Item>
        </Menu>
        <div className="title-large" style={{ margin: "2rem 0rem" }}>
          Begin the Ride
        </div>
        <Form warning={warningStatus} autoComplete="off">
          <Form.Field
            className="ui segment location-search"
            // disabled={this.state.pickupState === ""}
            width="16"
            error={this.showWarning("trip.start")}
          >
            {this.decidePickupSelector()}
          </Form.Field>
          <Form.Field
            className="ui segment location-search"
            width="16"
            // disabled={this.state.pickupCity.name === ""}
            error={this.showWarning("trip.end")}
          >
            {this.decideDropOffSelector()}
          </Form.Field>
          <Form.Group widths="equal" className="ui segment booking-form">
            <Form.Field error={this.showWarning("pickupDate")}>
              <div className="medium-text">Pick-Up Date</div>
              <DateInput
                hideMobileKeyboard
                name="pickupDate"
                placeholder="Select Date"
                value={this.state.pickupDate}
                minDate={new Date().getDate().toString()}
                iconPosition="left"
                icon={null}
                onChange={this.handleDateChange}
              />
            </Form.Field>
            <Form.Field error={this.showWarning("pickupTime")}>
              <div className="medium-text">Pick-Up Time</div>
              <TimeInput
                hideMobileKeyboard
                timeFormat="ampm"
                name="pickupTime"
                placeholder="Select Time"
                popupPosition="top center"
                value={this.state.pickupTime}
                iconPosition="left"
                icon={null}
                onChange={this.handleTimeChange}
              />
            </Form.Field>
          </Form.Group>
          {this.isRoundTrip() && (
            <Form.Group widths="equal" className="ui segment booking-form">
              <Form.Field
                disabled={!this.isReturnEnabled()}
                error={this.showWarning("returnDate")}
              >
                <div className="medium-text">Return Date</div>
                <DateInput
                  hideMobileKeyboard
                  name="returnDate"
                  placeholder="Return Date"
                  minDate={this.state.pickupDate}
                  value={this.state.returnDate}
                  iconPosition="left"
                  icon={null}
                  onChange={this.handleDateChange}
                />
              </Form.Field>
              <Form.Field
                disabled={!this.isReturnEnabled()}
                error={this.showWarning("returnTime")}
              >
                <div className="medium-text">Pick-Up Time</div>
                <TimeInput
                  hideMobileKeyboard
                  timeFormat="ampm"
                  name="returnTime"
                  placeholder="Return Time"
                  value={this.state.returnTime}
                  iconPosition="left"
                  icon={null}
                  onChange={this.handleTimeChange}
                />
              </Form.Field>
            </Form.Group>
          )}
          <Form.Field>
            <AccentButton
              labelPosition="left"
              fluid
              onClick={() => {
                this.handleFormSubmit();
              }}
              text="Next"
              style={{ padding: "2rem 0rem" }}
            />
          </Form.Field>
        </Form>
      </div>
    );
  }
}

export default connect(mapState, actions)(withRouter(MiniBookingForm));
