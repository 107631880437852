import { PROJECT_MODE, TESTING, STAGING, PRODUCTION } from "./Controller";
const URL_PREFIX_TESTING =
  "http://localhost:5000/luxocabs-testing/australia-southeast1/api";
const URL_PREFIX_STAGING = "https://australia-southeast1-luxocabs-testing.cloudfunctions.net/api";
const URL_PREFIX_PRODUCTION = "https://australia-southeast1-luxocabs-12b6b.cloudfunctions.net/api";

let URL_PREFIX = TESTING;
switch (PROJECT_MODE) {
  case TESTING:
    URL_PREFIX = URL_PREFIX_TESTING;
    break;
  case STAGING:
    URL_PREFIX = URL_PREFIX_STAGING;
    break;
  case PRODUCTION:
    URL_PREFIX = URL_PREFIX_PRODUCTION;
    break;
  default:
    URL_PREFIX = URL_PREFIX_TESTING;
    break;
}


export const USER_EXISTS = URL_PREFIX + "/auth/exists";
export const QUERY_MAIL_URL = URL_PREFIX + "/misc/query";
export const QUERY_TIMESTAMP = URL_PREFIX + "/misc/timestamp";
export const PREVIOUS_ORDERS_URL = URL_PREFIX + "/bookings/user_bookings";
export const DISTANCE_MATRIX = URL_PREFIX + "/maps/distancematrix";
export const CREATE_BOOKING = URL_PREFIX + "/bookings/create";
export const CREATE_CHAT = URL_PREFIX + "/chats/create";
export const CREATE_PAYEMNT_LINK = URL_PREFIX +  "/stripe/payments/create_payment_link";
