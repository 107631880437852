import React, { Component } from "react";
import {
  Button,
  Comment,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Item,
  Segment,
} from "semantic-ui-react";
import { AccentButton } from "../../Custom/CustomButtons";
import firebase from "../../../Firebase/initializeFirebase";
import { Collections } from "../../../Utils/Collections";
import { CREATE_CHAT, QUERY_TIMESTAMP } from "../../../Utils/URLConstants";
import axios from "axios";
import { MODAL_CUSTOM_MESSAGE } from "../../Modals/MessageModal";
import * as randomstring from "randomstring";
import { getCurrentTimeUTC } from "../../../Utils/helperFunctions";
import { isMobile } from "react-device-detect";

export default class HelpCenterSection extends Component {
  unsubscribeMessages;
  unsubscribeChat;
  
  state = {
    chat: null,
    messages: null,
    error: null,
    loading: true,
    exists: false,
    sendingMessage:false
  };

  componentDidMount() {
    const { auth } = this.props;
    const { uid } = auth;
    this.loadChat(uid);
    if(isMobile){
      // document.body.classList.add("stop-scroll");
      
    }
  }

  loadChat = async (uid) => {
    try {
      if (this.unsubscribeChat) {
        this.unsubscribeChat();
      }
      this.setState({
        loading: true,
        error: null,
        chat: null,
        exists: false,
      });
      this.unsubscribeChat = firebase
        .firestore()
        .collection(Collections.SUPPORT)
        .doc(`support_${uid}`)
        .onSnapshot((chatSnapshot) => {
          this.setState({
            chat: chatSnapshot.data(),
            exists: chatSnapshot.exists,
            loading: false,
            error: null,
          });
          if (chatSnapshot.exists && !this.unsubscribeMessages) {
            this.startChatSubscription(uid);
          }
        });
    } catch (e) {
      this.setState({
        error: "Problem Loading Support!",
        loading: false,
      });
      console.log(e);
    }
  };

  startChatSubscription = (uid) => {
    if (this.unsubscribeMessages) {
      this.unsubscribeMessages();
    }
    this.unsubscribeMessages = firebase
      .firestore()
      .collection(Collections.SUPPORT)
      .doc(`support_${uid}`)
      .collection(Collections.GROUP_MESSAGES)
      .orderBy("createdAt", "asc")
      .onSnapshot(
        (snapshot) => {
          let messages = snapshot.docs.map((e) => e.data());
          this.setState({
            messages: messages,
          });
          this.scrollToLastMessage();
        },
        (error) => {
          this.setState({
            error: error.message,
          });
        }
      );
  };

  handleStartChat = async () => {
    try {
      const { openModal, auth } = this.props;
      const { uid } = auth;
      const response = await axios.post(CREATE_CHAT, {
        token: uid,
      });
      const responseData = response.data;
      if (responseData["status"] === 0) {
        this.loadChat(uid);
      } else {
        openModal(MODAL_CUSTOM_MESSAGE, {
          heading: "Error",
          message: "Problem Starting Support Chat",
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  scrollToLastMessage() {
    const messageScrollView = document.getElementById("scroll-view");
    messageScrollView.focus();
    messageScrollView.scrollTop = messageScrollView.scrollHeight;
    
  }

  sendMessage = async () => {
    try {
      const { openModal, auth } = this.props;
      const { uid } = auth;
      const messageBox = document.getElementById("message-box");

      if (messageBox) {
        const text = messageBox.value.trim();

        if (text.length > 0) {
          this.setState({
            sendingMessage:true
          })
          let timestamp = await axios({
            method: "GET",
            url: QUERY_TIMESTAMP,
          });

          if (timestamp.data["status"] == 0) {
            const currentTime = timestamp.data['timestamp'];
            const messageItem = {
              id:
                "message_" +
                randomstring.generate({
                  charset: "alphanumeric",
                  length: 14,
                }),
              createdAt: currentTime,
              contentType: 0,
              content: text,
              uid: uid,
            };
            const firestore = firebase.firestore();
            let batch = firestore.batch();
            batch.set(
              firestore
                .collection(Collections.SUPPORT)
                .doc(`support_${uid}`)
                .collection(Collections.GROUP_MESSAGES)
                .doc(messageItem.id),
              messageItem
            );
            batch.update(
              firestore.collection(Collections.SUPPORT).doc(`support_${uid}`),
              {
                lastMessage: {
                  senderUID: uid,
                  message: text,
                  messageAt: currentTime,
                },
                [`status.${uid}.prevMessagesCount`]:
                  firebase.firestore.FieldValue.increment(1),
                totalMessages: firebase.firestore.FieldValue.increment(1),
              }
            );
            await batch.commit();
            messageBox.value = "";
            this.scrollToLastMessage();
          } 
        }
      }
    } catch (e) {
      console.log(e);
    }
    this.setState({
      sendingMessage:false
    })
  };

  componentWillUnmount() {
    if (this.unsubscribeMessages) {
      this.unsubscribeMessages();
    }
    if (this.unsubscribeChat) {
      this.unsubscribeChat();
    }
  }

  render() {
    const { loading, chat, exists, error, messages,sendingMessage } = this.state;
    return (
      <Segment
        basic
        loading={loading}
        placeholder={loading}
        style={{ height: "100%", width: "100%", height: isMobile?"70vh":"80vh" }}
      >
        {exists ? (
          <Segment
            loading={messages == null}
            basic
            style={{ height: "100%", width: "100%", padding: "0px",position:"relative" }}
          >
            {messages && (
              <Comment.Group style={{ padding: "0px" }}>
                <div
                  id="scroll-view"
                  inverse={true}
                  dataLength={messages.length} //This is important field to render the next data
                  hasMore={false}
                  style={{
                    position: "absolute",
                    bottom: isMobile?"15vh":"10vh",
                    height: "100%",
                    width: "100%",
                    overflowY: "scroll",
                  }}
                >
                  <div style={{ height: "10vh" }}></div>
                  {messages.map((message) => {
                    const userDetails = chat.profiles[message.uid];
                    const fullName =
                      userDetails["firstName"] + " " + userDetails["lastName"];
                    return (
                      <Comment>
                        <Comment.Avatar
                          as={Image}
                          src={userDetails["profilePic"]}
                          bordered
                          avatar
                          circular
                        />
                        <Comment.Content>
                          <Comment.Author>{fullName}</Comment.Author>
                          <Comment.Metadata>
                            <div>
                              {new Date(
                                message["createdAt"]
                              ).toLocaleTimeString()}
                            </div>
                          </Comment.Metadata>
                          <Comment.Text>{message["content"]}</Comment.Text>
                        </Comment.Content>
                      </Comment>
                    );
                  })}
                  <div id="messages-end"></div>
                </div>

                <Segment
                  style={{
                    position: "absolute",
                    bottom: "0px",
                    left: "0px",
                    width: "100%",
                    paddingBottom: "0px",
                  }}
                >
                  <Form
                    inverted
                    reply
                    loading={sendingMessage}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Form.Group style={{ width: "100%" }} inline>
                      <Form.Field width={14}>
                        <input
                          id="message-box"
                          placeholder="Write your Message Here"
                          type="text"
                          
                        />
                       
                      </Form.Field>
                      <Button
                        content="Send"
                        labelPosition="left"
                        icon="send"
                        
                        primary
                        onClick={() => {
                          this.sendMessage();
                        }}
                      />
                      
                    </Form.Group>
                  </Form>
                </Segment>
              </Comment.Group>
            )}
          </Segment>
        ) : (
          <Grid
            columns={1}
            verticalAlign="middle"
            textAlign="center"
            style={{ height: "100%" }}
          >
            <Grid.Column>
              <Header content="Want to talk to our Support Executive?" />

              <AccentButton
              loading={sendingMessage}
                labelPosition="left"
                onClick={() => {
                  this.handleStartChat();
                }}
                icon={<Icon name="chat" />}
                text="Start Chat"
              />
            </Grid.Column>
          </Grid>
        )}
       
      </Segment>
    );
  }
}
