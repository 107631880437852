import React from "react";
import { Link } from "react-router-dom";
import { Image, Label } from "semantic-ui-react";
import { ASSETS } from "../../Utils/Assets";

export default function TACApprovedIndicatorTwo(props) {
    const {showCheck = true} = props;
  return (
    <div basic compact style={{ margin: "0px" }}>
      <Link to={"/tac-approved"}>
        <Image
          bordered
          src={ASSETS.TAC_APPROVED}
          size="tiny"
          style={{ border: "1px solid white" }}
        />
        {showCheck && (
          <Label
            attached="top right"
            icon="check"
            color="green"
            size="mini"
            circular
          ></Label>
        )}
      </Link>
    </div>
  );
}
