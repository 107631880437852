import React, { useState } from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation
import "./SuburbsWeCoverSection.css";
import { Grid, Icon, Image, List, Segment } from "semantic-ui-react";
import { ASSETS } from "../../../Utils/Assets";
import { AccentButton } from "../../Custom/CustomButtons";

const suburbsData = [
  {
    name: "Suburb A",
    description: "Brief info about Suburb A.",
    detailPage: "/suburb-a",
  },
  {
    name: "Suburb B",
    description: "Brief info about Suburb B.",
    detailPage: "/suburb-b",
  },
  {
    name: "Suburb C",
    description: "Brief info about Suburb C.",
    detailPage: "/suburb-c",
  },
  // Add more suburbs as needed
];

const SuburbsWeCoverSection = () => {
  const [selectedSuburb, setSelectedSuburb] = useState(null);

  const handleSelectSuburb = (suburb) => {
    setSelectedSuburb(suburb);
  };

  return (
    <Segment padded basic secondary textAlign="center" attached="bottom">
      <div className="title-large">Suburbs We Cover</div>
      <div className="large-text">
        Seamless Airport Transfers: Your Gateway to Melbourne's Suburbs
      </div>
      <br />
      <br />
      <Grid padded columns={"equal"} textAlign="left" stackable>
        <Grid.Column>
          <Image
            src={ASSETS.SUBURBS_WE_COVER}
            size="medium"
            centered
            circular
          />
        </Grid.Column>
        <Grid.Column verticalAlign="middle">
          <List bulleted size="large" animated relaxed>
            <List.Item
              as="a"
              href="/suburbs-we-cover/Wyndham Vale to Melbourne Airport"
            >
              Wyndham Vale to Melbourne Airport
            </List.Item>
            <List.Item as="a" href="/suburbs-we-cover/Casey Taxi Service">
              Casey Taxi Service
            </List.Item>
            <List.Item
              as="a"
              href="/suburbs-we-cover/Tarneit to Melbourne Airport"
            >
              Tarneit to Melbourne Airport
            </List.Item>
            <List.Item
              as="a"
              href="/suburbs-we-cover/Truganina to Melbourne Airport"
            >
              Truganina to Melbourne Airport
            </List.Item>
            <List.Item
              as="a"
              href="/suburbs-we-cover/Altona to Melbourne Airport"
            >
              Altona to Melbourne Airport
            </List.Item>
            <List.Item
              as="a"
              href="/suburbs-we-cover/Williamstown to Melbourne Airport"
            >
              Williamstown to Melbourne Airport
            </List.Item>
          </List>
          <br />
          <Link to={"/suburbs-we-cover/all"}>
            <AccentButton
              text="View All Suburbs"
              icon={<Icon name="arrow circle right" />}
            />
          </Link>
        </Grid.Column>
      </Grid>
      <br />
      <br />
    </Segment>
  );
};

export default SuburbsWeCoverSection;
