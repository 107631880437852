import React, { useState } from "react";
import "./FAQSection.css";
import { AccentButton } from "../Custom/CustomButtons";
import { Link } from "react-router-dom";
import { Segment } from "semantic-ui-react";

export default function PortableFAQSection() {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Can I use my MPTP Card?",
      answer:
        "It is permissible to utilise your MPTP Card to receive discounted cab fares. However, the cardholder must be present in the taxi cab for the duration of the trip to qualify for this benefit. For further details, please follow the link provided: https://cpv.vic.gov.au/passengers/mptp",
    },
    {
      question: "Do you provide child seats?",
      answer:
        "It is important to note that Victorian cabs and their drivers are not legally permitted to provide child seats, restraints, or capsules. Rest assured that all cabs are equipped with an anchor bolt or restraint to support passengers during the ride. It is highly recommended that passengers adhere to these guidelines to ensure the safety and security of all parties involved.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <Segment secondary basic className="faqSection" attached="bottom" style={{padding:"2em"}}>
      <div className="faqHeader">
        <h2 className="faqTitle">Frequently Asked Questions</h2>
        <p className="faqDescription"></p>
      </div>
      <div className="faqList">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faqItem ${activeIndex === index ? "active" : ""}`}
            onClick={() => toggleFAQ(index)}
          >
            <div className="faqQuestion">{faq.question}</div>
            <div className="faqAnswer">
              {activeIndex === index && <p>{faq.answer}</p>}
            </div>
          </div>
        ))}
      </div>
      <Link  to="/faq" >
      <AccentButton text="More FAQs" />
      </Link>
      
    </Segment>
  );
}
