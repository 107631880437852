import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Grid, Icon, Input, List, Segment } from "semantic-ui-react";
// import Script from "react-load-script";

export default class SearchField extends Component {
  state = {
    address: "",
    scriptLoaded: true,
  };

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        address: this.props.value,
      });
    }
  }

  handleScriptLoad = () => {
    console.log("Loaded");

    this.setState({
      scriptLoaded: true,
    });
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  handleAddressChange = (data) => {
    this.props.onAddressChanged(data);
  };

  handleSelect = (address) => {
    var _this = this;
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        let data = {
          name: address,
          position: {
            lat: parseFloat(latLng.lat.toFixed(5)),
            lon: parseFloat(latLng.lng.toFixed(5)),
          },
        };
        _this.handleAddressChange(data);
        this.setState({
          address: data.name,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  render() {
    const { hint, style, showIcon = true, inverted = false } = this.props;

    return (
      <div>
        {window.mapsAPILoaded && (
          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
            debounce={1000}
            searchOptions={
              {
                // locationBias: {
                //   radius: 500000,
                //   center: { lat: 30.75224, lng: 75.60127 },
                // },
                // types: ["(cities)"],
              }
            }
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <div style={{ position: "relative" }}>
                <Input
                  iconPosition={showIcon ? "left" : null}
                  placeholder="Email"
                  inverted={inverted}
                >
                  {showIcon && (
                    <Icon name="map marker alternate" inverted={inverted} />
                  )}
                  <input
                    {...getInputProps({
                      placeholder: hint ?? "Select Address",
                      className: "location-search-input",
                    })}
                    style={style}
                  />
                </Input>

                <div
                  style={{
                    position: "absolute",
                    zIndex: "5",
                    width: "100%",
                    opacity: "1",
                    border: "1px solid lightblue",
                  }}
                  className="autocomplete-dropdown-container"
                >
                  {loading && <Segment placeholder loading />}
                  {suggestions && suggestions.length > 0 && (
                    <Segment>
                      <List divided relaxed>
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#fafafa",
                                cursor: "pointer",
                                padding: "1% 1%",
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer",
                                padding: "1% 1%",
                              };
                          return (
                            <div
                            key={suggestion.description}
                              role="listitem"
                              className="item"
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <div className="content">
                                {suggestion.description}
                              </div>
                            </div>
                          );
                        })}
                      </List>
                    </Segment>
                  )}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        )}
      </div>
    );
  }
}
