import {
  LOADING_PAYMENTS_END,
  LOADING_PAYMENTS_START,
  SET_PAYMENTS,
} from "./paymentsConstants";

const initialState = {
  payments: {},
  loading: false,
};

const paymentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_PAYMENTS_START:
      return { ...state, loading: true };
    case SET_PAYMENTS:
      return {
        ...state,
        payments: action.payments,
        loading: false,
      };
    case LOADING_PAYMENTS_END:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default paymentsReducer;
