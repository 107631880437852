import { legacy_createStore, applyMiddleware } from "redux";
import { getFirebase, reactReduxFirebase, reduxReactFirebase } from "react-redux-firebase";
import { reduxFirestore, getFirestore } from "redux-firestore";
import rootReducer from "./rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import firebase from "../Firebase/initializeFirebase";

const rrfConfig = {
  userProfile: "users",
  attachAuthIsReady: true,
  useFirestoreForProfile: true,
};

export const configureStore = () => {
  const middlewares = [thunk.withExtraArgument({ getFirebase, getFirestore })];
  
  const composedEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares),
    // reduxReactFirebase(firebase, rrfConfig),
    reduxFirestore(firebase)
  );
  const store = legacy_createStore(rootReducer, composedEnhancer);
  return store;
};
