import React from "react";
import { Button, Divider } from "semantic-ui-react";
import { ON_PRIMARY_COLOR, PRIMARY_COLOR } from "../../Utils/Constants";

export const AccentButton = (props) => {
  const {
    id,
    text,
    onClick,
    size,
    color,
    fluid,
    style,
    floated,
    disabled,
    loading,
    icon,
    labelPosition,
    className = "title-medium",
    inverted = false,
  } = props;
  return (
    <Button
      id={id}
      icon
      disabled={disabled || loading}
      loading={loading ?? false}
      fluid={fluid}
      size={size}
      labelPosition={labelPosition}
      floated={floated}
      className={className}
      style={{
        fontSize: "1.1em",
        color: inverted ? PRIMARY_COLOR : ON_PRIMARY_COLOR,
        backgroundColor: color ?? (inverted ? ON_PRIMARY_COLOR : PRIMARY_COLOR),
        outline: "none",
        borderRadius: "10px",
        padding: "0.5em 2em",
        ...style,
      }}
      onClick={onClick}
    >
      {icon}
      {text}
    </Button>
  );
};

export const LightDivider = (props) => {
  return <Divider style={{ borderTop: "1px solid #EFEAEA", ...props.style }} />;
};
