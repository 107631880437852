import React, { Component } from "react";
import {
  Header,
  Segment,
  Grid,
  Item,
  Label,
  Image,
  Table,
  Button,
  Icon,
  Message,
} from "semantic-ui-react";

import Axios from "axios";

import {
  CREATE_PAYEMNT_LINK,
  PREVIOUS_ORDERS_URL,
} from "../../../Utils/URLConstants";
import { isMobile } from "react-device-detect";
import { ASSETS } from "../../../Utils/Assets";
import { AccentButton, LightDivider } from "../../Custom/CustomButtons";
import withRouter from "../../../Utils/router/router_hocs";
import { BOOKING_STATUS, RIDE_TYPE } from "../../../Utils/enums";
import {
  getBookingStatusString,
  getBookingTypeString,
  getRideTypeString,
} from "../../../Utils/enumFunctions";
import {
  SUPPORT_CONTACT,
  SUPPORT_CONTACT_TEXT,
} from "../../../Utils/Constants";

class PreviousOrdersSection extends Component {
  mounted = false;

  state = {
    orders: null,
  };

  componentDidMount() {
    this.mounted = true;
    this.getOrders();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getOrders = async () => {
    let orders;
    try {
      this.setState({
        orders: null,
      });

      const { auth } = this.props;

      const { uid } = auth;
      const QUERY_URL =
        PREVIOUS_ORDERS_URL +
        "?" +
        new URLSearchParams({
          token: uid,
        }).toString();
      let prevOrdersResponse = await Axios({
        method: "GET",
        url: QUERY_URL,
      });
      const ordersData = prevOrdersResponse.data;
      if (ordersData["status"] === 0) {
        orders = ordersData["bookings"];
      }
    } catch (e) {
      console.log(e);
    } finally {
      // this.setState({})
      this.setState({
        orders: orders,
      });
    }
  };

  render() {
    const { orders } = this.state;
    const { openModal, auth } = this.props;

    return (
      <div style={{ backgroundColor: "white" }}>
        <Segment
          padded
          basic
          style={{ marginBottom: "0px", paddingBottom: "0px" }}
        >
          <span className={isMobile ? "title-medium" : "title-large"}>
            My Bookings
            <Button
              onClick={this.getOrders}
              style={{ marginLeft: "1rem" }}
              circular
              basic
              icon={<Icon name="refresh" />}
            />
          </span>

          <LightDivider style={{ marginBottom: "0px" }} />
        </Segment>
        <Segment
          loading={orders == null}
          placeholder={orders == null}
          basic
          style={{
            backgroundColor: "white",
            marginTop: "0px",
            minHeight: "50vh",
          }}
          textAlign="center"
        >
          {orders && (
            <div>
              {orders.length > 0 && (
                <div>
                  {orders.map((order, index) => {
                    return (
                      <div key={index}>
                        <PreviousOrderItem
                          auth={auth}
                          orderData={order}
                          openModal={openModal}
                        />
                      </div>
                    );
                  })}
                </div>
              )}

              {orders.length <= 0 && (
                <Grid style={{ height: "50vh" }}>
                  <Grid.Column
                    stretched
                    verticalAlign="middle"
                    textAlign="center"
                  >
                    <Image
                      centered
                      src={ASSETS.ILLUS_NOTHING_HERE}
                      size="large"
                    />
                    <Header content="No Bookings Yet! 😅" />
                  </Grid.Column>
                </Grid>
              )}
            </div>
          )}
          <br />
        </Segment>
      </div>
    );
  }
}

export default withRouter(PreviousOrdersSection);

class PreviousOrderItem extends Component {
  state = {
    expanded: false,
    order_name: "",
    image: null,
    loading: false,
  };

  toggleExpand = () => {
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  toProductPage = (item_code) => () => {
    this.props.push(`/product/${item_code}`);
  };

  createPaymentLink = async () => {
    try {
      this.setState({
        loading: true,
      });
      const { auth, orderData } = this.props;

      const { uid } = auth;
      let createPaymentLinkResponse = await Axios({
        method: "POST",
        url: CREATE_PAYEMNT_LINK,
        data: {
          token: uid,
          booking: orderData["id"],
        },
      });

      console.log(createPaymentLinkResponse.data);
      if (createPaymentLinkResponse.data["status"] === 0) {
        const data = createPaymentLinkResponse.data["data"];
        window.open(data["url"], "_blank");
      } else {
        window.alert("Problem Initiating Payment");
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  getImageAsset = (serviceType) => {
    switch (serviceType) {
      case RIDE_TYPE.SUV:
        return ASSETS.IMAGE_BMW_SUV;
      case RIDE_TYPE.SEDAN:
        return ASSETS.FLEET_SEDAN_BMW;
      case RIDE_TYPE.MAXO_TAXI:
        return ASSETS.FLEET_MAXI_TAXI;
      default:
        return ASSETS.FLEET_MAXI_TAXI;
    }
  };

  render() {
    const { orderData } = this.props;

    let bookingAccepted = orderData["status"] === BOOKING_STATUS.accepted;
    let bookingProcessing = orderData["status"] === BOOKING_STATUS.processing;
    let bookingPaid = orderData["status"] === BOOKING_STATUS.paid;
    let isMaxiTax = orderData["serviceType"] === RIDE_TYPE.MAXO_TAXI;
    let image = this.getImageAsset(orderData["serviceType"]);
    return (
      <div>
        <Segment
          basic
          loading={this.state.loading}
          style={{
            padding: "0px",
            marginTop: "2%",
            textAlign: "left",
            height: "100%",
          }}
        >
          <Item className="medium-text">
            Order: <span style={{ fontWeight: "bold" }}>{orderData.id}</span>
          </Item>

          <Segment
            basic
            style={{
              padding: "0px",
              border: `1px solid lightgray`,
            }}
            textAlign="center"
          >
            <Grid stackable verticalAlign="middle">
              <Grid.Column
                width="3"
                textAlign="center"
                style={{ paddingRight: "0px" }}
                onClick={() => {}}
              >
                <Image
                  size="small"
                  centered
                  style={{ margin: "auto auto auto auto" }}
                  src={image}
                  onError={(e) => {
                    e.target.src = ASSETS.BENZ;
                  }}
                />
                <div
                  className="title-medium"
                  style={{ marginBottom: "0.5rem" }}
                >
                  {getRideTypeString(orderData["serviceType"])}
                </div>

                <Label color="green">
                  <Icon name="car" />{" "}
                  {getBookingTypeString(orderData.bookingType)}
                </Label>
                {!bookingProcessing && !isMobile && <br />}
                <br />
              </Grid.Column>
              <Grid.Column width="13" stretched style={{ paddingLeft: "0px" }}>
                <Segment basic style={{ borderLeft: "1px solid #EFEAEA" }}>
                  <Grid stackable>
                    <Grid.Column width={12}>
                      <Table basic="very" attached="bottom">
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell>
                              <div className="title-small">From</div>
                              <Label>
                                <Icon name="map marker alternate" />{" "}
                                {orderData.trip.start.name}
                              </Label>
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: "left" }}>
                              <div className="title-small">To</div>
                              <Label>
                                <Icon name="map marker alternate" />{" "}
                                {orderData.trip.end.name}
                              </Label>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row>
                            <Table.Cell style={{ fontWeight: "bold" }}>
                              Booking Amount
                            </Table.Cell>
                            <Table.Cell style={{ textAlign: "left" }}>
                              {!isMaxiTax || bookingAccepted ? (
                                <div className="title-medium">
                                  {bookingAccepted ? (
                                    <div>
                                      {`${
                                        orderData.finalAmount ??
                                        orderData.estimatedAmount
                                      } AUD`}
                                    </div>
                                  ) : (
                                    <div>
                                      ${orderData.estimatedAmount} - $
                                      {orderData.estimatedAmount + 20} AUD
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div>
                                  <div className="medium-text">
                                    Please Call{" "}
                                    <a href={`tel:${SUPPORT_CONTACT}`}>
                                      <Icon name="call" />{" "}
                                      {SUPPORT_CONTACT_TEXT}
                                    </a>{" "}
                                    for Estimated Price
                                  </div>
                                </div>
                              )}
                            </Table.Cell>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                      {bookingProcessing && (
                        <Message
                          size="mini"
                          content={
                            <div>
                              Final Price will be updated here once your booking
                              is Accepted
                              <div>
                                Please Call{" "}
                                <a href={`tel:${SUPPORT_CONTACT}`}>
                                  <Icon name="call" /> {SUPPORT_CONTACT_TEXT}
                                </a>{" "}
                                for Estimated Price
                              </div>
                            </div>
                          }
                        />
                      )}
                    </Grid.Column>
                    <Grid.Column width={4} verticalAlign="middle">
                      {bookingPaid ? (
                        <AccentButton
                          disabled={true}
                          text="Paid"
                          style={{ margin: "auto" }}
                        />
                      ) : (
                        <AccentButton
                          disabled={!bookingAccepted || this.state.loading}
                          onClick={this.createPaymentLink}
                          text="Pay Now"
                          style={{ margin: "auto" }}
                        />
                      )}
                      {!bookingPaid && (
                        <div
                          className="small-text"
                          style={{ marginTop: "1rem" }}
                        >
                          Convenience Charges will Apply
                        </div>
                      )}
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid>
            <Label
              size="small"
              attached="top right"
              content={`${getBookingStatusString(orderData["status"])}`}
            />
          </Segment>
        </Segment>
      </div>
    );
  }
}
