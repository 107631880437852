import testReducer from "./testReducer";
import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import authReducer from "./Auth/authReducer";
import modalReducer from "./Modals/ModalReducer";
import userReducer from "./Users/userReducer";
import serviceOrderReducer from "./ServiceOrderReducer/serviceOrderReducer";
import paymentsReducer from "./PaymentsReducer/paymentsReducer";
import formsReducer from "./Forms/formsReducer";
import bookingsReducer from "./Booking/bookingReducer";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  // firestore: firestoreReducer,
  auth: authReducer,
  forms: formsReducer,
  serviceOrder: serviceOrderReducer,
  payments: paymentsReducer,
  user: userReducer,
  modal: modalReducer,
  test: testReducer,
  bookings:bookingsReducer
});

export default rootReducer;
