import React from "react";
import { Segment, Grid, Image, Button, Icon } from "semantic-ui-react";
import { ASSETS } from "../../../Utils/Assets";
import { Link } from "react-router-dom";

export default function RideWithYourComfort() {
  return (
    <Segment basic attached="bottom" secondary>
      <br />
      <br />
      <div className="heading-text" style={{ textAlign: "center" }}>
        Ride With Your Comfort
      </div>
      <br />
      <div className="large-text" style={{ textAlign: "center" }}>
        Try Luxo Cabs wide Range of Services to cover all your Transportation Needs
      </div>
      <br />
      <br />
      <Grid centered>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <ComfortElement
              title={"Airport Transfer"}
              image={`${ASSETS.IMAGE_AIRPORT}`}
              description={
                "Booking your airport transfer with Luxo Cabs is a breeze! Just go to our super easy online booking system or through our call centre to book your ride."
              }
              to={"/services/airport-transfers"}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <ComfortElement
              title={"Corporate Transfer"}
              image={`${ASSETS.IMAGE_CORPORATE}`}
              description={
                "Here are some specific examples of how LuxoCabs can be a partner in corporate transportation for businesses in Melbourne"
              }
              to={"/services/corporate-transfers"}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <ComfortElement
              title={"Conferences"}
              image={`${ASSETS.IMAGE_CONFERENCE}`}
              description={
                "We understand that conferences can be demanding, so we make it our priority to make your travel as stress-free and as comfortable as possible. "
              }
              to={"/services/conferences"}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={5}>
            <ComfortElement
              title={"International Events"}
              image={`${ASSETS.IMAGE_INTERNATIONAL_EVENT}`}
              description={
                "Luxo Cabs is committed to providing a safe and reliable transportation service to all of its customers, including international students."
              }
              to={"/services/international-event-transfers"}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <br />
      <br />
    </Segment>
  );
}

function ComfortElement(props) {
  const { title, description, image, to } = props;
  return (
    <Segment style={{ backgroundColor: "#F1F1F1" }}>
      <Grid columns={2}>
        <Grid.Column width={8}>
          <Image src={image} />
        </Grid.Column>
        <Grid.Column width={8}>
          <div className="title-small">{title}</div>
          <div className="small-text" style={{ marginTop: "0.5rem" }}>
            {description}
          </div>
          <br />
          <div style={{ textAlign: "left" }}>
            <Button
              as={Link}
              to={to}
              className="no-shadow-border"
              size="tiny"
              basic
              
              labelPosition="right"
              
              style={{ marginTop: "0.5rem",boxShadow:"none" }}
            >
              Read More
              <Icon name="angle right" />
            </Button>
          </div>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
