import { isProd } from "./Controller";

export const sendPhoneCallEvent = () => {
  if (isProd) {
    window.gtag("config", "AW-11371028950");
    window.gtag("event", "conversion", {
      send_to: "AW-11371028950/xurJCN3ilOwYENbDkK4q",
    });
    console.log("SENT PHONE CALL EVENT");
  }
};
