import React, { Component } from "react";
import { Grid, List, Header, Icon, Segment, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { ASSETS } from "../../Utils/Assets";
import {
  PRIMARY_COLOR,
  SERVICES,
  SUPPORT_CONTACT,
  SUPPORT_CONTACT_TEXT,
  SUPPORT_EMAIL,
} from "../../Utils/Constants";
import { PRODUCTION, PROJECT_MODE } from "../../Utils/Controller";
import { sendPhoneCallEvent } from "../../Utils/analyticsFunctions";
import TACApprovedIndicatorTwo from "../Misc/TACApprovedIndicatorTwo";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <Segment
          inverted
          basic
          style={{
            margin: "0px 0px",
            padding: "0px 0px",
            backgroundColor: PRIMARY_COLOR,
          }}
        >
          <div style={{ padding: "1% 1%" }}>
            <Grid inverted padded stackable divided columns="equal" centered>
              <Grid.Column
                mobile="16"
                tablet="16"
                computer="5"
                verticalAlign="middle"
                textAlign="left"
              >
                <Image size="small" src={ASSETS.LOGO} />
                <TACApprovedIndicatorTwo showCheck={false} />
                <br />
                <List inverted verticalAlign="middle">
                  <List.Item style={{ marginBottom: "1rem" }}>
                    {/* <Icon name="mail" /> */}
                    <List.Content to="/contact">
                      <Icon disabled name="mail" inverted />
                      <a href={`mailto:${SUPPORT_EMAIL}`}>
                        {SUPPORT_EMAIL}
                      </a>{" "}
                      <br />
                    </List.Content>
                  </List.Item>

                  <List.Item style={{ marginBottom: "1rem" }}>
                    {/* <Icon name="call" /> */}
                    <List.Content>
                      <Icon disabled name="call" inverted />
                      <a
                        href={`tel:${SUPPORT_CONTACT}`}
                        onClick={() => {
                          sendPhoneCallEvent();
                        }}
                      >
                        {SUPPORT_CONTACT_TEXT}
                      </a>
                    </List.Content>
                  </List.Item>
                  <List.Item style={{ marginBottom: "1rem" }}>
                    {/* <Icon name="time" /> */}
                    <List.Content>
                      <Icon disabled name="clock" inverted />
                      24/7 Customer Support
                    </List.Content>
                  </List.Item>
                  <List.Item style={{ marginBottom: "1rem" }}>
                    {/* <Icon name="time" /> */}
                    <List.Content>
                      <Icon disabled name="payment" inverted />
                      Secure Payments
                      <br />
                      <br />
                      <Image src={ASSETS.IMAGE_CARDS_SUPPORTED} size="medium" />
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="16" computer="5">
                <p className="title-large" style={{ color: "white" }}>
                  Services Range
                </p>
                <List
                  inverted
                  animated
                  selection
                  divided
                  verticalAlign="middle"
                >
                  {SERVICES.map((service) => (
                    <List.Item key={service.to}>
                      <List.Content as={Link} to={service.to}>
                        <List.Header>{service.title}</List.Header>
                      </List.Content>
                    </List.Item>
                  ))}
                  <List.Item key={"suburbs-we-cover/all"}>
                      <List.Content as={Link} to={"/suburbs-we-cover/all"}>
                        <List.Header>Suburbs we Cover</List.Header>
                      </List.Content>
                    </List.Item>
                </List>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="16" computer="5">
                <p className="title-large" style={{ color: "white" }}>
                  Navigation
                </p>
                <List
                  inverted
                  animated
                  selection
                  divided
                  verticalAlign="middle"
                >
                  <List.Item key={"home"}>
                    <List.Content as={Link} to="/">
                      <List.Header>Home</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"contact"}>
                    <List.Content as={Link} to="/contact">
                      <List.Header>Contact Us</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"about"}>
                    <List.Content as={Link} to="/about">
                      <List.Header>Know About Us?</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"tac-approved"}>
                    <List.Content as={Link} to="/tac-approved">
                      <List.Header>TAC Approved</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"faq"}>
                    <List.Content as={Link} to="/faq">
                      <List.Header>Frequently asked Questions</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"terms-and-conditions"}>
                    <List.Content as={Link} to="/terms-and-conditions">
                      <List.Header>Terms and Conditions</List.Header>
                    </List.Content>
                  </List.Item>
                  <List.Item key={"privacy-policy"}>
                    <List.Content as={Link} to="/privacy-policy">
                      <List.Header>Privacy Policy</List.Header>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid>
          </div>

          <Segment
            basic
            padded
            textAlign="center"
            style={{
              backgroundColor: "white",
              margin: "0px 0px",
            }}
          >
            <Header as="h4" textAlign="center" style={{ lineHeight: "2em" }}>
              Copyright© Luxo Cabs [2024]. All Rights Reserved <br />{" "}
              <p style={{ fontWeight: "lighter", fontSize: "0.9em" }}>
                Powered By Luxo Cabs (Version 1.1.0){" "}
                {PROJECT_MODE === PRODUCTION ? "" : "DEV"}
              </p>
            </Header>
          </Segment>
        </Segment>
      </div>
    );
  }
}
