import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/storage";
import "firebase/firestore";

import { PROJECT_MODE, STAGING, TESTING } from "../Utils/Controller";

const PRODUCTION_CONFIG = {
  apiKey: "AIzaSyBtpD7oCSLkI_mzn1n7p5LZv4QCFiXPb6o",
  authDomain: "luxocabs-12b6b.firebaseapp.com",
  projectId: "luxocabs-12b6b",
  storageBucket: "luxocabs-12b6b.appspot.com",
  messagingSenderId: "48316169572",
  appId: "1:48316169572:web:b25f387bc8386a1b900e71",
  measurementId: "G-4CKQYSSJKM",
};

var TEST_CONFIG = {
  apiKey: "AIzaSyCzSKX6DhpHIKWSglt-b-bb0BucJEjoxSo",
  authDomain: "luxocabs-testing.firebaseapp.com",
  projectId: "luxocabs-testing",
  storageBucket: "luxocabs-testing.appspot.com",
  messagingSenderId: "581757120669",
  appId: "1:581757120669:web:e4d2f13ec876dd3d49e474",
  measurementId: "G-S1FMV6FTBM",
};

// Initialize Firebase
firebase.initializeApp(
  PROJECT_MODE === TESTING || PROJECT_MODE === STAGING
    ? TEST_CONFIG
    : PRODUCTION_CONFIG
);
firebase.analytics();
firebase.firestore();

// export const auth = firebaseAuth;
export default firebase;
