import { ASSETS } from "../Assets";
import { RIDE_TYPE } from "../enums";

export const CAR_TYPES = [
  {
    icon: ASSETS.FLEET_SEDAN_LEXUS,
    name: RIDE_TYPE.SEDAN,
    title: "Sedan",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "4 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "2 Suitcase",
      },
    ],
  },
  {
    icon: ASSETS.FLEET_SUV_LEXUS,
    name: RIDE_TYPE.SUV,
    title: "SUV",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "Up to 6 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "2 Suitcase",
      },
    ],
  },
  {
    icon: ASSETS.FLEET_MAXI_TAXI,
    name: RIDE_TYPE.MAXO_TAXI,
    title: "Maxi Taxi",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "Up to 12 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "Confirm on Call",
      },
    ],
  },
];
