import React from "react";
import { Helmet } from "react-helmet";
import { Container } from "semantic-ui-react";

export default function PrivacyPolicy() {
  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <Helmet>
        <title>Privacy Policy - Luxo Cabs</title>
        <meta
          name="description"
          content="Luxo Cabs is a company based in Melbourne Australia that offers reliable transportation services for both locals and tourists."
        />
      </Helmet>
      <Container textAlign="left" style={{ padding: "2rem" }}>
        <div className="title-large" style={{textAlign:"center"}}>
          Privacy Policy - Luxo Cabs
        </div>
        <br/>
        <div className="medium-text">
          Last Updated: September 24, 2023
          <br />
          <br />
          We are committed to protecting the privacy of our customers. This
          privacy policy explains what information we collect, how we use it,
          and with whom we share it.
        </div>
        <br />
        <div className="title-medium">Information We Collect</div>
        <br />
        <div className="medium-text">
          We collect the following information from our customers:
          <br />
          <br />
          <ol>
            <li>Name</li>
            <li>
              Contact information (including email address and phone number)
            </li>
            <li>Pickup and drop-off locations</li>
            <li>Ride history</li>
            <li>Payment information</li>
          </ol>
        </div>
        <div className="title-medium">How We Use Your Information</div>
        <br />
        <div className="medium-text">
          We use your information to provide you with our taxi services,
          including:
          <br />
          <br />
          <ol>
            <li>Booking and dispatching rides</li>
            <li>Processing payments</li>
            <li>Communicating with you about your rides s</li>
            <li>Improving our services</li>
          </ol>
        </div>
        <div className="title-medium">With Whom We Share Your Information</div>
        <br />
        <div className="medium-text">
          We share your information with the following:
          <br />
          <br />
          <ol>
            <li>Our drivers</li>
            <li>Payment processors</li>
            <li>Law enforcement agencies (if required by law)</li>
          </ol>
          We <strong>do not</strong> sell or rent your information to any third
          parties for marketing purposes.
        </div>
        <br />
        <div className="title-medium">Your Choices:</div>
        <br />
        <div className="medium-text">
          You have the following choices regarding your privacy:
          <br />
          <br />
          <ol>
            <li>
              You can choose to not provide us with any information. However,
              this may limit your ability to use our services.
            </li>
            <li>
              You can access and review your information by contacting us.
            </li>
            <li>
              You can request that we delete your information by contacting us.
            </li>
          </ol>
        </div>
        <div className="title-medium">Your Choices:</div>
        <br />
        <div className="medium-text">
          We take security measures to protect your personal information from
          unauthorised access, disclosure, alteration, or destruction. These
          measures include encryption, access controls, and regular security
          assessments.
        </div>
        <br/>
        <div className="title-medium">Changes to This Privacy Policy</div>
        <br />
        <div className="medium-text">
          We may update this privacy policy from time to time. If we make any
          material changes to this privacy policy, we will post a notice on our
          website.
        </div>
        <br/>
        <div className="title-medium">Contact Us</div>
        <br />
        <div className="medium-text">
          If you have any questions about this privacy policy, please contact us
          at info@luxocabs.com.au
        </div>
        <br/>
        <div className="title-medium">
          Additional Information for Australian Customers
        </div>
        <br />
        <div className="medium-text">
          Under the Privacy Act 1988, Australian customers have the right to
          access and correct their personal information. They also have the
          right to request that their personal information be deleted. Customers
          can exercise these rights by contacting Luxo Cabs at the email address
          above.
          <br />
          <br />
          Luxo Cabs is also required to comply with the Australian Privacy
          Principles (APPs). The APPs set out the standards that businesses must
          comply with when handling personal information. More information about
          the APPs can be found on the website of the Office of the Australian
          Information Commissioner (OAIC).
        </div>
        <br/>
        <div className="title-medium">Complaints</div>
        <br />
        <div className="medium-text">
          If you have a complaint about how Luxo Cabs has handled your personal
          information, you can contact us at the email address above. We will
          investigate your complaint and respond to you within a reasonable
          time.
          <br />
          <br />
          If you are not satisfied with our response, you can complain to the
          OAIC. More information about how to complain to the OAIC can be found
          on the OAIC website.
        </div>
      </Container>
    </div>
  );
}
