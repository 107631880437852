import { OPEN_MODAL, CLOSE_MODAL, UPDATE_MODAL } from "./ModalConstants.js";

export const openModal = (modalType, modalProps) => {
  return {
    type: OPEN_MODAL,
    payload: {
      modalType,
      modalProps
    }
  };
};

export const closeModal = (modalType, modalProps) => {
  return {
    type: CLOSE_MODAL
  };
};

export const updateModal = (modalType, modalProps) => {
  return {
    type: UPDATE_MODAL,
    payload: {
      modalProps
    }
  };
};
