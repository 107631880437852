import React, { Component } from "react";
import { RIDE_TYPE } from "../../Utils/enums";
import { FLEET_DATA } from "../../Utils/Data/FleetData";
import { Helmet } from "react-helmet";
import { Button, Grid, Image, List, Segment } from "semantic-ui-react";
import { CAR_TYPES } from "../../Utils/Data/carsData";
import { HIGHLIGHT_COLOR, WHITE_COLOR } from "../../Utils/Constants";
import { ASSETS } from "../../Utils/Assets";

export default class FleetPage extends Component {
  state = {
    type: RIDE_TYPE.SEDAN,
    fleets: FLEET_DATA.filter((e) => e.name == RIDE_TYPE.SEDAN),
  };

  setFleet = (type) => {
    this.setState({
      type: type,
      fleets: FLEET_DATA.filter((e) => e.name == type),
    });
  };

  render() {
    const { type, fleets } = this.state;
    return (
      <div>
        <Helmet>
          <title>Our Fleet - Introducing Our Exquisite Fleet of Luxury Taxis</title>
          <meta
            name="description"
            content="At Luxo Cabs, we take pride in offering a premium and unforgettable transportation experience. Our fleet of luxury vehicles, featuring prestigious brands like Mercedes Benz, BMW, and Holden, redefines the art of travel. With a perfect blend of sophistication, comfort, and style, we ensure that every journey with us becomes an exceptional memory."
          />
        </Helmet>
        <Grid padded stackable>
          <Grid.Column computer={4}>
            <Segment>
              <Button icon="filter" basic label="Filter" />
              <br />
              <br />
              <CabTypeSection cabType={type} onTypeSelected={this.setFleet} />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={12}>
            {fleets.map((fleet) => {
              return <FleetItem data={fleet} />;
            })}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function FleetItem(props) {
  const { data } = props;
  return (
    <Segment>
      <div style={{ textAlign: "left" }}>
        <Grid padded>
          <Grid.Column width={4} verticalAlign="middle">
            <Image centered src={data["icon"]} size="medium" />
          </Grid.Column>
          <Grid.Column width={12} textAlign="left" verticalAlign="middle">
            <p className="title-large" style={{ fontWeight: "bold", marginBottom: "0px" }}>
              {data["title"]}
            </p>
            <div className="medium-text">{data["description"]}</div>
            <List horizontal celled size="tiny">
              {data["features"].map((feature) => {
                return (
                  <List.Item
                    key={`${data.name}_${feature.title}`}
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "4px",
                      marginRight: "0.5rem",
                    }}
                  >
                    <Image src={feature.icon} />
                    <List.Content>
                      <List.Header>{feature.title}</List.Header>
                    </List.Content>
                  </List.Item>
                );
              })}
            </List>
            {/* <List size="small" bulleted >
            {data["includes"].map((includeItem) => {
              return (
                <List.Item key={`${includeItem}`}>
                  <List.Content>
                    <List.Header>{includeItem}</List.Header>
                  </List.Content>
                </List.Item>
              );
            })}
          </List> */}
          </Grid.Column>
        </Grid>
      </div>
    </Segment>
  );
}

function CabTypeSection(props) {
  const { cabType, onTypeSelected } = props;
  return (
    <div style={{ textAlign: "left" }}>
      {CAR_TYPES.map((e) => {
        let selected = cabType === e.name;
        return (
          <div
            key={e.name}
            style={{
              border: "1px solid lightgrey",
              borderRadius: "1rem",
              background: selected ? HIGHLIGHT_COLOR : WHITE_COLOR,
              marginBottom: "1rem",
              cursor: "pointer",
            }}
            onClick={() => {
              onTypeSelected(e.name);
            }}
          >
            <Grid padded>
              <Grid.Column width={4}>
                <Image centered src={e.icon} size="medium" />
              </Grid.Column>
              <Grid.Column width={12} textAlign="left" verticalAlign="middle">
                <p className="title-medium" style={{ fontWeight: "bold", marginBottom: "0px" }}>
                  {e.title}
                </p>
              </Grid.Column>
            </Grid>
          </div>
        );
      })}
    </div>
  );
}
