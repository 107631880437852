import React, { Fragment } from "react";
import { Container, Grid, Image, List, Segment } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import { ASSETS } from "../../../Utils/Assets";
import { DesktopOnly, MobileOnly } from "../../Custom/Renderers";
import SeamlessTravel from "../../LandingPage/Sections/SeamlessTravel";
import TACApprovedIndicator from "../../Misc/TACApprovedIndicator";
import { isMobile } from "react-device-detect";

export default function ServiceDetails(props) {
  const { data } = props;

  function descriptionBox() {
    return (
      <Fragment>
        <div className="title-large" style={{ marginBottom: "1rem" }}>
          About the Service
        </div>
        <div className="large-text">{data["description"]}</div>
      </Fragment>
    );
  }

  function offerBox() {
    return (
      <Fragment>
        <div
          className="title-large"
          style={{ marginBottom: "1rem", textAlign: "left" }}
        >
          {data["descriptionHeader"] ?? "What We Offer"}
        </div>
        <br />
        <List className="large-text" as="ul" style={{ textAlign: "left" }}>
          {data["includes"].map((feature) => (
            <Fragment>
              <List.Item className="large-text" as="li">
                {feature}
              </List.Item>
              <div style={{ height: "1rem" }}></div>
            </Fragment>
          ))}
        </List>
      </Fragment>
    );
  }
  return (
    <div>
      <Helmet>
        <title>{data["name"]} - Luxo Cabs</title>
        <meta name="description" content={data["helmet"]} />
      </Helmet>
      <div style={{ position: "relative" }}>
        <Image src={data["banner"] ?? ASSETS.SERVICES_BANNER} fluid />
        <div className="dark-tint"></div>
        <Grid
          columns={1}
          style={{
            position: "absolute",
            left: "0px",
            top: "0px",
            zIndex: "9999",
            width: "100%",
            height: "100%",
          }}
        >
          <Grid.Column verticalAlign="middle" textAlign="center">
            <div className="heading-text" style={{ color: "white" }}>
              {data["name"]}
            </div>
          </Grid.Column>
        </Grid>
      </div>
      <Segment basic attached="bottom" inverted textAlign="center">
        <Image
          bordered
          src={ASSETS.TAC_APPROVED}
          size="tiny"
          centered
          style={{ border: "1px solid white" }}
        />
        <TACApprovedIndicator />
      </Segment>
      <Grid
        padded
        columns={2}
        style={{ padding: "5%" }}
        stackable
        verticalAlign="middle"
      >
        <Grid.Column>
          <MobileOnly>
            <Container
              textAlign="justified"
              style={{ padding: isMobile ? "0rem" : "2rem" }}
            >
              {descriptionBox()}
            </Container>
          </MobileOnly>
          <DesktopOnly>
            <Container
              textAlign="justified"
              style={{ padding: isMobile ? "0rem" : "2rem" }}
            >
              {descriptionBox()}
            </Container>
          </DesktopOnly>
        </Grid.Column>
        <Grid.Column>
          <Image centered src={data["promoImage"]} />
        </Grid.Column>
      </Grid>
      {/* <Grid centered stackable>
        {data["images"].map((image) => {
          return (
            <Grid.Column computer={4} mobile={16}>
              <Image
                bordered
                centered
                size="medium"
                src={image}
                style={{ padding: "2rem" }}
                rounded
              />
            </Grid.Column>
          );
        })}
      </Grid> */}
      <Segment
        basic
        attached="top"
        style={{ backgroundColor: "#F8F6F6", paddingBottom: "0px" }}
      >
        <Grid verticalAlign="middle" textAlign="center" stackable>
          <Grid.Column
            computer={10}
            mobile={8}
            tablet={8}
            textAlign="center"
            style={{ paddingBottom: "0px" }}
          >
            <MobileOnly>
              <Container textAlign="justified" style={{ padding: isMobile ? "0rem" :"2rem" }}>
                {offerBox()}
              </Container>
            </MobileOnly>
            <DesktopOnly>
              <Container
                textAlign="justified"
                style={{ padding: isMobile ? "0rem" : "5rem" }}
              >
                {offerBox()}
              </Container>
            </DesktopOnly>
          </Grid.Column>
          <Grid.Column
            computer={6}
            mobile={8}
            tablet={8}
            textAlign="right"
            style={{ padding: "0px" }}
            only="computer"
          >
            <Image size="huge" src={ASSETS.IMAGE_BMW} floated="right" />
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment
        inverted
        style={{ padding: "5%", borderRadius: "0px", marginBottom: "0px" }}
        attached="bottom"
      >
        <SeamlessTravel onlyFeatures={true} inverted={true} />
      </Segment>
      {data["otherElements"] && data["otherElements"].map((e) => e)}
    </div>
  );
}
