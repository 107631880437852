import { ASSETS } from "../Assets";
import { RIDE_TYPE } from "../enums";

export const FLEET_DATA = [
  {
    icon: ASSETS.FLEET_SEDAN_LEXUS,
    name: RIDE_TYPE.SEDAN,
    title: "Lexus ES",
    description:
      "Indulge in opulence as you ride through Australia in the Lexus ES—a sedan that defines luxury, exclusively offered by our cab service.",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "4 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "2 Suitcase",
      },
    ],
    includes: ["145 Kms included. After that $2/KM", "Free Cancellation within 30 min of booking", "Petrol Car"],
  },
  {
    icon: ASSETS.FLEET_SEDAN_MERCEDES,
    name: RIDE_TYPE.SEDAN,
    title: "Mercedes E",
    description:
      "Embark on a sophisticated journey with our cab service featuring the Mercedes E. Elevate your travel experience with unparalleled comfort and style.",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "4 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "2 Suitcase",
      },
    ],
    includes: ["145 Kms included. After that $2/KM", "Free Cancellation within 30 min of booking", "Petrol Car"],
  },

  {
    icon: ASSETS.FLEET_SEDAN_GENESIS,
    name: RIDE_TYPE.SEDAN,
    title: "Genesis",
    description:
      "Discover the epitome of elegance with the Genesis Sedan, now available in our premium cab fleet. Luxury travel redefined for the discerning passenger.",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "4 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "2 Suitcase",
      },
    ],
    includes: ["145 Kms included. After that $2/KM", "Free Cancellation within 30 min of booking", "Petrol Car"],
  },
  {
    icon: ASSETS.FLEET_SEDAN_SKODA,
    name: RIDE_TYPE.SEDAN,
    title: "Skoda",
    description:
      "Experience European finesse on every ride with our Skoda sedan. Enjoy a perfect blend of style, performance, and comfort as you navigate Australia's roads.",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "4 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "3 Suitcase",
      },
    ],
    includes: ["145 Kms included. After that $2/KM", "Free Cancellation within 30 min of booking", "Petrol Car"],
  },
  {
    icon: ASSETS.FLEET_SEDAN_BMW,
    name: RIDE_TYPE.SEDAN,
    title: "BMW",
    description:
      "Unleash the thrill of driving with our BMW sedan fleet. Precision engineering meets unmatched luxury for an unforgettable cab experience.",

    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "4 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "2 Suitcase",
      },
    ],
    includes: ["145 Kms included. After that $2/KM", "Free Cancellation within 30 min of booking", "Petrol Car"],
  },
  {
    icon: ASSETS.FLEET_SEDAN_CAPRICE,
    name: RIDE_TYPE.SEDAN,
    title: "Holden Caprice",
    description:
      "Cruise in comfort and grandeur with our Holden Caprice sedan. Exceptional spaciousness and premium features make every journey a lavish affair.",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "4 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "3 Suitcase",
      },
    ],
    includes: ["145 Kms included. After that $2/KM", "Free Cancellation within 30 min of booking", "Petrol Car"],
  },
  {
    icon: ASSETS.FLEET_SUV_LEXUS,
    name: RIDE_TYPE.SUV,
    title: "Lexus RX",
    description:
      "Embark on a journey of luxury and performance with the Lexus RX, an SUV that redefines opulence. Elevate your travel experience with our premium cab service in Australia.",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "4 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "4 Suitcase",
      },
    ],
    includes: ["145 Kms included. After that $2/KM", "Free Cancellation within 30 min of booking", "Petrol Car"],
  },
  {
    icon: ASSETS.FLEET_SUV_KLUGER,
    name: RIDE_TYPE.SUV,
    title: "Toyota Kluger",
    description:
      "Experience the perfect blend of comfort and versatility with the Toyota Kluger, now available in our SUV fleet. Unleash the spacious elegance of Kluger as you traverse Australia's landscapes.",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "6 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "5 Suitcase",
      },
    ],
    includes: ["145 Kms included. After that $2/KM", "Free Cancellation within 30 min of booking", "Petrol Car"],
  },
  {
    icon: ASSETS.FLEET_MAXI_TAXI,
    name: RIDE_TYPE.MAXO_TAXI,
    title: "Maxi Taxi",
    description:"Catering to groups and comfort, our cab service proudly features the Maxi Taxi. Enjoy spacious interiors and a smooth ride as you travel with ease.",
    features: [
      {
        icon: ASSETS.ICON_AIR_CONDITION,
        title: "AC",
      },
      {
        icon: ASSETS.ICON_SEAT,
        title: "10 Seats",
      },
      {
        icon: ASSETS.ICON_SUITCASE,
        title: "10 Suitcase",
      },
    ],
    includes: ["145 Kms included. After that $2/KM", "Free Cancellation within 30 min of booking", "Petrol Car"],
  },
];
