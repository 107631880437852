import {
  LOADING_SERVICE_ORDER_START,
  LOADING_SERVICE_ORDER_END,
  SET_SERVICE_ORDER_DATA,
  SET_SERVICE_ORDER_ITEMS,
  SET_DOORSTEP_ORDER_DATA,
  SET_ALL_SERVICE_ORDERS,
  SET_ALL_DOORSTEP_ORDERS,
  SET_ALL_LOCATIONS,
  CLEAR_SERVICE_ORDER,
} from "./serviceOrderConstants";

const initialState = {
  userServiceOrders: {},
  userDoorstepOrders: {},
  serviceOrderData: null,
  doorstepOrderData: null,
  serviceOrderItems: null,
  loading: false,
  serviceOrderType: null,
  locations: null,
};

const serviceOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_SERVICE_ORDER_START:
      return { ...state, loading: true };
    case SET_SERVICE_ORDER_ITEMS:
      return {
        ...state,
        serviceOrderItems: action.serviceOrderItems,
        loading: false,
        serviceOrderType: action.serviceOrderType,
      };
    case SET_SERVICE_ORDER_DATA:
      return {
        ...state,
        serviceOrderData: action.serviceOrderData,
        loading: false,
      };
    case SET_DOORSTEP_ORDER_DATA:
      return {
        ...state,
        doorstepOrderData: action.doorstepOrderData,
        loading: false,
      };
    case SET_ALL_SERVICE_ORDERS:
      return {
        ...state,
        userServiceOrders: action.serviceOrders,
        loading: false,
      };
    case SET_ALL_DOORSTEP_ORDERS:
      return {
        ...state,
        userDoorstepOrders: action.doorstepOrders,
        loading: false,
      };
    case SET_ALL_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
        loading: false,
      };
    case LOADING_SERVICE_ORDER_END:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_SERVICE_ORDER:
      return initialState;
    default:
      return state;
  }
};

export default serviceOrderReducer;
