import React, { useEffect } from "react";
import { ASSETS } from "../../Utils/Assets";
import { Container, Grid, Image, List, Segment } from "semantic-ui-react";
import { Helmet } from "react-helmet";
import SeamlessTravel from "../LandingPage/Sections/SeamlessTravel";
import { isMobile } from "react-device-detect";

const LuxoCabsTACApproved = () => {
  useEffect(() => {
    // Animation or JS-based effects can be added here if needed
  }, []);

  return (
    <div>
      <Helmet>
        <title>TAC Approved - Luxo Cabs</title>
        <meta
          name="description"
          content={
            "Get ready to experience a new level of taxi service in Melbourne with Luxo Cabs, now proudly Transport Accidents Commission (TAC), Melbourne approved!"
          }
        />
      </Helmet>
      <div style={{ position: "relative" }}>
        <Image src={ASSETS.BANNER_TAC_APPROVED} fluid />
        <div className="dark-tint"></div>
        <Grid
          columns={1}
          style={{
            position: "absolute",
            left: "0px",
            top: "0px",
            zIndex: "9999",
            width: "100%",
            height: "100%",
          }}
        >
          <Grid.Column verticalAlign="middle" textAlign="center">
            <Image
              src={ASSETS.TAC_APPROVED}
              size={isMobile ? "small" : "medium"}
              centered
            />
          </Grid.Column>
        </Grid>
      </div>
      <Segment
        basic
        padded
        secondary
        style={{ textAlign: "left" }}
        attached="bottom"
      >
        <Container>
          <div className="title-large" style={{ textAlign: "center" }}>
            Your TAC Approved Taxi Service in Melbourne
          </div>
          <br />
          <div className="large-text">
            Get ready to experience a new level of taxi service in Melbourne
            with Luxo Cabs, now proudly Transport Accidents Commission (TAC),
            Melbourne approved! This prestigious accreditation signifies our
            unwavering commitment to safety, reliability, and exceptional
            customer care.
          </div>
          <div className="large-text">
            Whether you're a busy professional, a family on the go, or a
            Worksafe claimant requiring safe and accredited transport, Luxo Cabs
            is your trusted taxi partner on the road.
          </div>
          <br />
          <div className="title-large">Benefits of TAC Approval</div>
          <br />
          <div className="large-text">
            TAC helps you to resume your work and life after you have been in a
            transport accident and covers transportation costs related to
            transport for visiting the healthcare professional or other members
            of the treating team or when your solicitor arranges for you to
            attend an independent medical examination, travelling to work after
            an accident for no more than 24 weeks, travelling to pre-vocational
            or vocational training programs, and travelling to an authorised
            supported employment service.
          </div>
          <br />
          <a href="https://www.tac.vic.gov.au" className="tacLink title-medium">
            Learn more about TAC
          </a>
          <br />
          <br />
          <div className="title-large">
            What does TAC Approval mean for you?
          </div>
          <br />
          <div className="large-text">
            If you are a Worksafe claimant or TAC-funded client, you can now
            book Luxo Cabs for taxi services the charges of which are directly
            paid by TAC for eligible members.
          </div>
          <List bulleted relaxed>
            <List.Item className="large-text">
              Rigorous safety standards
            </List.Item>
            <List.Item className="large-text">Reliability</List.Item>
            <List.Item className="large-text">Financial Stability</List.Item>
            <List.Item className="large-text">
              Transparent and Ethical Working
            </List.Item>
          </List>
        </Container>
      </Segment>
      <SeamlessTravel />
    </div>
  );
};

export default LuxoCabsTACApproved;
