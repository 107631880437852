import { BOOKING_STATUS, BOOKING_TYPE, RIDE_TYPE } from "./enums";

export const getBookingTypeString = (bookingType) => {
  switch (bookingType) {
    case BOOKING_TYPE.ONE_WAY:
      return "One Way";
    case BOOKING_TYPE.ROUND_TRIP:
      return "Round Trip";
    case BOOKING_TYPE.AIRPORT_RIDE:
      return "Airport Ride";
  }
};

export const getBookingStatusString = (status) => {
  switch (status) {
    case BOOKING_STATUS.processing:
      return "Processing";
    case BOOKING_STATUS.accepted:
      return "Accepted";
    case BOOKING_STATUS.rejected:
      return "Rejected";
    case BOOKING_STATUS.cancelled:
      return "Rejected";
    case BOOKING_STATUS.rejected:
      return "Cancelled";
    case BOOKING_STATUS.paid:
      return "Paid";
  }
};

export const getRideTypeString = (rideType) => {
  switch (rideType) {
    case RIDE_TYPE.MAXO_TAXI:
      return "Maxi Taxi";
    case RIDE_TYPE.SUV:
      return "SUV";
    case RIDE_TYPE.SEDAN:
      return "Sedan";
  }
};
