import {
  LOADING_FORM_APPLICATIONS_FINISH,
  LOADING_FORM_APPLICATIONS_START,
  SET_FORM_APPLICATIONS,
  CHANGE_FAMILY_DATA,
  SET_CURRENT_FORM
} from "./formsConstants";

const initialState = {
  formApplications: {},
  currentForm: null,
  loading: false
};

const formsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_FORM_APPLICATIONS_START:
      return { ...state, loading: true };
    case SET_FORM_APPLICATIONS:
      return { ...state, formApplications: action.formApplications, loading: false };
    case LOADING_FORM_APPLICATIONS_FINISH:
      return {
        ...state,
        loading: false
      };
    case CHANGE_FAMILY_DATA:
      let currentForm = state.currentForm;
      currentForm["familyMembersData"] = action.familyData;
      return Object.assign(state, { currentForm: currentForm });

    case SET_CURRENT_FORM:

      return Object.assign(state, { currentForm: action.formData })
    default:
      return state;
  }
};

export default formsReducer;
