import React, { Component, Fragment } from "react";
import {
  Form,
  Grid,
  Segment,
  Header,
  Image,
  List,
  Dropdown,
  Message,
  Icon,
} from "semantic-ui-react";
import axios from "axios";
import {
  BOOKING_TYPE_DROPDOWN_OPTIONS,
  CAB_SERVICE_CHOICE,
  EMPTY_BOOKING_DATA,
  EMPTY_TRIP,
  HIGHLIGHT_COLOR,
  SUPPORT_CONTACT,
  SUPPORT_CONTACT_TEXT,
  WHITE_COLOR,
} from "../../Utils/Constants";
import { connect } from "react-redux";
import { updateBookingForm } from "../../Redux/Booking/bookingActions";
import SearchField from "./SearchField";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";
import { BOOKING_TYPE, RIDE_TYPE } from "../../Utils/enums";
import { CAR_TYPES } from "../../Utils/Data/carsData";
import { ASSETS } from "../../Utils/Assets";
import { AccentButton } from "../Custom/CustomButtons";
import { openModal } from "../../Redux/Modals/ModalActions";
import { MODAL_LOGIN_REGISTER } from "../Modals/Auth/LoginRegisterModal";
import { CREATE_BOOKING, DISTANCE_MATRIX } from "../../Utils/URLConstants";
import { MODAL_CUSTOM_MESSAGE } from "../Modals/MessageModal";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
const randomstring = require("randomstring");

const PAGES = {
  CAR_TYPE: "CAR_TYPE",
  PASSANGER_DETAILS: "PASSANGER_DETAILS",
  PRICING: "PRICING",
  FINALIZED: "FINALIZED",
};

const actions = {
  updateBookingForm,
  openModal,
};

const mapState = (state) => {
  return {
    bookings: state.bookings,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    authenticated: !state.firebase.auth.isEmpty,
  };
};

class BookingForm extends Component {
  warningStatus = false;
  resultMessage = "";

  loading = false;
  errorLoadingDistance = false;
  fareCalculated = false;
  fieldsValidated = false;

  currentPage = PAGES.CAR_TYPE;

  state = EMPTY_BOOKING_DATA.current;

  componentDidMount() {
    const { bookings } = this.props;
    this.setState(bookings.current);
    // this.calculateDistance(bookings.current);
  }

  componentWillUnmount() {
    this.warningStatus = false;
    this.resultMessage = "";

    this.loading = false;
    this.errorLoadingDistance = false;
    this.fieldsValidated = false;
  }

  totalChargeCalculator() {
    let travelCost = this.state.estimatedAmount;
    let totalPrice = travelCost + travelCost * 0.05;
    totalPrice = Math.ceil(totalPrice);
    return totalPrice;
  }

  calculateBetweenDays = (booking) => {
    if (booking.bookingType === BOOKING_TYPE.ROUND_TRIP) {
      let pickupDateSplit = booking.pickupDate.split("-");
      let dropOffDateSplit = booking.returnDate.split("-");
      return parseInt(dropOffDateSplit) - parseInt(pickupDateSplit);
    }
    return 0;
  };

  calculatePerUnitCost(vehicle) {
    var tripType = this.state.bookingType;
    switch (vehicle) {
      case RIDE_TYPE.SEDAN:
        return tripType === BOOKING_TYPE.ONE_WAY ? 12 : 9;
      case RIDE_TYPE.SUV:
        return tripType === BOOKING_TYPE.ONE_WAY ? 14 : 12;
      case RIDE_TYPE.MAXO_TAXI:
        return tripType === BOOKING_TYPE.ONE_WAY ? 13 : 11;
      default:
        return 10;
    }
  }

  calculateBookingCost(vehicle) {
    let perUnitCost = this.calculatePerUnitCost(vehicle);
    let travelCost = this.state.travelDistance * perUnitCost;
    let daysBetween = this.state.betweenDays;
    if (daysBetween > 1) {
      travelCost = travelCost + daysBetween * 100;
    }

    if (this.state.bookingType === BOOKING_TYPE.ROUND_TRIP) {
      travelCost = travelCost * 2;
    }
    return travelCost;
  }

  updateServiceType = (serviceType) => () => {
    let travelCost = this.calculateBookingCost(serviceType);
    this.setState({
      serviceType: serviceType,
      travelCost: travelCost,
    });
  };

  calculateDistance = async () => {
    const { auth } = this.props;
    const uid = auth.uid;
    const booking = this.state;
    let pickupPosition = booking.trip.start.position;
    let dropOffPosition = booking.trip.end.position;
    if (pickupPosition != null && dropOffPosition != null) {
      this.loading = true;
      this.errorLoadingDistance = false;
      this.fareCalculated = false;
      this.setState();

      const response = await axios.get(
        DISTANCE_MATRIX +
          "?" +
          new URLSearchParams({
            start: `${pickupPosition.lat},${pickupPosition.lon}`,
            end: `${dropOffPosition.lat},${dropOffPosition.lon}`,
            rideType: booking.serviceType,
            bookingType: booking.bookingType,
            token: uid,
          })
      );
      const responseData = response.data;

      if (responseData["status"] == 0) {
        const routedata = responseData["data"];
        const travelCost = routedata["price"];
        const travelDistance = routedata["distance"];
        // let routeLength = routedata["distance"]["value"];
        // let travelDistance = Math.ceil(routeLength / 1000);
        // let perUnitCost = this.calculatePerUnitCost(booking.serviceType);
        let daysBetween = this.calculateBetweenDays(booking);
        // let travelCost = travelDistance * perUnitCost;
        // if (daysBetween > 1) {
        //   travelCost = travelCost + daysBetween * 100;
        // }

        // if (booking.bookingType === BOOKING_TYPE.ROUND_TRIP) {
        //   travelCost = travelCost * 2;
        // }

        this.setState({
          travelDistance: travelDistance,
          betweenDays: daysBetween,
          estimatedAmount: travelCost,
        });
      }
      this.loading = false;
      this.fareCalculated = true;
      this.errorLoadingDistance = false;
    } else {
      this.loading = false;
      this.errorLoadingDistance = true;
    }
    this.setState({});
  };

  isRoundTrip = () => {
    return this.state.bookingType === BOOKING_TYPE.ROUND_TRIP;
  };

  isReturnEnabled = () => {
    if (this.state.pickupTime === "" || this.state.pickupDate === "") {
      return false;
    }
    return true;
  };

  onPickupSuburbChange = (value) => {
    let newTrip = EMPTY_TRIP;
    newTrip.start = value;
    this.setState({
      trip: newTrip,
    });
  };

  onDropOffSuburbChange = (value) => {
    this.setState((prevState) => {
      let prevTrip = prevState.trip;
      prevTrip.end = value;
      return {
        trip: prevTrip,
      };
    });
  };

  handleTimeChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleDateChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleFieldChange = (e) => {
    this.warningStatus = false;
    this.resultMessage = "";
    e.preventDefault();
    let fieldName = e.target.name;
    let value = e.target.value;
    this.setState((prevState) => {
      return {
        [fieldName]: value,
      };
    });
  };

  handlePassangerFieldChange = (e) => {
    this.warningStatus = false;
    this.resultMessage = "";
    e.preventDefault();
    let fieldName = e.target.name;
    let value = e.target.value;

    this.setState((prevState) => {
      let prevPassangerInfo = prevState.passangers.primary;
      prevPassangerInfo[fieldName] = value;
      return {
        passangers: {
          primary: prevPassangerInfo,
        },
      };
    });
  };

  handleBookingTypeChange = (e, { name, value }) => {
    this.warningStatus = false;
    this.resultMessage = "";
    let newValue;
    if (value === BOOKING_TYPE.ONE_WAY) {
      newValue = "nill";
    } else {
      newValue = "";
    }
    this.setState({
      [name]: value,
      returnDate: newValue,
      returnTime: newValue,
    });
  };

  handleFormSubmit = () => {
    const validation = this.checkFormFields();
    if (validation) {
      this.fieldsValidated = true;
      this.currentPage = PAGES.PRICING;
      this.calculateDistance();
      this.setState({});
      // bookingDone = true;
      // this.warningStatus = false;
    } else {
      this.warningStatus = true;
      this.fieldsValidated = false;
    }
    this.setState({});
  };

  handleSubmitBooking = async () => {
    if (this.checkFormFields()) {
      this.loading = true;
      this.setState({});

      try {
        const { openModal, auth } = this.props;
        const { uid } = auth;
        const booking = this.state;
        const response = await axios.post(CREATE_BOOKING, {
          booking: JSON.stringify(booking),
          token: uid,
        });
        const responseData = response.data;
        if (responseData["status"] === 0) {
          this.currentPage = PAGES.FINALIZED;
          this.setState();
        } else {
          openModal(MODAL_CUSTOM_MESSAGE, {
            heading: "Error",
            message: "Problem Requesting Booking",
          });
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
      this.setState({});
    } else {
      this.warningStatus = true;
      this.fieldsValidated = false;
      this.setState({});
    }
  };

  showWarning = (name) => {
    let fieldValue = this.state[name];
    if (this.warningStatus && fieldValue.trim() === "") {
      return true;
    }
    return false;
  };

  showWarningForPassangerForm = (name) => {
    let fieldValue = this.state.passangers.primary[name];
    if (this.warningStatus && fieldValue.trim() === "") {
      return true;
    }
    return false;
  };

  showWarningForTripStart = (name) => {
    let fieldValue = this.state.trip.start[name];
    if (this.warningStatus && fieldValue.trim() === "") {
      return true;
    }
    return false;
  };

  showWarningForTripEnd = (name) => {
    let fieldValue = this.state.trip.end[name];
    if (this.warningStatus && fieldValue.trim() === "") {
      return true;
    }
    return false;
  };

  checkFormFields = () => {
    var REQUIRED_STRINGS = [
      "pickupTime",
      "pickupDate",
      "bookingType",
      "status",
      "returnDate",
      "returnTime",
      "serviceType",
    ];
    var CITYFIELDS = ["trip"];
    var INTFIELDS = []; //["travelDistance", "travelCost"];
    let PASSANGERFIELDS = ["passangers"];

    let valid = true;
    const stateKeys = Object.keys(this.state);
    if (stateKeys.length > 0) {
      stateKeys.forEach((key) => {
        if (REQUIRED_STRINGS.includes(key)) {
          let value = this.state[key];
          if (value.trim() === "" && key !== "customerMessage") {
            valid = false;
          }
        } else if (CITYFIELDS.includes(key)) {
          let value = this.state[key];
          if (value != null) {
            if (
              value.start.name.trim() === "" ||
              value.end.name.trim() === ""
            ) {
              valid = false;
            }
          } else {
            valid = false;
          }
        } else if (PASSANGERFIELDS.includes(key)) {
          let passFields = Object.keys(this.state.passangers.primary);
          for (let x = 0; x < passFields.length; x++) {
            let field = passFields[x];
            let value = this.state.passangers.primary[field];
            if (value != null) {
              if (value.trim() === "") {
                valid = false;
              }
            } else {
              valid = false;
            }
          }
        } else if (INTFIELDS.includes(key)) {
          let value = this.state[key];
          if (value === 0) {
            valid = false;
          }
        }
      });
    }
    return valid;
  };

  autofillPassangerDetails() {
    const booking = this.state;
    const { profile, auth } = this.props;
    const { fullName, phoneNumber, email } = auth;
    const {
      firstName,
      lastName,
      phoneNumber: userPhone,
      email: userEmail,
    } = profile;
    if (booking.passangers.primary.fullName.trim().length === 0) {
      this.setState({
        passangers: {
          primary: {
            fullName: fullName ?? `${firstName} ${lastName}`,
            phoneNumber: phoneNumber ?? userPhone,
            email: email ?? userEmail,
          },
        },
      });
    }
  }

  render() {
    const { authenticated, openModal } = this.props;
    const {} = this.state;
    return (
      <div id="booking-form">
        <Segment
          basic
          // loading={this.sendingMessage || this.loadingDistance}
          // disabled={this.errorLoadingDistance}
          style={{ marginTop: "0px", padding: "0px" }}
        >
          {authenticated ? (
            <Fragment>
              {this.currentPage !== PAGES.FINALIZED && (
                <Segment inverted className="no-shadow-border" attached="top">
                  <Form className="dark-form" inverted>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <BarElement>
                          <div className="medium-text">Trip Type</div>

                          <Dropdown
                            className="dark-dropdown"
                            selection
                            fluid
                            placeholder="Trip Type"
                            name="bookingType"
                            onChange={this.handleBookingTypeChange}
                            value={this.state.bookingType}
                            options={BOOKING_TYPE_DROPDOWN_OPTIONS}
                          />
                        </BarElement>
                      </Form.Field>
                      <Form.Field error={this.showWarningForTripStart("name")}>
                        <BarElement>
                          <div className="medium-text">From</div>

                          <SearchField
                            name="start"
                            editable={true}
                            hint="Select Location"
                            value={this.state.trip.start.name}
                            onAddressChanged={this.onPickupSuburbChange}
                            inverted={true}
                            showIcon={false}
                          />
                        </BarElement>
                      </Form.Field>
                      <Form.Field error={this.showWarningForTripEnd("name")}>
                        <BarElement>
                          <div className="medium-text">To</div>

                          <SearchField
                            name="end"
                            editable={true}
                            hint="Select Location"
                            value={this.state.trip.end.name}
                            onAddressChanged={this.onDropOffSuburbChange}
                            inverted={true}
                            showIcon={false}
                          />
                        </BarElement>
                      </Form.Field>
                      <Form.Field error={this.showWarning("pickupDate")}>
                        <BarElement>
                          <div className="medium-text">Pick-Up Date</div>
                          <DateInput
                            hideMobileKeyboard
                            name="pickupDate"
                            placeholder="Select Date"
                            value={this.state.pickupDate}
                            minDate={new Date().getDate().toString()}
                            iconPosition="left"
                            icon={null}
                            onChange={this.handleDateChange}
                          />
                        </BarElement>
                      </Form.Field>
                      <Form.Field error={this.showWarning("pickupTime")}>
                        <BarElement>
                          <div className="medium-text">Pick-Up Time</div>
                          <TimeInput
                            hideMobileKeyboard
                            timeFormat="ampm"
                            name="pickupTime"
                            placeholder="Select Time"
                            value={this.state.pickupTime}
                            iconPosition="left"
                            icon={null}
                            onChange={this.handleTimeChange}
                          />
                        </BarElement>
                      </Form.Field>
                      {this.isRoundTrip() && (
                        <Fragment>
                          <Form.Field
                            disabled={!this.isReturnEnabled()}
                            error={this.showWarning("returnDate")}
                          >
                            <BarElement>
                              <div className="medium-text">Return Date</div>
                              <DateInput
                                hideMobileKeyboard
                                name="returnDate"
                                placeholder="Return Date"
                                minDate={this.state.pickupDate}
                                value={this.state.returnDate}
                                iconPosition="left"
                                icon={null}
                                onChange={this.handleDateChange}
                              />
                            </BarElement>
                          </Form.Field>
                          <Form.Field
                            disabled={!this.isReturnEnabled()}
                            error={this.showWarning("returnTime")}
                          >
                            <BarElement>
                              <div className="medium-text">Pick-Up Time</div>
                              <TimeInput
                                hideMobileKeyboard
                                timeFormat="ampm"
                                name="returnTime"
                                placeholder="Return Time"
                                value={this.state.returnTime}
                                iconPosition="left"
                                icon={null}
                                onChange={this.handleTimeChange}
                              />
                            </BarElement>
                          </Form.Field>
                        </Fragment>
                      )}
                    </Form.Group>
                  </Form>
                </Segment>
              )}
              <Segment secondary attached="bottom" className="lp-background">
                <Grid padded={!isMobile} centered>
                  <Grid.Column
                    computer={7}
                    mobile={16}
                    tablet={16}
                    verticalAlign="middle"
                    textAlign="left"
                  >
                    <Segment
                      loading={this.loading}
                      // placeholder={this.loading}
                      style={{
                        borderRadius: "1rem",
                        padding: isMobile ? "0.5rem" : "2rem",
                        width: "100%",
                        minHeight: "20vh",
                      }}
                    >
                      {this.currentPage == PAGES.CAR_TYPE && (
                        <CabTypeSection
                          updateServiceType={this.updateServiceType}
                          state={this.state}
                          onNextClicked={() => {
                            this.currentPage = PAGES.PASSANGER_DETAILS;
                            this.autofillPassangerDetails();
                            this.setState({});
                          }}
                        />
                      )}
                      {this.currentPage == PAGES.PASSANGER_DETAILS && (
                        <Fragment>
                          <AccentButton
                            labelPosition="left"
                            fluid
                            onClick={() => {
                              this.currentPage = PAGES.CAR_TYPE;
                              this.setState({});
                            }}
                            icon={<Icon name="arrow left" />}
                            text="Change Cab Type"
                            style={{ padding: "2rem 0rem" }}
                          />
                          <br />
                          <div className="title-medium">Passenger Details</div>
                          <br />
                          <Form
                            warning={this.warningStatus}
                            className="passenger-form"
                          >
                            <Form.Group widths="equal">
                              <Form.Field
                                error={this.showWarningForPassangerForm(
                                  "fullName"
                                )}
                              >
                                <div className="medium-text">Name</div>
                                <input
                                  placeholder="Full Name"
                                  type="text"
                                  value={this.state.passangers.primary.fullName}
                                  name="fullName"
                                  onChange={this.handlePassangerFieldChange}
                                />
                              </Form.Field>
                              <Form.Field
                                error={this.showWarningForPassangerForm(
                                  "phoneNumber"
                                )}
                              >
                                <div className="medium-text">Phone Number</div>
                                <input
                                  placeholder="Contact Number"
                                  type="text"
                                  name="phoneNumber"
                                  value={`${this.state.passangers.primary.phoneNumber}`}
                                  onChange={this.handlePassangerFieldChange}
                                />
                              </Form.Field>
                            </Form.Group>

                            <Form.Field
                              error={this.showWarningForPassangerForm("email")}
                            >
                              <div className="medium-text">Email Address</div>
                              <input
                                placeholder="Email Address"
                                type="text"
                                name="email"
                                value={this.state.passangers.primary.email}
                                onChange={this.handlePassangerFieldChange}
                              />
                            </Form.Field>

                            <Form.Field
                              error={this.showWarning("customerMessage")}
                            >
                              <div className="medium-text">
                                Message For Us (Optional)
                              </div>
                              <textarea
                                placeholder="Write your Message Here"
                                name="customerMessage"
                                value={this.state.customerMessage}
                                onChange={this.handleFieldChange}
                              />
                            </Form.Field>

                            <AccentButton
                              labelPosition="left"
                              fluid
                              onClick={() => {
                                this.handleFormSubmit();
                              }}
                              text="Proceed"
                              style={{ padding: "2rem 0rem" }}
                            />
                          </Form>
                        </Fragment>
                      )}
                      {this.currentPage == PAGES.PRICING && (
                        <div style={{ width: "100%" }}>
                          {this.fareCalculated && (
                            <div style={{ width: "100%" }}>
                              <AccentButton
                                labelPosition="left"
                                fluid
                                onClick={() => {
                                  this.currentPage = PAGES.PASSANGER_DETAILS;
                                  this.setState({});
                                }}
                                icon={<Icon name="arrow left" />}
                                text="Change Passanger Details"
                                style={{ padding: "2rem 0rem" }}
                              />
                              <br />
                              <Grid padded verticalAlign="middle">
                                <Grid.Column width={8} textAlign="left">
                                  <Header
                                    as={isMobile ? "h4" : "h4"}
                                    content="Estimated Amount"
                                    subheader="Inclusive of Taxes"
                                    style={{ fontWeight: "bold" }}
                                  />
                                </Grid.Column>
                                <Grid.Column
                                  width={8}
                                  textAlign="right"
                                  verticalAlign="middle"
                                >
                                  {this.state.serviceType ===
                                  RIDE_TYPE.MAXO_TAXI ? (
                                    <div className="medium-text">
                                      Please Call <br />
                                      <a href={`tel:${SUPPORT_CONTACT}`}>
                                        <Icon name="call" />{" "}
                                        {SUPPORT_CONTACT_TEXT}
                                      </a>
                                      <br />
                                      for Estimated Price
                                    </div>
                                  ) : (
                                    <div className="title-medium">
                                      ${this.state.estimatedAmount} - $
                                      {this.state.estimatedAmount + 20} AUD
                                    </div>
                                  )}
                                  {this.state.serviceType !==
                                    RIDE_TYPE.MAXO_TAXI && (
                                    <div className="small-text">
                                      Please Call{" "}
                                      <a
                                        href={`tel:${SUPPORT_CONTACT}`}
                                        style={{ fontWeight: "bold" }}
                                      >
                                        <Icon name="call" />
                                        {SUPPORT_CONTACT_TEXT}
                                      </a>{" "}
                                      <br />
                                      for Fixed Price
                                    </div>
                                  )}
                                </Grid.Column>
                              </Grid>
                              <Message
                                size="tiny"
                                positive
                                icon="info circle"
                                content="This is an Estimated Fare. Once your booking is Finalized, We'll inform you via Text Message and Email on your Registered Contact Information."
                              />
                              <AccentButton
                                labelPosition="left"
                                fluid
                                onClick={() => {
                                  this.handleSubmitBooking();
                                  // Request Booking
                                }}
                                text="Request Booking"
                                style={{ padding: "2rem 0rem" }}
                              />
                              <Message
                                size="tiny"
                                content={
                                  <div>
                                    By Submitting this Booking Request you agree
                                    to LuxoCab's{" "}
                                    <a
                                      href="/terms-and-conditions"
                                      target="_blank"
                                    >
                                      Terms of Use{" "}
                                    </a>
                                    &{" "}
                                    <a href="/privacy-policy" target="_blank">
                                      Privacy Policy
                                    </a>
                                    .
                                  </div>
                                }
                              />
                            </div>
                          )}
                          {this.errorLoadingDistance && (
                            <Message
                              size="tiny"
                              negative
                              icon="info circle"
                              content="Problem Fetching Fare"
                            />
                          )}
                        </div>
                      )}

                      {this.currentPage == PAGES.FINALIZED && (
                        <div>
                          <Grid centered>
                            <Grid.Column
                              textAlign="center"
                              verticalAlign="middle"
                            >
                              <Icon
                                name="check circle"
                                color="green"
                                size="huge"
                              />
                              <br />
                              <br />
                              <div className="title-medium">
                                Booking Has been Requested
                              </div>
                              <br />
                              <div className="medium-text">
                                We'll get back to you soon with a Confirmation
                                Text and Email and a Final Price for your
                                Booking. You can pay the driver directly or via
                                'Pay Now' button in your Bookings.
                              </div>
                              <br />
                              <Link to={"/account/orders"}>
                                <AccentButton
                                  labelPosition="left"
                                  fluid
                                  text="Your Bookings"
                                  style={{ padding: "2rem 0rem" }}
                                />
                              </Link>
                            </Grid.Column>
                          </Grid>
                        </div>
                      )}
                    </Segment>
                  </Grid.Column>
                </Grid>
              </Segment>
            </Fragment>
          ) : (
            <Grid columns={1} centered>
              <Grid.Column verticalAlign="middle">
                <Segment placeholder style={{ height: "70vh" }}>
                  <Header>
                    <Header.Content>Please Login to Continue</Header.Content>
                  </Header>
                  <AccentButton
                    labelPosition="left"
                    fluid
                    onClick={() => {
                      openModal(MODAL_LOGIN_REGISTER, { forLogin: true });
                    }}
                    text="Log In / Sign Up"
                    style={{ padding: "2rem 0rem" }}
                  />
                </Segment>
              </Grid.Column>
            </Grid>
          )}
        </Segment>
      </div>
    );
  }
}

export default connect(mapState, actions)(BookingForm);

function CabTypeSection(props) {
  const { updateServiceType, state, onNextClicked } = props;
  return (
    <div style={{ textAlign: "left" }}>
      {CAR_TYPES.map((e) => {
        let selected = state.serviceType === e.name;
        return (
          <div
            key={e.name}
            style={{
              border: "1px solid lightgrey",
              borderRadius: "1rem",
              background: selected ? HIGHLIGHT_COLOR : WHITE_COLOR,
              marginBottom: "1rem",
              cursor: "pointer",
            }}
            onClick={updateServiceType(e.name)}
          >
            <Grid padded>
              <Grid.Column width={4}>
                <Image centered src={e.icon} size="small" />
              </Grid.Column>
              <Grid.Column width={12} textAlign="left" verticalAlign="middle">
                <p
                  className="title-large"
                  style={{ fontWeight: "bold", marginBottom: "0px" }}
                >
                  {e.title}
                </p>
                <List horizontal celled size="tiny">
                  {e.features.map((feature) => {
                    return (
                      <List.Item
                        key={`${e.name}_${feature.title}`}
                        style={{
                          border: "1px solid lightgrey",
                          borderRadius: "4px",
                          marginRight: "0.5rem",
                        }}
                      >
                        <Image src={feature.icon} />
                        <List.Content>
                          <List.Header>{feature.title}</List.Header>
                        </List.Content>
                      </List.Item>
                    );
                  })}
                </List>
              </Grid.Column>
            </Grid>
          </div>
        );
      })}

      <Message
        size="tiny"
        positive
        icon="check"
        content="All drivers undergo police verification, possess valid licenses, and undergo regular audits, ensuring 100% compliance."
      />

      <AccentButton
        labelPosition="left"
        fluid
        onClick={onNextClicked}
        text="Next"
        style={{ padding: "2rem 0rem" }}
      />
    </div>
  );
}

function BarElement(props) {
  const { children } = props;
  return (
    <div
      style={{
        border: "1px solid #FFFFFF44",
        padding: "0.5rem",
        borderRadius: "0.5rem",
      }}
    >
      {children}
    </div>
  );
}
