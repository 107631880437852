import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Segment, Image, Grid, Container } from "semantic-ui-react";
import { DesktopOnly, MobileOnly } from "../Custom/Renderers";
import { ASSETS } from "../../Utils/Assets";
import SeamlessTravel from "../LandingPage/Sections/SeamlessTravel";
import TACApprovedIndicator from "../Misc/TACApprovedIndicator";

export default function AboutUsPage() {
  function descriptionBox() {
    return (
      <Fragment>
        <div className="title-large" style={{ marginBottom: "1rem" }}>
          Luxury At Your Doorstep
        </div>
        <div className="large-text">
          We are a taxi company based in Melbourne Australia. We offer reliable
          transportation services for both locals, tourists and businesses. With
          a fleet of well maintained vehicles and experienced drivers, we ensure
          safe and comfortable rides. Whether you need a ride to the airport, a
          city tour or just a convenient way to get around, we have got you
          covered. The commitment to punctuality and customer satisfaction sets
          us apart from other taxi businesses. Give us a try and experience
          hassle free travel in Melbourne.
        </div>
        <br />
        <div className="title-large" style={{ marginBottom: "1rem" }}>
          History
        </div>
        <div className="large-text">
          Luxo Cabs was founded in 2012 with a vision of providing convenient
          and reliable taxi services to businesses, community and executives.
          Luxo Cabs started with 2 cars which eventually increased to 20. Luxo
          Cabs quickly gained popularity due to its sincere drivers, cleanest
          taxis and fast service. Over the years, the company worked on
          different aspects ranging from customer satisfaction to safety of the
          passengers. Luxocabs ensures proper screening of the drivers and
          various training programs are held from time to time. Booking the cabs
          has been made easier over the years.
        </div>
      </Fragment>
    );
  }

  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <Helmet>
        <title>About - Luxo Cabs</title>
        <meta
          name="description"
          content="Luxo Cabs is a company based in Melbourne Australia that offers reliable transportation services for both locals and tourists."
        />
      </Helmet>
      <div style={{ position: "relative" }}>
        <Image src={ASSETS.BANNER_FLEET} fluid />
        <div className="dark-tint"></div>
        <Grid
          columns={1}
          style={{
            position: "absolute",
            left: "0px",
            top: "0px",
            zIndex: "9999",
            width: "100%",
            height: "100%",
          }}
        >
          <Grid.Column verticalAlign="middle" textAlign="center">
            <div className="heading-text" style={{ color: "white" }}>
              About Luxo Cabs
            </div>
          </Grid.Column>
        </Grid>
      </div>
      <Segment basic attached="bottom" inverted textAlign="center">
        <Image
          bordered
          src={ASSETS.TAC_APPROVED}
          size="tiny"
          centered
          style={{ border: "1px solid white" }}
        />
        <TACApprovedIndicator />
      </Segment>
      <Grid
        padded
        columns={2}
        style={{ padding: "5%" }}
        stackable
        verticalAlign="middle"
      >
        <Grid.Column>
          <MobileOnly>
            <Container textAlign="justified" style={{ padding: "2rem" }}>
              {descriptionBox()}
            </Container>
          </MobileOnly>
          <DesktopOnly>
            <Container textAlign="justified" style={{ padding: "5rem" }}>
              {descriptionBox()}
            </Container>
          </DesktopOnly>
        </Grid.Column>
        <Grid.Column>
          <Image centered src={ASSETS.IMAGE_BMW_SUV} />
        </Grid.Column>
      </Grid>

      <Segment
        secondary
        style={{ padding: "5%", borderRadius: "0px " }}
        attached="bottom"
      >
        <SeamlessTravel onlyFeatures={true} />
      </Segment>
    </div>
  );
}
