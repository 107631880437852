import React, { Component, Fragment } from "react";
import { Form, Button, Item, Segment } from "semantic-ui-react";
import { connect } from "react-redux";
import {
  validateGenericField,
  validateContactField,
  validateEmail,
} from "../../../Utils/FieldValidation";
import { updateAuth } from "../../../Redux/Auth/authActions";
import {
  closeModal,
  openModal,
  updateModal,
} from "../../../Redux/Modals/ModalActions";
import { AccentButton } from "../../Custom/CustomButtons";
import PhoneInput from "react-phone-input-2";
import firebase from "../../../Firebase/initializeFirebase";
import OTPInput from "react-otp-input";
import axios from "axios";
import { USER_EXISTS } from "../../../Utils/URLConstants";
import { MODAL_LOGIN_REGISTER } from "./LoginRegisterModal";
import withRouter from "../../../Utils/router/router_hocs";
import { updateUserProfile } from "../../../Redux/Users/userActions";
import { ASSETS } from "../../../Utils/Assets";

const actions = {
  openModal,
  updateModal,
  updateAuth,
  closeModal,
  updateUserProfile,
};

const noErrors = {
  nameError: null,
  emailError: null,
  numberError: null,
  signupError: null,
  capchaError: null,
};

const initialState = {
  loading: false,
  loadingCapcha: false,
  fullName: "",
  email: "",
  contactNumber: "",
  confirmationResult: null,
  otp: "",
  capchaSolved: false,
  errors: noErrors,
};

const mapState = (state) => {
  return {
    modalProps: state.modal.modalProps,
    auth: state.auth,
  };
};

class LoginRegisterForm extends Component {
  accountAlreadyExists = false;
  state = initialState;

  componentDidMount() {
    this.setState(initialState);
    this.props.updateAuth({ error: null, signupError: null, loading: false });
  }

  componentWillUnmount() {
    // if (window.recaptchaVerifier) {

    // }
    window.recaptchaVerifier = null;
    window.confirmationResult = null;
  }

  handleValueChange = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      errors: this.noErrors(),
    });
  };

  noErrors = () => {
    return {
      nameError: null,
      emailError: null,
      numberError: null,
      signupError: null,
      capchaError: null,
    };
  };

  confirmOTP = async () => {
    try {
      const { closeModal, modalProps, navigate, updateUserProfile } =
        this.props;
      const { forBooking = false } = modalProps;
      const { email, fullName, otp } = this.state;
      if (otp.length === 6) {
        if (this.state.capchaSolved) {
          this.setState({
            loading: true,
            errors: this.noErrors(),
          });

          try {
            const result = await window.confirmationResult.confirm(otp);
            if (!result.user) {
              this.setState({
                loading: false,
                errors: Object.assign(noErrors, {
                  signupError: "Problem Fetching User",
                }),
              });
            } else {
              if (!this.accountAlreadyExists) {
                const fullNameSplit = fullName.split(" ");
                const firstName = fullNameSplit[0];
                const lastName =
                  fullNameSplit.length > 0 ? fullNameSplit[1] : "";
                updateUserProfile({
                  firstName: firstName,
                  lastName: lastName,
                  email: email,
                  phoneNumber: result.user.phoneNumber,
                  profilePic: ASSETS.STOCK_PROFILE_PIC,
                });
              }
              closeModal();
              if (forBooking) {
                navigate("/booking");
              }
            }
          } catch (e) {
            this.setState({
              loading: false,
              errors: Object.assign(noErrors, {
                signupError:
                  e.code == "auth/invalid-verification-code"
                    ? "Invalid Code, Please try Again"
                    : "Problem Completing Request",
              }),
            });
          }
        } else {
          this.setState({
            errors: Object.assign(noErrors, {
              signupError: "Please solve the Capcha",
            }),
          });
        }
      } else {
        this.setState({
          errors: Object.assign(noErrors, {
            signupError: "Please Enter Valid OTP",
          }),
        });
      }
    } catch (e) {
      this.setState({
        loading: false,
        errors: Object.assign(noErrors, {
          signupError: "Problem Logging the User In",
        }),
      });
    }
  };

  handleFormSubmit = async () => {
    window.confirmationResult = null;
    const { modalProps, updateModal } = this.props;
    const { forLogin = false, forBooking = false } = modalProps;
    this.setState({
      loadingCapcha: true,
      errors: this.noErrors(),
    });
    const { fullName, contactNumber, email } = this.state;
    const finalContactNumber = `+${contactNumber}`;

    const numError = !validateContactField(finalContactNumber);
    if (numError) {
      this.setState({
        errors: Object.assign(noErrors, {
          numberError: true,
        }),
        loading: false,
        loadingCapcha: false,
      });
      return;
    }

    const URL =
      USER_EXISTS +
      `?${new URLSearchParams({
        phone: finalContactNumber,
      }).toString()}`;
    const existResponse = await axios.get(URL);
    this.accountAlreadyExists = existResponse.data["status"] == 0;
    if (!this.accountAlreadyExists && forLogin) {
      this.setState({
        errors: Object.assign(noErrors, {
          signupError:
            "Your Account Does not Exist. Please register before Logging In",
        }),
        loading: false,
        loadingCapcha: false,
      });
      updateModal(MODAL_LOGIN_REGISTER, {
        forLogin: false,
        forBooking: forBooking,
      });
      return;
    }

    const nameError = !validateGenericField(fullName) && !forLogin;
    const emailError = !validateEmail(email) && !forLogin;

    if (!nameError && !numError && !emailError) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
          callback: (response) => {
            this.setState({
              capchaSolved: true,
            });
          },
          "expired-callback": () => {
            this.setState({
              errors: Object.assign(noErrors, { capchaError: true }),
            });
          },
        }
      );
      try {
        const response = await firebase
          .auth()
          .signInWithPhoneNumber(finalContactNumber, window.recaptchaVerifier);
        window.confirmationResult = response;
        this.setState({
          loadingCapcha: false,
        });
      } catch (e) {
        console.log(e);
        this.setState({
          errors: Object.assign(noErrors, {
            signupError: "Problem Completing Request",
          }),
          loading: false,
          loadingCapcha: false,
        });
      }
    } else {
      this.setState({
        errors: Object.assign(noErrors, {
          nameError: true,
          numberError: true,
          emailError: true,
        }),
        loading: false,
        loadingCapcha: false,
      });
    }
  };

  submitOTP = async () => {
    try {
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { contactNumber, otp, errors, loading, loadingCapcha, capchaSolved } =
      this.state;
    const { modalProps } = this.props;
    const { forLogin = false } = modalProps;
    return (
      <Segment basic>
        <div style={{ border: "none" }}>
          <div className="title-large" style={{ textAlign: "center" }}>
            Welcome to Luxo Cabs
          </div>
          <br />
          <Form loading={loading}>
            {!window.confirmationResult ? (
              <Fragment>
                {!forLogin && (
                  <Fragment>
                    <Form.Field required error={errors.nameError}>
                      <label>Full Name</label>
                      <input
                        name="fullName"
                        type="text"
                        placeholder="Full Name"
                        onChange={this.handleValueChange}
                        value={this.state.fullName}
                        style={{ padding: "1rem" }}
                      />
                    </Form.Field>
                    <Form.Field required error={errors.emailError}>
                      <label>Email </label>
                      <input
                        name="email"
                        type="email"
                        placeholder="Enter Email"
                        onChange={this.handleValueChange}
                        value={this.state.email}
                      />
                    </Form.Field>
                  </Fragment>
                )}
                {/* 
            {modalProps.emailError && (
              <div>
                <Item style={{ color: "red" }}>Invalid Email</Item>
                <br />
              </div>
            )} */}

                <Form.Field required error={errors.numberError}>
                  <label>Contact Number </label>
                  <PhoneInput
                    country={"au"}
                    // disableDropdown
                    specialLabel={null}
                    value={contactNumber}
                    // countryCodeEditable={false}
                    autoFormat={false}
                    onChange={(phone) =>
                      this.setState({ contactNumber: phone })
                    }
                  />
                </Form.Field>
                <div id="recaptcha-container"></div>
                <br />
              </Fragment>
            ) : (
              <div style={{ textAlign: "center" }}>
                <Form.Field width={16}>
                  <OTPInput
                    style={{ marginLeft: "auto", marginRight: "auto" }}
                    value={otp}
                    numInputs={6}
                    inputType="number"
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                    inputStyle={{
                      fontSize: "1rem",
                      width: "20%",
                      height: "4rem",
                      color: "black",
                    }}
                    onChange={(otp) => {
                      this.setState({
                        otp: otp,
                      });
                    }}
                  />
                </Form.Field>
              </div>
            )}

            {errors.signupError && (
              <div>
                <Item style={{ color: "red" }}>
                  {errors.signupError ??
                    "Problem Creating Account | Either the Account Already Exists or there is some problem with the data you entered!"}
                </Item>
                <br />
              </div>
            )}
            {errors.capchaError && (
              <div>
                <Item style={{ color: "red" }}>
                  Please revalidate the Capcha
                </Item>
                <br />
              </div>
            )}
            <br />
            <div style={{ width: "100%", textAlign: "center" }}>
              <AccentButton
                fluid
                onClick={
                  window.confirmationResult
                    ? this.confirmOTP
                    : this.handleFormSubmit
                }
                loading={loading || loadingCapcha}
                color="black"
                type="submit"
                text={
                  window.confirmationResult ? "Confirm OTP" : "Generate OTP"
                }
                style={{ padding: "2rem 0rem" }}
              />
            </div>
          </Form>
        </div>
      </Segment>
    );
  }
}

export default connect(mapState, actions)(withRouter(LoginRegisterForm));
