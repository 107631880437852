import React, { createRef, useState } from "react";
import { Button, Grid, Icon, Image, Sticky } from "semantic-ui-react";
import { ASSETS } from "../../Utils/Assets";
import MiniBookingForm from "../BookingForm/MiniBookingForm";
import SeamlessTravel from "./Sections/SeamlessTravel";
import RideWithYourComfort from "./Sections/RideWithYourComfort";
import { isMobile } from "react-device-detect";
import { connect } from "react-redux";
import { updateBookingForm } from "../../Redux/Booking/bookingActions";
import { DesktopOnly, MobileOnly, WidescreenOnly } from "../Custom/Renderers";
import { Helmet } from "react-helmet";
import PortableFAQSection from "../FAQSection/PortableFAQSection";
import AboutLuxoCabs from "./Sections/AboutSection";
import { PRIMARY_COLOR } from "../../Utils/Constants";
import TACApprovedIndicator from "../Misc/TACApprovedIndicator";

const actions = {
  updateBookingForm,
};

const mapState = (state) => {
  return {
    auth: state.auth,
  };
};

function LandingPage(props) {
  const [showBooking, setShowBooking] = useState(false);
  const { updateBookingForm } = props;

  const form = () => {
    return (
      <Grid.Column width={16} verticalAlign="middle">
        <Grid columns={1} stretched>
          <Grid.Column
            computer={8}
            widescreen={6}
            tablet={16}
            mobile={16}
            verticalAlign="middle"
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                padding: isMobile ? "1rem" : "32px",
                background: "rgba(255, 255, 255, 0.80)",
                borderRadius: 16,
                border: "0.50px #E6E6E6 solid",
                backdropFilter: "blur(40px)",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                gap: 24,
                display: "inline-flex",
              }}
            >
              <MiniBookingForm updateBookingForm={updateBookingForm} />
            </div>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    );
  };

  const width = window.innerWidth;

  return (
    <div>
      <Helmet>
        <title>Luxo Cabs</title>
        <meta
          name="description"
          content="Luxo Cabs is a company based in Melbourne Australia that offers reliable transportation services for both locals and tourists."
        />
      </Helmet>
      <Grid textAlign="left">
        <DesktopOnly>
          <Grid.Row
            className="lp-background"
            style={Object.assign({
              margin: "0px",
              padding: "4rem",
              width: width,
              minHeight: (width * 3) / 16,
            })}
          >
            {form()};
          </Grid.Row>
        </DesktopOnly>
        <MobileOnly>
          <Grid.Row
            style={Object.assign({
              margin: "0px",
              width: "100vw",
            })}
          >
            <Button
              size="large"
              onClick={() => {
                setShowBooking(!showBooking);
              }}
              color={showBooking ? "black" : "orange"}
              fluid
              icon={
                <Icon name={showBooking ? "close" : "arrow circle right"} />
              }
              labelPosition="left"
              content={showBooking ? "Close" : "Book Now"}
              style={{ marginTop: "1rem", borderRadius: "0px" }}
            />

            {showBooking ? form() : <div />}
            <Image src={ASSETS.LP_BANNER} />
          </Grid.Row>
        </MobileOnly>
        <AboutLuxoCabs />
        <Grid.Row columns={1}>
          <Grid.Column>
            <SeamlessTravel />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <RideWithYourComfort />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <PortableFAQSection />
    </div>
  );
}

export default connect(mapState, actions)(LandingPage);
