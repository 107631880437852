import { Icon, List } from "semantic-ui-react";
import { ASSETS } from "../Assets";
import { Link } from "react-router-dom";
import SuburbsWeCoverSection from "../../Components/Services/AirportTransfers/SuburbsWeCoverSection";

export const SERVICES_DATA = {
  "point-to-point-transportation": {
    serviceId: "point-to-point-transportation",
    banner: ASSETS.BANNER_ROADSIDE,
    name: "Point-to-Point Transportation",
    promoImage: ASSETS.IMAGE_POINT_TO_POINT,
    images: [ASSETS.BENZ, ASSETS.BENZ, ASSETS.BENZ],
    helmet:
      "Point-to-Point Transportation is the Primary service offered by LuxoCabs. This involves picking up passengers at their specified location and dropping them off at their desired destination.",
    includes: [
      "Convenience: We offer a variety of services and payment options to make it easy for you to book a ride.",
      "Reliability: Our drivers are experienced and professional, and they are committed to getting you to your destination on time.",
      "Comfort: Our vehicles are clean and well-maintained, and we offer a variety of amenities to make your ride more comfortable.",
      "Safety: We are committed to providing our customers with a safe transportation experience. Our drivers are trained in safety procedures and our vehicles are regularly inspected.",
    ],
    descriptionHeader:
      "Here are some of the benefits of using LuxoCabs for your point-to-point transportation needs",
    description:
      "The primary service offered by our company is point-to-point transportation. This involves picking up passengers at their specified location and dropping them off at their desired destination.If you are looking for a point-to-point transportation company in Melbourne, Australia, we encourage you to consider Luxo Cabs. We offer a variety of services, competitive rates, and excellent customer service. We are confident that we can meet your transportation needs and exceed your expectations.",
  },
  "hourly-charter": {
    serviceId: "hourly-charter",
    name: "Hourly Charter",
    promoImage: ASSETS.IMAGE_HOURLY,
    banner: ASSETS.BANNER_HOURLY,
    images: [ASSETS.BENZ, ASSETS.BENZ, ASSETS.BENZ],
    helmet:
      "Our customers can hire our taxi for a specific period of time, allowing them to make multiple stops and travel to various locations within that time frame.",
    includes: [
      "Business trip: If you are travelling to Melbourne for a business trip, you can book an hourly charter taxi to take you to and from your meetings, appointments, and events. This will save you from having to hail a taxi each time you need to go somewhere, and it will also ensure that you have a reliable and comfortable ride.",
      "Sightseeing tour: If you are visiting Melbourne for the first time, you can book an hourly charter taxi to take you on a sightseeing tour of the city. The driver can take you to all of the popular tourist attractions, and they can also provide you with information about the city.",
      "Special event: If you are attending a special event in Melbourne, such as a wedding, sporting event, or concert, you can book an hourly charter taxi to take you to and from the event. This will save you from having to worry about parking, and it will also ensure that you can leave the event at your own convenience.",
      "Safety: We are committed to providing our customers with a safe transportation experience. Our drivers are trained in safety procedures and our vehicles are regularly inspected.",
    ],
    descriptionHeader:
      "Here are some examples of how Luxo Cabs' hourly charter taxi service can be used",
    description: (
      <div>
        We also provide hourly charter services, where customers can hire our
        taxi for a specific period of time, allowing them to make multiple stops
        and travel to various locations within that time frame.
        <br />
        <br />
        With Luxo Cabs hourly charter taxi service, you can book a taxi for a
        minimum of two hours, and you will have a dedicated driver and vehicle
        at your disposal for that time. This means that you can relax and enjoy
        your ride, knowing that you will be taken to your destination safely and
        efficiently.No matter what your needs are, Luxo Cabs hourly charter taxi
        service is a convenient and affordable way to get around Melbourne.
      </div>
    ),
  },
  "airport-transfers": {
    serviceId: "airport-transfers",
    name: "Airport Transfers",
    promoImage: ASSETS.IMAGE_AIRPORT,
    banner: ASSETS.BANNER_AIRPORT,
    helmet:
      "Booking your airport transfer with Luxo Cabs is a breeze! Just go to our super easy online booking system or through our call centre to book your ride. ",
    images: [ASSETS.BENZ, ASSETS.BENZ, ASSETS.BENZ],
    descriptionHeader:
      "Why Choose Luxo Cabs for Your Melbourne Airport Transfers?",
    includes: [
      <p>
        <b>Wide Range of Services:</b> We cater to all your needs, offering a
        diverse fleet of clean, modern vehicles to suit your group size and
        budget. Choose from sleek sedans, or comfortable minibuses, perfect for
        families or larger groups while departing or arriving from Melbourne
        Airport.,
      </p>,
      <p>
        <b>City to Melbourne Airport:</b> Leave the navigating to us. Let our
        expert drivers whisk you safely and efficiently from anywhere in the
        city to your departing flight.,
      </p>,
      <p>
        <b>Meet & Greet:</b> Our friendly drivers will greet you warmly at the
        arrivals terminal, eliminating the stress of navigating taxi queues or
        hailing rides.,
      </p>,
      <p>
        <b>Pre-Booked Convenience:</b> Pre-book your transfer online or through
        our website, ensuring a driver awaits you upon arrival, even during peak
        hours. No waiting, no hassle, just pure convenience.,
      </p>,
      <p>
        <b>Competitive Rates:</b> We offer transparent and competitive taxi fare
        pricing, with no hidden fees or surprises. Enjoy peace of mind knowing
        the exact fare before you book your Airport Taxi.,
      </p>,
      <p>
        <b>24/7 Availability:</b> We're here for you around the clock at the
        Melbourne Airport, no matter your arrival or departure time. Whether
        it's a red-eye flight or an early morning getaway, we'll be there to
        take you where you need to be.
      </p>,
    ],
    description: (
      <div>
        <p>
          Welcome to Melbourne! Whether you're a seasoned traveller or a
          first-time visitor, navigating the bustling metropolis after a long
          flight can be daunting. That's where Luxo Cabs steps in, your reliable
          partner for seamless Melbourne Airport Transfers.
        </p>
        <p>
          We understand the importance of a smooth transition, and we're
          dedicated to making your journey from Airport to City and vice versa
          an experience of comfort and convenience.
        </p>
      </div>
    ),
    otherElements: [<SuburbsWeCoverSection />],
  },
  conferences: {
    serviceId: "conferences",
    name: "Conferences",
    banner: ASSETS.BANNER_CONFERENCE,
    helmet:
      "We understand that conferences can be demanding, so we make it our priority to make your travel as stress-free as possible. ",
    promoImage: ASSETS.IMAGE_CONFERENCE,
    images: [ASSETS.BENZ, ASSETS.BENZ, ASSETS.BENZ],
    includes: [
      "Transportation to and from the event venue. This is the most common service that we provide. We can pick up attendees from their hotels or homes and take them to the event venue, and then back again at the end of the day.",
      "We also provide conference planning services. This can include helping to book taxis for transportation, as well as providing other logistical support such as finding parking and coordinating schedules.",
      "All-hours service. Our taxi service will be available 24/7, in case there are any last-minute changes to the schedule or if there are any transportation issues.",
      "Well-trained drivers. Our drivers are familiar with the city and surrounding area and they are able to handle large groups of people.",
      "Clean, comfortable vehicles. Our vehicles are clean and comfortable, and equipped with air conditioning and other amenities.",
    ],
    descriptionHeader:
      "Here are some of the benefits of using LuxoCabs for your Conference Transportation Needs",
    description:
      "We understand that conferences can be demanding, so we make it our priority to make your travel as stress-free as possible. Our drivers are familiar with the Melbourne city area and can navigate traffic quickly and efficiently. They are also courteous and professional, and will always go the extra mile to ensure your comfort and safety.",
  },
  "corporate-transfers": {
    serviceId: "corporate-transfers",
    name: "Corporate Transfers",
    banner: ASSETS.BANNER_CORPORATE,
    helmet:
      "We provide corporate transportation services to businesses and professionals. This includes transportation for business meetings, conferences, and other corporate events.",
    promoImage: ASSETS.IMAGE_CORPORATE,
    images: [ASSETS.BENZ, ASSETS.BENZ, ASSETS.BENZ],
    descriptionHeader:
      "Here are some specific examples of how LuxoCabs can be a partner in corporate transportation for businesses in Melbourne:",
    includes: [
      "We can help you to make a good impression on your clients. When your clients arrive in Melbourne, they will be greeted by one of our professional chauffeurs and transported to their hotel in a luxury vehicle. This will send a message that your company values their business and is committed to providing them with a positive experience.",
      "We can help you to save time and money. We offer corporate discounts and packages that can help you to save money on your transportation costs. We can also help you to save time by planning and coordinating your transportation needs.",
      "We can help you to reduce stress. We understand that travelling can be stressful, especially when you are on a business trip. That is why we offer a variety of features and services that are designed to make your ride as comfortable and stress-free as possible.",
    ],

    description:
      "We provide corporate transportation services to businesses and professionals. This includes transportation for business meetings, conferences, and other corporate events.If you are looking for a reliable and professional corporate transportation partner in Melbourne, Luxo Cabs is the perfect choice. Contact us today to learn more about our services.",
  },
  "cruise-ship-transfers": {
    serviceId: "cruise-ship-transfers",
    banner: ASSETS.BANNER_CRUIZE,
    name: "Cruise Ship Transfers",
    promoImage: ASSETS.IMAGE_AIRPORT,
    images: [ASSETS.BENZ, ASSETS.BENZ, ASSETS.BENZ],
    helmet:
      "Luxo Cabs offer specialised transportation such as Cruise Ship Transfers. We transport passengers arriving and departing to and from the ports/Cruise Ships.",
    includes: [
      "Reliable and affordable service: We offer competitive rates and our drivers are always on time.",
      "Meet and greet service: Our driver will meet you at the cruise terminal with a sign and help you with your luggage.",
      "Door-to-door service: We will pick you up from your hotel or residence and take you directly to the cruise terminal.",
      "24/7 service: We are available 24 hours a day, 7 days a week, so you can book your transfer anytime.",
    ],
    descriptionHeader:
      "Here are some of the benefits of booking a cruise ship transfer with Luxo Cabs:",
    description: (
      <div>
        We understand that your cruise ship transfer is an important part of
        your vacation, and we are committed to providing you with the best
        possible experience. Our drivers are experienced and professional, and
        they will take care of everything to ensure that you arrive at your
        cruise ship on time and stress-free.
        <br />
        <br />
        We also offer specialised transportation such as Cruise Ship Transfers.
        We transport passengers arriving and departing to and from the
        ports/Cruise Ships. Our drivers are familiar with the layout of the
        cruise terminal.
        <br />
        <br />
        We offer a reliable and affordable service to and from all major cruise
        terminals in Melbourne, including Station Pier and Webb Dock.
        <br />
        <br />
        To book a cruise ship transfer with Luxo Cabs, simply visit our website
        or call us at 03 8488 8052. We will be happy to provide you with a quote
        and answer any questions you have.
      </div>
    ),
  },

  "international-event-transfers": {
    serviceId: "international-event-transfers",
    name: "International Event Transfers",
    promoImage: ASSETS.IMAGE_INTERNATIONAL_EVENT,
    banner: ASSETS.BANNER_INTERNATIONAL,
    images: [ASSETS.BENZ, ASSETS.BENZ, ASSETS.BENZ],
    helmet:
      "Luxo Cabs is committed to providing a safe and reliable transportation service to all of its customers, including international students.",
    includes: [
      "Airport transfers: We offer pick-up and drop-off services at Melbourne International Airport (MEL). Our drivers are knowledgeable about the city and can help you to get to your destination safely and efficiently.",
      "Campus transfers: We offer transportation services to and from all of the major universities in Melbourne. Our drivers are familiar with the campuses and can help you to find your way to your classes and other activities.",
      "City tours: We offer city tours that are designed to help international students learn about Melbourne and its attractions. Our tours are led by experienced guides who can provide you with insights into the city's history, culture, and lifestyle.",
    ],
    description: (
      <div>
        Luxo Cabs is committed to providing a safe and reliable transportation
        service to all of its customers, including international students. We
        understand that transferring to a new country can be a daunting
        experience, and we want to make the process as smooth and stress-free as
        possible for our international student customers.
        <br />
        <br />
        Our drivers are well versed with the addresses and layouts of common
        educational institutions which are the destination of international
        students. Our drivers can provide basic orientation of the city,
        important landmarks, public transportation options and nearby amenities
        to help students become familiar with their surroundings.
      </div>
    ),
    descriptionHeader:
      "We offer a variety of services that are specifically tailored to the needs of international students, including:",
  },

  "special-events": {
    serviceId: "special-events",
    name: "Special Events",
    promoImage: ASSETS.IMAGE_SPECIAL_EVENT,
    banner: ASSETS.BANNER_SPECIAL_EVENT,
    images: [ASSETS.BENZ, ASSETS.BENZ, ASSETS.BENZ],
    helmet:
      "Whether you're planning a wedding, corporate function, or sporting event, Luxo Cabs can help you get your guests to and from the venue safely and on time",
    includes: [
      "Reliability: We know that punctuality is important on special occasions, so we make sure that our vehicles are always on time and that our drivers are familiar with the best routes to and from your venue.",
      "Affordability: We offer competitive rates on our taxi hire services, and we can work with you to create a package that fits your budget.",
      "Comfort and style: Our vehicles are clean, well-maintained, and equipped with all the latest amenities. We also offer a variety of interior and exterior decorations to help you create the perfect look for your event.",
      "Professionalism: Our drivers are experienced and professional, and they are committed to providing our customers with the best possible service. They are also happy to go the extra mile to make sure that your special event is a success.",
    ],
    descriptionHeader:
      "Here are just a few of the benefits of hiring Luxo Cabs for your special event",
    description: (
      <div>
        Melbourne is a city that loves to celebrate, and Luxo Cabs is proud to
        be the go-to taxi hire company for special events of all kinds. Whether
        you're planning a wedding, corporate function, or sporting event, we can
        help you get your guests to and from the venue safely and on time.
        <br />
        <br />
        If you're planning a special event in Melbourne, contact Luxo Cabs today
        to learn more about our taxi hire services. We'll be happy to work with
        you to create a package that meets your specific needs and budget.
      </div>
    ),
  },
  "parcel-delivery": {
    serviceId: "parcel-delivery",
    banner: ASSETS.BANNER_ROADSIDE,
    name: "Parcel Delivery",
    promoImage: ASSETS.IMAGE_POINT_TO_POINT,
    images: [ASSETS.BENZ, ASSETS.BENZ, ASSETS.BENZ],
    helmet:
      "Point-to-Point Transportation is the Primary service offered by LuxoCabs. This involves picking up passengers at their specified location and dropping them off at their desired destination.",
    includes: [
      <div id="speed_and_efficiency">
        <p className="title-medium">Speed and Efficiency:</p>
        <List bulleted relaxed>
          <List.Item>
            <b>Same-day delivery:</b> Urgent documents or forgotten gifts? Luxo
            Cabs can ensure your parcels reach their destination within
            Melbourne on the same day, perfect for last-minute needs.
          </List.Item>
          <List.Item>
            <b>Scheduled deliveries:</b> Plan ahead with scheduled parcel
            deliveries, tailored to your specific requirements. Whether it's a
            daily business delivery or a one-time item, Luxo Cabs gets it there
            reliably.
          </List.Item>
          <List.Item>
            <b>Real-time tracking:</b> Gain peace of mind by tracking your
            parcel's journey in real-time, knowing exactly where it is and when
            it will arrive.
          </List.Item>
        </List>
      </div>,
      <div id="Safety and Security">
        <p className="title-medium">Safety and Security:</p>
        <List bulleted relaxed>
          <List.Item>
            <b>Trained and professional drivers:</b> Our team of trusted drivers
            understands the importance of careful handling,ensuring your parcels
            arrive in the same condition they left.
          </List.Item>
          <List.Item>
            <b>Secure vehicles:</b> Parcels are transported in designated
            compartments within the vehicles, safeguarding them from damage and
            unauthorised access.
          </List.Item>
          <List.Item>
            <b>Insurance options:</b> Choose from additional insurance options
            for valuable or fragile items, providing extra peace of mind.
          </List.Item>
        </List>
      </div>,
      <div id="Convenience and Flexibility">
        <p className="title-medium">Convenience and Flexibility:</p>
        <List bulleted relaxed>
          <List.Item>
            <b>Door-to-door service:</b> No more inconvenient drop-off points or
            long walks. Luxo Cabs picks up your parcels from your doorstep and
            delivers them directly to the recipient's door.
          </List.Item>
          <List.Item>
            <b>Multiple parcel sizes:</b> Whether it's a small envelope or a
            large box, Luxo Cabs can accommodate a variety of parcel sizes to
            meet your needs.
          </List.Item>
          <List.Item>
            <b>Competitive rates:</b> Enjoy transparent and competitive pricing
            for your parcel deliveries, making them an affordable and
            hassle-free choice.
          </List.Item>
        </List>
      </div>,
      <div id="Additional Services">
        <p className="title-medium">Additional Services:</p>
        <List bulleted relaxed>
          <List.Item>
            <b>Specialised delivery options:</b> Catering to specific needs,
            Luxo Cabs might offer temperature-controlled deliveries for
            sensitive items or express deliveries for urgent needs.
          </List.Item>
          <List.Item>
            <b>Weekend and after-hours deliveries:</b> For added convenience,
            explore the possibility of weekend or after-hours delivery options,
            catering to specific needs and schedules.
          </List.Item>
        </List>
      </div>,
    ],
    descriptionHeader: "",
    description: (
      <div>
        While Luxo Cabs is known for its reliable and comfortable taxi services
        in Melbourne, our commitment to convenience extends beyond passenger
        transport.{" "}
        <b>
          Let's shed light on our parcel delivery services, highlighting what
          makes them a valuable option for Melbournians:
        </b>
        <br />
        <List relaxed>
          <List.Item>
            {" "}
            <Icon name="check circle" style={{ marginRight: "1em" }} />
            Speed and Efficiency
          </List.Item>
          <List.Item>
            <Icon name="check circle" style={{ marginRight: "1em" }} />
            Safety and Security
          </List.Item>
          <List.Item>
            <Icon name="check circle" style={{ marginRight: "1em" }} />
            Convenience and Flexibility
          </List.Item>
          <List.Item>
            <Icon name="check circle" style={{ marginRight: "1em" }} />
            Additional Services
          </List.Item>
        </List>
      </div>
    ),
  },
  worksafe: {
    serviceId: "worksafe",
    banner: ASSETS.BANNER_CONFERENCE,
    name: "Worksafe",
    promoImage: ASSETS.IMAGE_POINT_TO_POINT,
    images: [ASSETS.BENZ, ASSETS.BENZ, ASSETS.BENZ],
    helmet:
      "Luxo Cabs: Melbourne's Safest and Most Reliable Taxi Service, Now Partnered with WorkSafe",
    includes: [
      <div>
        <div className="title-large">What does this mean for you?</div>
        <br />
        <div>
          If you're an injured worker who has been approved for travel by
          WorkSafe, you can now book your Luxo Cabs with confidence, knowing
          that you're getting:
        </div>
        <br />
        <List bulleted relaxed>
          <List.Item>
            <b>Same-day delivery:</b> Urgent documents or forgotten gifts? Luxo
            Cabs can ensure your parcels reach their destination within
            Melbourne on the same day, perfect for last-minute needs.
          </List.Item>
          <List.Item>
            <b>Scheduled deliveries:</b> Plan ahead with scheduled parcel
            deliveries, tailored to your specific requirements. Whether it's a
            daily business delivery or a one-time item, Luxo Cabs gets it there
            reliably.
          </List.Item>
          <List.Item>
            <b>Real-time tracking:</b> Gain peace of mind by tracking your
            parcel's journey in real-time, knowing exactly where it is and when
            it will arrive.
          </List.Item>
        </List>
        <br />
        <b>Booking your WorkSafe taxi is easy</b> To book your WorkSafe taxi,
        simply call us on{"  "}
        <br />
        <a href="tel:0384888052">03 8488 8052</a> or visit our website
        <a href="https://luxocabs.com.au" target="_blank"> luxocabs.com.au</a> and select the
        "WorkSafe booking" option.
      </div>,
    ],
    descriptionHeader: "",
    description: (
      <div>
        At Luxo Cabs, we're committed to providing our customers with the safest
        and most reliable taxi service in Melbourne. That's why we're proud to
        announce our new partnership with WorkSafe, the Victorian regulator for
        workplace health and safety.
        <br />
        <br />
        This means that Luxo Cabs is now the preferred taxi provider for
        WorkSafe-approved injured workers who need transportation for medical
        appointments or other work-related activities
        <b>What does this mean for you?</b>
        <br />
      </div>
    ),
  },
};
