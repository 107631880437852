import React, { Component } from "react";
import { Helmet } from "react-helmet";
import {
  Accordion,
  Icon,
  Image,
  Grid,
  Segment,
  List,
  Container,
} from "semantic-ui-react";
import { ASSETS } from "../../Utils/Assets";
import withRouter from "../../Utils/router/router_hocs";

class SuburbsWeCoverPage extends Component {
  state = { activeIndex: 0 };

  pages = {
    "Wyndham Vale to Melbourne Airport": 0,
    "Casey Taxi Service": 1,
    "Tarneit to Melbourne Airport": 2,
    "Truganina to Melbourne Airport": 3,
    "Altona to Melbourne Airport": 4,
    "Williamstown to Melbourne Airport": 5,
    "Newport to Melbourne Airport": 6,
    "Point Cook to Melbourne Airport": 7,
    "Hoppers Crossing to Melbourne Airport": 8,
    "Manor Lakes to Melbourne Airport": 9,
    "Werribee to Melbourne Airport": 10,
    "Geelong to Melbourne Airport": 11,
    "Lara to Melbourne Airport": 12,
    "Deer Park to Melbourne Airport": 13,
    "Melton to Melbourne Airport": 14,
    "Ballarat to Melbourne Airport": 15,
    "Bacchus Marsh to Melbourne Airport": 16,
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  componentDidMount() {
    let pageID = this.props.params.id;
    if (this.pages[pageID]) {
      this.setState({
        activeIndex: this.pages[pageID],
      });
      const docID = document.getElementById(pageID);
      if (docID) {
        setTimeout(() => {
          docID.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }, 2000);
      }
    }
  }

  render() {
    const { activeIndex } = this.state;

    return (
      <div style={{ display: "block", textAlign: "center" }}>
        <Helmet>
          <title>About - Luxo Cabs</title>
          <meta
            name="description"
            content="Luxo Cabs is a company based in Melbourne Australia that offers reliable transportation services for both locals and tourists."
          />
        </Helmet>
        <div style={{ position: "relative" }}>
          <Image src={ASSETS.BANNER_FLEET} fluid />
          <div className="dark-tint"></div>
          <Grid
            columns={1}
            style={{
              position: "absolute",
              left: "0px",
              top: "0px",
              zIndex: "9999",
              width: "100%",
              height: "100%",
            }}
          >
            <Grid.Column verticalAlign="middle" textAlign="center">
              <div className="heading-text" style={{ color: "white" }}>
                Suburbs We Cover
              </div>
            </Grid.Column>
          </Grid>
        </div>
        <Segment
          attached="bottom"
          basic
          secondary
          style={{ textAlign: "left", padding: "2em" }}
        >
          <Grid centered>
            <Grid.Column computer={10} mobile={16} tablet={14}>
              <Accordion styled fluid>
                <Accordion.Title
                  id="Wyndham Vale to Melbourne Airport"
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Wyndham Vale to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <Segment basic secondary>
                    <p className="medium-text">
                      Welcome to our esteemed transportation service, Wyndham
                      Vale Taxis. We are pleased to offer our exceptional taxi
                      services with a focus on ensuring your comfort and safety.
                      Our team of professional and affable drivers are readily
                      available to assist you at any time. Our commitment to
                      providing a seamless and enjoyable experience is at the
                      forefront of our operations.
                    </p>
                  </Segment>
                </Accordion.Content>

                <Accordion.Title
                  id="Casey Taxi Service"
                  active={activeIndex === 1}
                  index={1}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">Casey Taxi Service</span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <Segment basic secondary>
                    <p className="medium-text">
                      Beaconsfield, Berwick, Blind Bight, Cannons Creek, Clyde,
                      Clyde North, Cranbourne, Cranbourne East, Cranbourne
                      North, Cranbourne South, Cranbourne West, Devon Meadows,
                      Doveton, Endeavour Hills, Eumemmerring, Fountain Gate,
                      Hallam, Hampton Park, Harkaway, Junction Village,
                      Lynbrook, Lyndhurst, Lysterfield South, Narre Warren,
                      Narre Warren North, Narre Warren South, Pearcedale,
                      Tooradin and Warneet.
                    </p>
                  </Segment>
                </Accordion.Content>

                <Accordion.Title
                  id="Tarneit to Melbourne Airport"
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Tarneit to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <Segment basic secondary>
                    <Container className="medium-text">
                      <b>
                        Fly with Ease from Tarneit to Melbourne Airport with
                        Luxo Cabs Airport Transfers.
                      </b>
                      <br />
                      <br />
                      <p className="medium-text">
                        Planning a trip from Tarneit and need a reliable airport
                        taxi to whisk you to Melbourne Airport? Look no further
                        than Luxo Cabs Airport Transfers! We offer a range of
                        services to ensure a smooth and comfortable journey.
                        Tarneit is a suburb located in Melbourne, Victoria,
                        Australia, roughly 25 kilometres (16 miles) west of the
                        city's central business district. It falls under the
                        City of Wyndham local government area.
                      </p>
                      <p>Here's how you can make the journey:</p>
                      <b className="title-small">Booking your ride:</b>
                      <br />
                      <br />
                      <p className="medium-text">
                        <b>Online:</b> Visit the Luxo Cabs website (
                        <a href="https://luxocabs.com.au/">
                          https://luxocabs.com.au/
                        </a>
                        ). Specify your pickup location in Tarneit, date and
                        time of travel, and the number of passengers.
                      </p>
                      <p className="medium-text">
                        <b>Phone:</b> Call Luxo Cabs directly at{" "}
                        <a href="tel:0384888052">03 8488 8052</a> to speak with
                        a customer service representative who can assist you
                        with your booking.
                      </p>
                      <br />
                      <b>Additional Luxo Cabs Airport Transfer Perks:</b>
                      <List bulleted relaxed>
                        <List.Item className="medium-text">
                          Meet and Greet Service: Opt for a meet-and-greet
                          service for an extra touch of convenience upon arrival
                          at Melbourne Airport.
                        </List.Item>
                        <List.Item className="medium-text">
                          Real-Time Flight Tracking: We monitor your flight in
                          real-time, adjusting your pickup time if needed to
                          guarantee you catch your flight even in case of
                          delays.
                        </List.Item>
                      </List>
                      <p className="medium-text">
                        Flexible Payment Options: Pay for your airport taxi with
                        cash, credit card, or through our secure online booking
                        system.
                      </p>
                      <p className="medium-text">
                        By choosing Luxo Cabs, you can be sure of a stress-free
                        and enjoyable travel experience from Tarneit to
                        Melbourne Airport.
                      </p>
                    </Container>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Truganina to Melbourne Airport"
                  active={activeIndex === 3}
                  index={3}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Truganina to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <Segment basic secondary>
                    <p className="medium-text">
                      Headed for a thrilling adventure or a relaxing getaway?
                      Don't let Truganina to Melbourne Airport taxi woes cloud
                      your journey. Luxo Cabs Airport Transfers is here to whisk
                      you away in style and comfort, ensuring a smooth and
                      stress-free airport taxi ride.
                    </p>
                    <p className="medium-text">
                      The distance from Truganina to Melbourne Airport is
                      approximately 24.1 kilometres along the Metropolitan Ring
                      Rd/Western Ring Road/M80. It usually takes about 17
                      minutes to drive from Truganina to Melbourne Airport under
                      normal traffic conditions.
                    </p>
                    <p className="title-small">
                      Book Your Luxo Cabs Airport Transfer with Ease:
                    </p>
                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        Meet and Greet Service: Opt for a meet-and-greet service
                        for an extra touch of convenience upon arrival at
                        Melbourne Airport.
                      </List.Item>
                    </List>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Altona to Melbourne Airport"
                  active={activeIndex === 4}
                  index={4}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Altona to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <Segment basic secondary>
                    <p className="medium-text">
                      Nestled along the picturesque shores of Port Phillip Bay,
                      Altona is a hidden gem among Melbourne's suburbs, offering
                      a unique blend of coastal charm, rich history, and modern
                      amenities.
                    </p>
                    <p className="medium-text">
                      Luxo Cabs is your top-tier provider of luxury
                      transportation services in Altona, Melbourne. Whether
                      you're a business traveller, tourist, or resident looking
                      for a comfortable and reliable ride to Melbourne Airport
                      from Altona, look no further. We specialise in delivering
                      exceptional airport taxi services tailored to meet your
                      specific needs, ensuring a stress-free and seamless
                      journey.
                    </p>
                    <p className="medium-text">
                      The distance between Altona and Melbourne Airport (also
                      known as Tullamarine Airport) is approximately 24
                      kilometres (about 15 miles) when travelling via the
                      fastest route by Luxo Cabs.
                    </p>
                    <p className="medium-text">
                      At Luxo Cabs, we prioritise customer satisfaction above
                      all else. Our friendly and knowledgeable chauffeurs are
                      committed to providing personalised service, catering to
                      your specific requirements throughout your journey.
                      Whether you need assistance with luggage or have special
                      requests, we go the extra mile to exceed your expectations
                      and make your airport transfer experience truly
                      exceptional.
                    </p>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Williamstown to Melbourne Airport"
                  active={activeIndex === 5}
                  index={5}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Williamstown to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  <Segment basic secondary>
                    <p className="medium-text">
                      Are you departing from Williamstown for exciting
                      adventures or a relaxing getaway? Don't let the hassle of
                      airport transportation cloud your journey. Luxo Cabs, the
                      best taxi in Williamstown, offers seamless and comfortable
                      airport transfers to ensure your trip starts stress-free.
                    </p>
                    <p className="title-small">
                      Booking Your Luxo Cabs Airport Transfer:
                    </p>
                    <p className="medium-text">
                      Effortless Online Booking: Skip the wait and book your
                      Williamstown airport taxi online in minutes! Visit our
                      website and Choose your pick-up location in Williamstown,
                      date, and time, and select the perfect vehicle for your
                      group and luggage.
                    </p>
                    <p className="medium-text">
                      The distance between Altona and Melbourne Airport (also
                      known as Tullamarine Airport) is approximately 24
                      kilometres (about 15 miles) when travelling via the
                      fastest route by Luxo Cabs.
                    </p>
                    <p className="medium-text">
                      Personalised Phone Booking: Prefer a human touch? Our
                      friendly customer service representatives are just a call
                      away at 03 8488 8052. They'll help you book your Luxo Cabs
                      Melbourne airport taxi and answer any questions you may
                      have.
                    </p>
                    <p className="title-small">Relax and Enjoy the Ride:</p>
                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        <b>Door-to-Door Airport Taxi Service:</b> Leave the
                        driving to us! Sit back and relax as our professional
                        drivers pick you up directly from your Williamstown
                        doorstep and deliver you straight to Melbourne Airport's
                        terminal. No more airport parking struggles or crowded
                        public transport woes.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Modern Fleet for Every Need:</b> Choose from our
                        diverse fleet of sleek sedans, spacious SUVs, or
                        comfortable maxi cabs, each meticulously maintained and
                        air-conditioned for a luxurious Williamstown taxi
                        experience.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Expert Drivers at Your Service:</b> Our Melbourne
                        Airport taxi drivers are local Williamstown pros,
                        well-versed in the quickest routes to get you to the
                        airport on time, every time. Enjoy a smooth and
                        worry-free ride with their courteous and professional
                        service.
                      </List.Item>
                    </List>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Newport to Melbourne Airport"
                  active={activeIndex === 6}
                  index={6}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Newport to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                  <Segment basic secondary>
                    <p className="medium-text">
                      Planning a trip and need the best taxi in Newport to whisk
                      you smoothly to Melbourne Airport? Look no further than
                      Luxo Cabs, your reliable partner for stress-free airport
                      transfers. Ditch the parking woes and public transport
                      hassles, and let us turn your Newport to Melbourne Airport
                      taxi ride into a comfortable and convenient experience.
                    </p>
                    <p className="title-small">
                      Why Choose Luxo Cabs for Your Newport Airport Transfer?
                    </p>

                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        <b>Seamless Booking:</b> Book your Newport taxi to the
                        airport in minutes! Choose from our user-friendly
                        website or simply call us at{" "}
                        <a href="tel:0384888052">03 8488 8052</a>. We're here to
                        make your journey effortless.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Door-to-Door Service:</b> No need to lug your bags
                        through busy streets. We'll pick you up directly from
                        your Newport location and deliver you right to Melbourne
                        Airport's doorstep, leaving you relaxed and ready for
                        your flight.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Modern Fleet:</b> Choose from our diverse fleet of
                        sleek sedans, spacious SUVs, or comfortable maxi cabs to
                        perfectly accommodate your group and luggage. All our
                        vehicles are meticulously maintained and air-conditioned
                        for a luxurious ride.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Expert Drivers:</b> Our Melbourne Airport taxi
                        drivers are local pros, well-versed in the quickest
                        routes to get you to the airport on time, every time.
                        Sit back, relax, and enjoy the smooth journey under the
                        care of our friendly and professional chauffeurs.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Peace of Mind:</b> Worried about missing your flight?
                        We track your flight in real-time and adjust your
                        Newport pick-up time if needed, ensuring you reach the
                        airport without a hitch.
                      </List.Item>
                    </List>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Point Cook to Melbourne Airport"
                  active={activeIndex === 7}
                  index={7}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Point Cook to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 7}>
                  <Segment basic secondary>
                    <p className="medium-text">
                      Planning a trip and need a reliable Point Cook airport
                      taxi to whisk you effortlessly to Melbourne Airport? Look
                      no further than Luxo Cabs! We're the best taxi in Point
                      Cook, dedicated to making your airport transfer smooth,
                      comfortable, and stress-free.
                    </p>
                    <p className="title-small">
                      Why Choose Luxo Cabs for Your Point Cook Airport Taxi
                      Transfer?
                    </p>

                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        <b>Convenience you crave:</b> Skip the parking hassles
                        and public transport woes. We offer door-to-door
                        service, picking you up directly from your Point Cook
                        location and delivering you straight to Melbourne
                        Airport's terminal.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Comfort you deserve:</b> Choose from our modern fleet
                        of sedans, SUVs, and spacious maxi cabs, perfectly
                        suited for any group size and luggage needs. All our
                        vehicles are meticulously maintained and air-conditioned
                        for a relaxing journey.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Reliability you can trust:</b> Our experienced and
                        professional drivers are Point Cook locals, well-versed
                        in the best routes to get you to the airport on time,
                        every time. No need to worry about delays or missed
                        turns – we'll handle the driving while you focus on the
                        excitement ahead.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Transparency you appreciate:</b> We offer upfront
                        pricing and competitive fares, ensuring you get the best
                        value for your Point Cook to Melbourne Airport taxi
                        transfer.
                      </List.Item>
                    </List>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Hoppers Crossing to Melbourne Airport"
                  active={activeIndex === 8}
                  index={8}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Hoppers Crossing to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 8}>
                  <Segment basic secondary>
                    <p className="title-small">
                      Hoppers Crossing: A Melbourne Suburb on the Rise and Taxis
                      to Get You There
                    </p>
                    <p className="medium-text">
                      Hoppers Crossing, nestled approximately 23 kilometres west
                      of Melbourne's bustling city centre, is a vibrant and
                      rapidly developing suburb. Once primarily known for its
                      agricultural land, it has transformed into a sought-after
                      residential area boasting a diverse community, modern
                      amenities, and easy access to the city.
                    </p>
                    <p className="title-small">
                      Getting Around Hoppers Crossing:
                    </p>
                    <p className="medium-text">
                      With limited public transport options, navigating Hoppers
                      Crossing and its surrounding areas can be challenging.
                      This is where reliable taxi services like Luxo Cabs come
                      in handy.
                    </p>

                    <p className="title-small">
                      Luxo Cabs: Your Trusted Ride in Hoppers Crossing:
                    </p>
                    <p className="medium-text">
                      Luxo Cabs offers a convenient and comfortable way to get
                      around Hoppers Crossing, whether you need a ride to the
                      airport, within the suburb itself, or to neighbouring
                      areas like Werribee and Tarneit. Here's why Luxo Cabs is
                      your perfect choice:
                    </p>
                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        <b>Extensive fleet: </b>Choose from a variety of
                        well-maintained vehicles, including sedans, SUVs, and
                        maxi cabs, to accommodate your group size and luggage
                        needs.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Experienced drivers:</b> Our local drivers are
                        familiar with the area, ensuring a smooth and efficient
                        journey.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>24/7 availability:</b> Need a late-night or
                        early-morning ride? We're here for you, any time of day
                        or night.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Competitive fares:</b> Enjoy affordable rates for
                        your trip.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Online booking:</b> Book your ride quickly and easily
                        through our user-friendly website or app.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Stress-free travel:</b> Sit back, relax, and let us
                        take care of the driving.
                      </List.Item>
                    </List>
                    <p className="title-small">
                      Exploring Beyond Hoppers Crossing:
                    </p>
                    <p className="medium-text">
                      Luxo Cabs can also whisk you away to explore the
                      surrounding region. Take a day trip to the enchanting
                      Werribee Open Range Zoo, immerse yourself in the natural
                      beauty of Werribee South Beach, or discover the historical
                      charm of Geelong.
                    </p>
                    <p className="medium-text">
                      So, whether you're a resident of Hoppers Crossing or just
                      visiting, remember that Luxo Cabs is here to make your
                      experience smooth and enjoyable. Book your ride today and
                      discover the best Hoppers Crossing has to offer!
                    </p>
                    <p className="title-small">
                      Skip the Hassle, Embrace the Comfort:
                    </p>
                    <p className="medium-text">
                      Imagine this: instead of battling airport parking or
                      crowded public transport, you simply step outside your
                      Hoppers Crossing home and into a pristine Luxo Cabs
                      vehicle. Our friendly driver handles the traffic, leaving
                      you free to unwind, catch up on work, or simply savour the
                      anticipation of your trip.
                    </p>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Manor Lakes to Melbourne Airport"
                  active={activeIndex === 9}
                  index={9}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Manor Lakes to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 9}>
                  <Segment basic secondary>
                    <p className="title-small">
                      Take Flight with Ease: Your Tranquil Manor Lakes to
                      Melbourne Airport Journey with Luxo Cabs
                    </p>
                    <p className="medium-text">
                      Picture this: you're in Manor Lakes, excitement brewing
                      for your upcoming trip. But the looming shadow of
                      navigating to Melbourne Airport with luggage and potential
                      traffic throws a wrench into your pre-travel bliss. Fear
                      not! Luxo Cabs swoops in, transforming your Manor Lakes to
                      Melbourne Airport experience into a tranquil and
                      stress-free ride. Here's how you'll benefit:
                    </p>
                    <p className="title-small">
                      Effortless Door-to-Door Service:
                    </p>
                    <p className="medium-text">
                      Ditch the airport parking struggles and public transport
                      anxieties. Our courteous drivers pick you up directly from
                      your Manor Lakes doorstep, whisking you away in comfort
                      and eliminating unnecessary hassle.
                    </p>

                    <p className="title-small">Modern Fleet for Every Need:</p>
                    <p className="medium-text">
                      Choose from our diverse fleet of sleek sedans, spacious
                      SUVs, or comfortable maxi cabs, each meticulously
                      maintained and air-conditioned. Whether you're a solo
                      traveller or heading off with a group, we have the perfect
                      vehicle to accommodate your needs and luggage with ease.
                    </p>
                    <p className="title-small">
                      Expert Navigation, Peace of Mind:
                    </p>
                    <p className="medium-text">
                      Sit back and relax as our local Manor Lakes experts
                      navigate the most efficient routes to Melbourne Airport.
                      They possess an intimate knowledge of the area, ensuring
                      you arrive on time and stress-free, even during peak
                      hours. No need to worry about wrong turns or unfamiliar
                      roads – just enjoy the smooth journey.
                    </p>

                    <p className="title-small">
                      Real-Time Flight Tracking, Guaranteed On-Time Arrival:
                    </p>
                    <p className="medium-text">
                      Flight delays can disrupt your travel plans, but with Luxo
                      Cabs, they won't ruin your peace of mind. Our real-time
                      flight tracking monitors your flight, adjusting your Manor
                      Lakes pick-up time if needed to guarantee you catch your
                      departure comfortably. Breathe easy, knowing we've got
                      your back!
                    </p>

                    <p className="title-small">
                      Seamless Booking and Flexible Options:
                    </p>
                    <p className="medium-text">
                      Booking your Luxo Cabs ride is a breeze. Do it online
                      through our user-friendly website, or call our friendly
                      customer service representatives at{" "}
                      <a href="tel:0384888052">03 8488 8052</a> for personalised
                      assistance. We offer various payment options for your
                      convenience, ensuring a hassle-free booking experience.
                    </p>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Werribee to Melbourne Airport"
                  active={activeIndex === 10}
                  index={10}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Werribee to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 10}>
                  <Segment basic secondary>
                    <p className="title-small">
                      Breeze Through Your Travels: Werribee to Melbourne Airport
                      with Luxo Cabs, Melbourne's Best Taxi Service
                    </p>
                    <p className="medium-text">
                      Seeking the best Werribee airport taxi to whisk you
                      effortlessly from your doorstep to Melbourne Airport? Look
                      no further than Luxo Cabs, Melbourne's premier airport
                      taxi service. Experience a stress-free, comfortable, and
                      reliable journey that sets the perfect tone for your
                      upcoming adventure.
                    </p>
                    <p className="title-small">
                      Why Choose Luxo Cabs for Your Werribee to Airport Taxi?
                    </p>
                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        <b>Unrivalled Convenience:</b> Say goodbye to airport
                        parking woes and public transport complexities. Our
                        door-to-door service picks you up directly from your
                        Werribee location and delivers you right to the
                        Melbourne Airport terminal, eliminating unnecessary
                        hassle.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Modern Fleet for Every Need:</b> Choose from our
                        diverse fleet of sleek sedans, spacious SUVs, or
                        comfortable maxi cabs.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Expert Drivers, Smooth Rides:</b> Our local Werribee
                        drivers are experts in navigating the most efficient
                        routes, ensuring you arrive at the airport on time and
                        relaxed. No need to worry about unfamiliar roads or
                        last-minute detours – just sit back and enjoy the smooth
                        ride.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Peace of Mind with Real-Time Flight Tracking:</b>{" "}
                        Flight delays happen, but not with Luxo Cabs. Our
                        real-time flight tracking monitors your flight and
                        adjusts your Werribee pick-up time if needed,
                        guaranteeing you never miss your departure. Breathe
                        easy, knowing we've got you covered.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Seamless Booking and Flexible Options:</b> Booking
                        your Luxo Cabs ride is a breeze. Do it online through
                        our user-friendly website, or call our friendly customer
                        service representatives for personalised assistance. We
                        offer various payment options for your convenience,
                        ensuring a hassle-free booking experience.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Competitive Rates:</b> Enjoy affordable fares without
                        compromising on quality or service. When it comes to
                        value, Luxo Cabs stands out as the best Werribee airport
                        taxi.
                      </List.Item>
                    </List>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Geelong to Melbourne Airport"
                  active={activeIndex === 11}
                  index={11}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Geelong to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 11}>
                  <Segment basic secondary>
                    <p className="title-small">
                      Hello Adventure: Luxo Cabs Smooths Your Melbourne Airport
                      Takeoff
                    </p>
                    <p className="medium-text">
                      Sunsets over Geelong, casting long shadows as your travel
                      spirit ignites. But airport logistics loom, threatening to
                      dampen your pre-flight excitement.
                    </p>
                    <p className="title-small">
                      Don't let parking woes or public transport uncertainties
                      steal your joy!
                    </p>
                    <p className="medium-text">
                      Luxo Cabs is here to transform your Geelong to Melbourne
                      Airport journey into a breeze. Picture this: we whisk you
                      away from your doorstep, navigating city streets with
                      expertise, delivering you relaxed and refreshed right to
                      your terminal. The perfect prelude to your adventure
                      awaits!
                    </p>
                    <p className="medium-text">
                      The distance from Geelong to Melbourne Airport is
                      approximately 81.4 kilometres along the M1 freeway. It
                      usually takes about 55 minutes to drive this distance
                      under normal traffic conditions with Luxo Cabs.
                    </p>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Lara to Melbourne Airport"
                  active={activeIndex === 12}
                  index={12}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Lara to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 12}>
                  <Segment basic secondary>
                    <p className="medium-text">
                      Lara is approximately 65 kilometres away from Melbourne
                      Airport, meaning the drive time can vary depending on
                      traffic conditions. Typically, it takes around 55-65
                      minutes to get from Lara to Melbourne Airport via Luxo
                      Cabs.
                    </p>

                    <p className="medium-text">
                      Luxo Cabs offers a convenient and comfortable way to
                      travel from Lara to Melbourne Airport. We have a diverse
                      fleet of vehicles to accommodate your needs, from sleek
                      sedans to spacious SUVs and comfortable maxi cabs. Our
                      experienced drivers are familiar with the area and will
                      take the most efficient route to get you to the airport on
                      time.
                    </p>
                    <p className="medium-text">
                      Here's how you can easily book your Luxo Cabs ride from
                      Lara to Melbourne Airport:
                    </p>
                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        <b>Visit our website: </b>You can browse our fleet
                        options, see estimated fares, and book your ride
                        directly through our user-friendly platform at
                        https://luxocabs.com.au/
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Call their customer service:</b> If you prefer a more
                        personalised touch, our friendly representatives are
                        happy to assist you with booking your ride and answer
                        any questions you may have. Call 03 8488 8052 to
                        contact.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Enjoy the ride:</b> Sit back, relax, and let their
                        experienced drivers handle the traffic. You can focus on
                        getting excited for your upcoming trip!
                      </List.Item>
                    </List>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Deer Park to Melbourne Airport"
                  active={activeIndex === 13}
                  index={13}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Deer Park to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 13}>
                  <Segment basic secondary>
                    <p className="medium-text">
                      Choosing Luxo Cabs is more than just booking a Deer Park
                      to Tullamarine Airport taxi. It's choosing a reliable
                      partner who prioritises your comfort, peace of mind, and
                      time. From the moment you book your transfer to the
                      friendly goodbye at the airport, we strive to make your
                      journey seamless, enjoyable, and stress-free.
                    </p>

                    <p className="title-small">
                      Ready to experience the Luxo Cabs difference?
                    </p>
                    <p className="medium-text">
                      Don't settle for unreliable Deer Park taxi services. Book
                      your Luxo Cabs transfer today and take off on the right
                      foot! Remember:
                    </p>

                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        Luxo Cabs is the best taxi in Melbourne for Deer Park to
                        Tullamarine Airport transfers.
                      </List.Item>
                      <List.Item className="medium-text">
                        Enjoy door-to-door convenience and avoid airport parking
                        or public transport hassles.
                      </List.Item>
                      <List.Item className="medium-text">
                        Relax in comfort with our modern fleet and expert
                        drivers.
                      </List.Item>
                      <List.Item className="medium-text">
                        Benefit from real-time flight tracking and guaranteed
                        on-time arrival.
                      </List.Item>
                      <List.Item className="medium-text">
                        Experience a seamless booking process with flexible
                        options.
                      </List.Item>
                    </List>
                    <p className="medium-text">
                      Start your next adventure with the best. Choose Luxo Cabs
                      for your Deer Park to Melbourne Airport transfer!
                    </p>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Melton to Melbourne Airport"
                  active={activeIndex === 14}
                  index={14}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Melton to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 14}>
                  <Segment basic secondary>
                    <p className="title-small">
                      Glide from Melton to Melbourne Airport with Ease: Why Luxo
                      Cabs is Your Perfect Match
                    </p>
                    <p className="medium-text">
                      Planning a trip and need the best taxi in Melbourne to
                      transport you smoothly from Melton to Melbourne Airport?
                      Look no further than Luxo Cabs, Melton's trusted partner
                      for stress-free airport transfers.
                    </p>
                    <p className="medium-text">
                      <b>
                        {" "}
                        Melton and Melbourne Airport are approximately 29
                        kilometres apart,
                      </b>{" "}
                      a journey that usually takes around 30-40 minutes
                      depending on traffic. But why settle for just any taxi
                      when you can enjoy a superior experience with Luxo Cabs?
                      Here's why we're the best taxi in both Melton and
                      Melbourne:
                    </p>
                    <p className="medium-text">
                      <b>Effortless Door-to-Door Service:</b> Skip the airport
                      parking scramble and public transport uncertainties. We
                      pick you up directly from your doorstep in Melton and
                      deliver you right to the Melbourne Airport terminal,
                      eliminating unnecessary stress.
                    </p>
                    <p className="medium-text">
                      <b>Modern Fleet for Every Need:</b> Choose from our
                      diverse fleet of sleek sedans, spacious SUVs, or
                      comfortable maxi cabs, all meticulously maintained and
                      air-conditioned. Whether you're a solo traveller or flying
                      with a group, we have the perfect vehicle for your comfort
                      and luggage.
                    </p>

                    <p className="medium-text">
                      <b>Local Expertise, Smooth Navigation:</b> Our
                      Melton-based drivers possess an intimate knowledge of the
                      area, navigating the most efficient routes to ensure you
                      arrive at the airport on time and relaxed. No need to
                      worry about unfamiliar roads or last-minute detours – just
                      sit back and enjoy the smooth journey.
                    </p>
                    <p className="medium-text">
                      <b>Peace of Mind with Real-Time Flight Tracking:</b>{" "}
                      Flight delays happen, but not with Luxo Cabs. Our
                      real-time flight tracking monitors your flight and adjusts
                      your Melton pick-up time if needed, guaranteeing you never
                      miss your departure. Breathe easy, knowing we've got you
                      covered.
                    </p>
                    <p className="medium-text">
                      <b>Seamless Booking and Flexible Options:</b> Booking your
                      Luxo Cabs ride is a breeze. Do it online through our
                      user-friendly website or app, or call our friendly
                      customer service representatives for personalised
                      assistance. We offer various payment options for your
                      convenience, ensuring a hassle-free experience.
                    </p>
                    <p className="title-small">
                      More Than Just a Ride, It's an Investment in Your Trip:
                    </p>
                    <p className="medium-text">
                      Choosing Luxo Cabs is more than just booking an airport
                      taxi. It's choosing a reliable partner who prioritises
                      your comfort, peace of mind, and time. From the moment you
                      book your transfer to the friendly goodbye at the airport,
                      we strive to make your journey seamless, enjoyable, and
                      stress-free.
                    </p>
                    <p className="title-small">
                      Ready to Experience the Luxo Cabs Difference?
                    </p>
                    <p className="medium-text">
                      Don't settle for just any Melton taxi or a mediocre
                      airport transfer. Book your Luxo Cabs ride today and take
                      off on the right foot!
                    </p>

                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        Luxo Cabs is the best taxi in Melbourne for Deer Park to
                        Tullamarine Airport transfers.
                      </List.Item>
                      <List.Item className="medium-text">
                        Enjoy door-to-door convenience and avoid airport parking
                        or public transport hassles.
                      </List.Item>
                      <List.Item className="medium-text">
                        Relax in comfort with our modern fleet and expert
                        drivers.
                      </List.Item>
                      <List.Item className="medium-text">
                        Benefit from real-time flight tracking and guaranteed
                        on-time arrival.
                      </List.Item>
                      <List.Item className="medium-text">
                        Experience a seamless booking process with flexible
                        options.
                      </List.Item>
                    </List>
                    <p className="medium-text">
                      Start your next adventure with the best. Choose Luxo Cabs
                      for your Deer Park to Melbourne Airport transfer!
                    </p>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Ballarat to Melbourne Airport"
                  active={activeIndex === 15}
                  index={15}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Ballarat to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 15}>
                  <Segment basic secondary>
                    <p className="title-small">
                      Luxo Cabs: Your Premium Choice for Ballarat to Melbourne
                      Airport Transfers
                    </p>
                    <p className="medium-text">
                      Welcome to Luxo Cabs, your premier provider of luxury taxi
                      services in Melbourne. Whether you're a resident of
                      Ballarat or a visitor exploring the region, we specialise
                      in delivering exceptional airport transfers to and from
                      Melbourne Airport. With Luxo Cabs, you can expect a
                      seamless and comfortable taxi ride from Ballarat to the
                      airport, ensuring a stress-free start to your journey.
                    </p>

                    <p className="medium-text">
                      For inquiries or reservations, please contact Luxo Cabs at
                      <a className="tel:0384888052">03 8488 8052</a> or visit
                      our website
                      <a className="https://luxocabs.com.au/">
                        https://luxocabs.com.au/
                      </a>
                      . We look forward to serving you and exceeding your
                      expectations with our Ballarat to Melbourne Airport
                      transfers.
                    </p>
                  </Segment>
                </Accordion.Content>
                <Accordion.Title
                  id="Bacchus Marsh to Melbourne Airport"
                  active={activeIndex === 16}
                  index={16}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  <span className="title-medium">
                    Bacchus Marsh to Melbourne Airport
                  </span>
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 16}>
                  <Segment basic secondary>
                    <p className="medium-text">
                      Bacchus Marsh is a charming peri-urban town located
                      approximately 50 kilometres northwest of Melbourne,
                      Australia.It's known for its rich history, scenic beauty,
                      and proximity to several state parks.
                    </p>
                    <p className="medium-text">
                      <b>Seeking the best Airport Taxi in Bacchus Marsh?</b>{" "}
                      Look no further than Luxo Cabs! Whether you're heading for
                      an exciting adventure or returning home, our premium
                      service takes the stress out of your Bacchus to Airport or
                      Melbourne Airport to Bacchus Marsh journey.
                    </p>
                    <p className="medium-text">Why Choose Luxo Cabs?</p>
                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        <b>Effortless Travel:</b> Ditch the hassle of hailing
                        taxis or public transport. Book your Bacchus Marsh
                        Airport Taxi online or by phone, and a chauffeur will be
                        waiting with your name at the designated Airport pick-up
                        point.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Luxury on Wheels:</b> Bid farewell to cramped cabs.
                        Luxo's fleet boasts sleek sedans, spacious SUVs, and
                        even comfortable vans, ensuring a Bacchus Marsh to
                        Airport experience in true style.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Relax and Unwind:</b> Leave the driving to
                        professionals! Their courteous and knowledgeable drivers
                        will navigate the Melbourne roads seamlessly, allowing
                        you to relax and appreciate the scenery.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Door-to-Door Convenience:</b> Forget lugging bags
                        from station to station. Luxo Cabs offers a door-to-door
                        service,picking you up directly at your Bacchus Marsh
                        location and delivering you straight to the Airport
                        terminal.
                      </List.Item>
                      <List.Item className="medium-text">
                        <b>Peace of Mind:</b> Enjoy flight tracking and
                        automatic adjustments for any delays, plus child seat
                        options and luggage assistance upon request.
                      </List.Item>
                    </List>
                    <p className="medium-text">
                      More Than Just an Airport Taxi:
                    </p>
                    <p className="medium-text">
                      Luxo Cabs goes beyond a standard Bacchus Marsh to Airport
                      transfer. We understand the value of your time and strive
                      to make your journey a seamless and enjoyable experience.
                    </p>
                    <p className="medium-text">
                      Search online for "Luxo Cabs" or Call{" "}
                      <a href="tel:0384888052">03 8488 8052</a> and discover the
                      Luxo Cabs difference:
                    </p>
                    <List bulleted relaxed>
                      <List.Item className="medium-text">
                        Competitive rates for top-notch service
                      </List.Item>
                      <List.Item className="medium-text">
                        24/7 availability for stress-free booking
                      </List.Item>
                      <List.Item className="medium-text">
                        Professional and friendly drivers
                      </List.Item>
                      <List.Item className="medium-text">
                        Commitment to safety and comfort
                      </List.Item>
                    </List>
                    <p className="medium-text">
                      Book your Luxo Cab today and let the smooth ride begin!
                      Embark on your travels with confidence, knowing you're in
                      the hands of the best taxi in Bacchus Marsh.
                    </p>
                  </Segment>
                </Accordion.Content>
              </Accordion>
            </Grid.Column>
          </Grid>
        </Segment>
      </div>
    );
  }
}

export default withRouter(SuburbsWeCoverPage);
