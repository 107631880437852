import React from "react";
import { Grid, Image, Header, Segment } from "semantic-ui-react";
import { ASSETS } from "../../../Utils/Assets";

export default function SeamlessTravel(props) {
  const { onlyFeatures = false, inverted = false } = props;
  const featureWidth = onlyFeatures ? 4 : 8;
  const featureTextAlign = onlyFeatures ? "center" : "left";
  return (
    <div>
      <br />
      <br />
      <div
        className="heading-text"
        style={Object.assign(
          { textAlign: "center" },
          inverted ? { color: "white" } : {}
        )}
      >
        Experience Seamless Travel
      </div>
      <br />
      <br />
      <Grid
        centered
        columns={onlyFeatures ? 1 : 2}
        padded
        verticalAlign="middle"
      >
        {!onlyFeatures && (
          <Grid.Column only="computer">
            <Image src={ASSETS.BENZ} centered size="huge" />
          </Grid.Column>
        )}
        <Grid.Column computer={onlyFeatures ? 12 : 8} mobile={16} tablet={16}>
          <Grid
            centered
            style={{
              backgroundColor: "white",
              borderRadius: "2rem",
              padding: "2rem 4rem",
            }}
            // stackable={onlyFeatures}
          >
            <Grid.Column computer={featureWidth} mobile={8} textAlign={featureTextAlign} >
              <Image
                src={ASSETS.ICON_HOME_PICKUP}
                centered={onlyFeatures ? true : false}
              />
              <br />
              <div className="title-medium">Home Pickup</div>
              <div
                className="medium-text"
                style={{ marginTop: "0.2rem", color: "black" }}
              >
                Comfortable and <br/> Reliable Vehicles
              </div>
              <Header>
                <Header.Subheader></Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column computer={featureWidth} mobile={8} textAlign={featureTextAlign}>
              <Image
                src={ASSETS.ICON_SUPPORT}
                centered={onlyFeatures ? true : false}
              />
              <br />
              <div className="title-medium">24x7 Support</div>
              <div
                className="medium-text"
                style={{ marginTop: "0.2rem", color: "black" }}
              >
                We are here to<br/>Assist and Help
              </div>
              <Header>
                <Header.Subheader></Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column computer={featureWidth} mobile={8} textAlign={featureTextAlign}>
              <Image
                src={ASSETS.ICON_BEST_PRICE}
                centered={onlyFeatures ? true : false}
              />
              <br />
              <div className="title-medium">Best Price</div>
              <div
                className="medium-text"
                style={{ marginTop: "0.2rem", color: "black" }}
              >
                Pay your Fare<br/>the Way you want
              </div>
              <Header>
                <Header.Subheader></Header.Subheader>
              </Header>
            </Grid.Column>
            <Grid.Column computer={featureWidth} mobile={8} textAlign={featureTextAlign}>
              <Image
                src={ASSETS.ICON_EASY_BOOKING}
                centered={onlyFeatures ? true : false}
              />
              <br />
              <div className="title-medium">Easy Booking</div>
              <div
                className="medium-text"
                style={{ marginTop: "0.2rem", color: "black" }}
              >
                Book your Ride with<br/>Just a Few Clicks
              </div>
              <Header>
                <Header.Subheader></Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    </div>
  );
}
