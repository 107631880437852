import React from "react";
import { useMediaQuery } from "react-responsive";

export const MobileOnly = (props) => {
  const { children } = props;
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  return <div>{isTabletOrMobile ? children : <div />}</div>;
};

export const DesktopOnly = (props) => {
  const { children } = props;
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  return <div>{isDesktopOrLaptop ? children : <div />}</div>;
};

export const WidescreenOnly = (props) => {
  const { children } = props;
  const isBigScreen = useMediaQuery({ minWidth: 1824 });
  return <div>{isBigScreen ? children : <div />}</div>;
};
