import { EMPTY_BOOKING_DATA } from "../../Utils/Constants";
import {
  LOADING_BOOKING_FINISH,
  LOADING_BOOKING_START,
  RESET_CURRENT_BOOKING,
  UPDATE_CURRENT_BOOKING,
} from "./bookingConstants";

const initialState = EMPTY_BOOKING_DATA;

const bookingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_BOOKING_START:
      return { ...state, loading: true };
    case UPDATE_CURRENT_BOOKING:
      return {
        ...state,
        ...{ current: Object.assign(state.current, action.payload) },
      };
    case RESET_CURRENT_BOOKING:
      return initialState;
    case LOADING_BOOKING_FINISH:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default bookingsReducer;
