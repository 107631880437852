import {
  PROFILE_UPDATE_SUCCESSFUL,
  START_PROFILE_UPDATE,
  END_PROFILE_UPDATE,
  PROFILE_UPDATE_ERROR,
} from "./userConstants";

export const updateUserProfile = (newData) => {
  return async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = firebase.firestore();

    try {
      const uid = getState().firebase.auth.uid;
      if (uid) {
        dispatch({
          type: START_PROFILE_UPDATE,
        });
        try {
          await firebase.auth().currentUser.updateProfile({
            displayName: `${newData["firstName"] ?? ""} ${
              newData["lastName"] ?? ""
            }`,
            photoURL: `${newData["profilePic"] ?? ""}`,
          });
          await firebase.auth().currentUser.updateEmail(newData["email"] ?? "");
        } catch (e) {
          console.log(e);
        }
        await firestore
          .collection("users")
          .doc(uid)
          .set(Object.assign({ userID: uid }, newData), {
            merge: true,
          });
        setTimeout(async () => {
          await firestore
            .collection("users")
            .doc(uid)
            .set(Object.assign({ userID: uid }, newData), {
              merge: true,
            });
        }, 1000);
        dispatch({
          type: END_PROFILE_UPDATE,
        });
        dispatch({
          type: PROFILE_UPDATE_SUCCESSFUL,
        });
      }
    } catch (error) {
      dispatch({
        type: PROFILE_UPDATE_ERROR,
      });
      dispatch({
        type: END_PROFILE_UPDATE,
      });
    }
  };
};
