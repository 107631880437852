import {
  START_PROFILE_UPDATE,
  PROFILE_UPDATE_ERROR,
  END_PROFILE_UPDATE,
  PROFILE_UPDATE_SUCCESSFUL,
} from "./userConstants";

const initialState = {
  updating: false,
  errorUpdating: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case START_PROFILE_UPDATE:
      return { ...state, updating: true };
    case PROFILE_UPDATE_ERROR:
      return { ...state, errorUpdating: true };
    case PROFILE_UPDATE_SUCCESSFUL:
      return { ...state, errorUpdating: false };
    case END_PROFILE_UPDATE:
      return { ...state, updating: false };
    default:
      return state;
  }
};

export default userReducer;
