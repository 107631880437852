export const LOADING_SERVICE_ORDER_START = "LOADING_SERVICE_ORDER_START";
export const LOADING_SERVICE_ORDER_END = "LOADING_SERVICE_ORDER_END";
export const SET_SERVICE_ORDER_DATA = "SET_SERVICE_ORDER_DATA";
export const SET_SERVICE_ORDER_ITEMS = "SET_SERVICE_ORDER_ITEMS";
export const SET_SERVICE_ORDER_TYPE = "SET_SERVICE_ORDER_TYPE";
export const CLEAR_SERVICE_ORDER = "CLEAR_SERVICE_ORDER";
export const SET_ALL_SERVICE_ORDERS = "SET_ALL_SERVICE_ORDERS";
export const SET_ALL_DOORSTEP_ORDERS = "SET_ALL_DOORSTEP_ORDERS";
export const SET_ALL_LOCATIONS = "SET_ALL_LOCATIONS";
export const SET_DOORSTEP_ORDER_DATA = "SET_DOORSTEP_ORDER_DATA";
