import React from 'react';
import './TACApprovedIndicator.css'; // Ensure to create this CSS file for styling

const TACApprovedIndicator = () => {
  return (
    <div className="tac-approved-container">
      <div className="tac-approved-badge">
        <svg viewBox="0 0 24 24" aria-hidden="true" className="tac-approved-icon">
          <path fillRule="evenodd" clipRule="evenodd" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15l-5-5 1.41-1.41L11 13.17l4.59-4.59L17 10l-6 7z"></path>
        </svg>
        <span>We are proudly TAC Approved | <a href='/tac-approved' className='title-small'>Learn More</a></span>
      </div>
    </div>
  );
};

export default TACApprovedIndicator;
